<template>
    <l-geo-json :visible="visible" :name="name" :geojson="geojson"  layer-type="overlay" :options="options"></l-geo-json>
</template>
<script>
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });
export default {
    props: ["file", "visible", "name"],
    components: {
    },
    data(){
        return {
            geojson:'',
            onEachFeature: this.onEachFeatureFunction
        }
    },
    async mounted() {
        this.geojson = await this.getFileContent(this.file).catch(err => {console.error(err);});
    },
    methods : {
        async getFileContent(file) {
            return await this.$root.getGisFileContent (file).catch(err => {console.error(err);});            
        }, 
    },
    computed: {
        options() {
            return {
                onEachFeature: this.onEachFeatureFunction
            };
        },
        onEachFeatureFunction() {
            return (feature, layer) => {
                layer.bindPopup(
                    feature.properties.description,
                    { permanent: false, sticky: true }
                );
                if(feature.properties.icon) {
                        
                    var markerIcon = new L.icon({
                        iconUrl: 'http://localhost:5000/api/marker/' + feature.properties.icon,
                        iconSize: [38, 45],
                        iconAnchor: [12, 42],          
                        popupAnchor: [0, -41]
                    });
                    layer.setIcon(markerIcon);
                } 
            };
        },      
    },

}
</script>
<style scoped>

</style>