import Vue from 'vue';
import { LMap, LTileLayer, LMarker, LGeoJson, LControlLayers, LControlScale, LWMSTileLayer, LLayerGroup } from 'vue2-leaflet';
import lgeojsonHelper from '@/modules/layout/components/forms/helper/lgeojsonHelper.vue';
import 'leaflet/dist/leaflet.css';
import AsyncComputed from 'vue-async-computed';
import router from './routes';
import store from '@/store/context-store';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/pt';
import excel from 'vue-excel-export';
import JsonExcel from "vue-json-excel";
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { locale });
import app from './App.vue';
//import NotificationHub from './Hubs/notification-hub';
//import TaskHub from './Hubs/task-hub';
import VueSimpleMarkdown from 'vue-simple-markdown';
import randomColor from 'randomcolor';
import { v4 as uuidv4 } from 'uuid';
//import VueWorker from 'vue-worker';
import CKEditor from '@ckeditor/ckeditor5-vue2';
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'vue-simple-markdown/dist/vue-simple-markdown.css';
import '@/assets/styles/css/index.css';
//needs to be the last css to be imported
//import '@/assets/styles/css/layout.css';
//components fields
const ComponentField = () => import("@/modules/layout/components/forms/helper/componentField.vue");
const MatrixHelper = () => import("@/modules/layout/components/forms/helper/matrixHelper.vue");
const ListForm = () => import('@/modules/layout/components/forms/list.vue');
const EditForm = () => import('@/modules/layout/components/forms/edit.vue');
const TreeForm = () => import('@/modules/layout/components/forms/tree.vue');
const GanttForm = () => import('@/modules/layout/components/forms/gantt.vue');
const StringField = () => import('@/modules/widgets/String.vue');
const DateField = () => import('@/modules/widgets/Date.vue');
const PeriodField = () => import('@/modules/widgets/Period.vue');
const IntegerField = () => import('@/modules/widgets/Integer.vue');
const DropDownField = () => import('@/modules/widgets/DropDown.vue');
const SelectField = () => import('@/modules/widgets/Select.vue');
const RadioField = () => import('@/modules/widgets/Radio.vue');
const EmailField = () => import('@/modules/widgets/Email.vue');
const TextareaField = () => import('@/modules/widgets/Textarea.vue');
const WYSIWYGField = () => import('@/modules/widgets/WYSIWYG.vue');
const DecimalField = () => import('@/modules/widgets/Decimal.vue');
const PasswordField = () => import('@/modules/widgets/Password.vue');
const PercentField = () => import('@/modules/widgets/Percent.vue');
const TimeField = () => import('@/modules/widgets/Time.vue');
const SeparatorField = () => import('@/modules/widgets/Separator.vue');
const NewLineField = () => import('@/modules/widgets/NewLine.vue');
const UrlField = () => import('@/modules/widgets/Url.vue');
const LinkField = () => import('@/modules/widgets/Link.vue');
const BooleanField = () => import('@/modules/widgets/Boolean.vue');
const CheckBoxField = () => import('@/modules/widgets/CheckBox.vue');
const ChoiceField = () => import('@/modules/widgets/Choice.vue');
const ParentField = () => import('@/modules/widgets/Parent.vue');
const UploadField = () => import('@/modules/widgets/Upload.vue');
const DICOMImageField = () => import('@/modules/widgets/DICOMImage.vue');
const ListField = () => import('@/modules/widgets/List.vue');
const Many2ManyField = () => import('@/modules/widgets/Many2Many.vue');
const GuidField = () => import('@/modules/widgets/Guid.vue');
const DateTimeField = () => import('@/modules/widgets/DateTime.vue');
const CurrencyField = () => import('@/modules/widgets/Currency.vue');
const GeolocationField = () => import('@/modules/widgets/Geolocation.vue');
const ButtonField = () => import('@/modules/widgets/ButtonField.vue');
const ColorField = () => import('@/modules/widgets/ColorField.vue');
const LabelField = () => import('@/modules/widgets/helper/Label.vue');
const SideBarElement = () => import("@/modules/layout/components/helper/sideBarElement.vue");
const SideBarElementHelper = () => import("@/modules/layout/components/helper/sideBarElementHelper.vue");
const DrawerSideBarHelper = () => import("@/modules/layout/posComponents/forms/helper/DrawerSideBarHelper.vue");
import base from './store/base';
const Map = () => import('@/modules/layout/components/forms/map.vue');
const GeolocationMap = () => import('@/modules/widgets/helper/GeolocationMap.vue');
const Chart = () => import('@/modules/layout/components/forms/chart.vue');
const Table = () => import('@/modules/layout/components/forms/table.vue');
const Line = () => import('@/modules/layout/components/forms/line.vue');
const Statistic = () => import('@/modules/layout/components/forms/statistic.vue');
const Matrix = () => import('@/modules/layout/components/forms/matrix.vue');
import DFEReport from '@/modules/layout/components/forms/dfeReport.vue';
// import ForgotPassword from '@/modules/auth/posComponents/forgot-password.vue';
//POS
import Calculator from '@/modules/layout/posComponents/forms/calculator.vue';
import PosSidebar from '@/modules/layout/posComponents/forms/helper/PosSideBarElement.vue';
import POSKeyBoardNumber from '@/modules/layout/posComponents/forms/posKeyBoardNumber.vue';
import POSMemoryChoice from '@/modules/layout/posComponents/forms/posMemoryChoice.vue';
import POSmemory from '@/modules/layout/posComponents/forms/posMemory.vue';
import POSSplitBill from '@/modules/layout/posComponents/forms/posSplitBill.vue';
import POSproduct from '@/modules/layout/posComponents/forms/posProduct.vue';
import POSProductChoice from '@/modules/layout/posComponents/forms/posProductChoice.vue';
import POSCredit from '@/modules/layout/posComponents/forms/posCredit.vue';
import POSMarge from '@/modules/layout/posComponents/forms/posMarge.vue';
import POSProforma from '@/modules/layout/posComponents/forms/posProforma.vue';
import POSReprintDocument from '@/modules/layout/posComponents/forms/posReprintDocument.vue';
import POSOffer from '@/modules/layout/posComponents/forms/posOffer.vue';
import POSConsumption from '@/modules/layout/posComponents/forms/posConsumption.vue';
import POSLoss from '@/modules/layout/posComponents/forms/posLoss.vue';
import POSUser from '@/modules/layout/posComponents/forms/posUser.vue';
import POSFileImage from '@/modules/layout/posComponents/forms/helper/FileImage.vue';
import POSShift from '@/modules/layout/posComponents/forms/posShift.vue';
import POSSummary from '@/modules/layout/posComponents/forms/posSummary.vue';
import POSCashJournal from '@/modules/layout/posComponents/forms/posCashJournal.vue';
import POSPayment from '@/modules/layout/posComponents/forms/posPayment.vue';
import POSStaffWorkTime from '@/modules/layout/posComponents/forms/pos-staff-work-time.vue';
import POSEditChange from '@/modules/layout/posComponents/forms/posEditChange.vue';
import POSList from '@/modules/layout/posComponents/forms/posList.vue';
//import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
//import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
//import './registerServiceWorker'
var format = require('date-format');
Vue.prototype.$dateFormat = format;
Vue.use(VueSimpleMarkdown);
Vue.use(randomColor);
Vue.use(AsyncComputed);
Vue.use(CKEditor);
//Vue.use(VueWorker);
Vue.component('geolocation-map', GeolocationMap);
Vue.component('matrix-helper', MatrixHelper);
Vue.component('el-line', Line);
Vue.component('app-map', Map);
Vue.component('chart', Chart);
Vue.component('app-table', Table);
Vue.component('statistic', Statistic);
Vue.component('matrix', Matrix);
Vue.component('SideBarElement', SideBarElement);
Vue.component('SideBarElementHelper', SideBarElementHelper);
Vue.component('DrawerSideBarHelper', DrawerSideBarHelper);
Vue.component('component-field', ComponentField);
Vue.component('ListForm', ListForm);
Vue.component('edit-form', EditForm);
Vue.component('TreeForm', TreeForm);
Vue.component('GanttForm', GanttForm);
Vue.component('string-field', StringField);
Vue.component('date-field', DateField);
Vue.component('period-field', PeriodField);
Vue.component('integer-field', IntegerField);
Vue.component('drop-down-field', DropDownField);
Vue.component('select-field', SelectField);
Vue.component('radio-field', RadioField);
Vue.component('email-field', EmailField);
Vue.component('text-field', TextareaField);
Vue.component('wysiwyg-field', WYSIWYGField);
Vue.component('decimal-field', DecimalField);
Vue.component('password-field', PasswordField);
Vue.component('percent-field', PercentField);
Vue.component('time-field', TimeField);
Vue.component('separator-field', SeparatorField);
Vue.component('new-line-field', NewLineField);
Vue.component('url-field', UrlField);
Vue.component('link-field', LinkField);
Vue.component('boolean-field', BooleanField);
Vue.component('checkbox-field', CheckBoxField);
Vue.component('choice-field', ChoiceField);
Vue.component('parent-field', ParentField);
Vue.component('upload-field', UploadField);
Vue.component('dicomimage-field', DICOMImageField);
Vue.component('list-field', ListField);
Vue.component('many2many-field', Many2ManyField);
Vue.component('guid-field', GuidField);
Vue.component('date-time-field', DateTimeField);
Vue.component('currency-field', CurrencyField);
Vue.component('geolocation-field', GeolocationField);
Vue.component('button-field', ButtonField);
Vue.component('color-field', ColorField);
Vue.component('label-field', LabelField);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-geo-json-helper', lgeojsonHelper);
Vue.component('l-layer-group', LLayerGroup);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-control-scale', LControlScale);
Vue.component('l-wms-tile-layer', LWMSTileLayer);
Vue.component('dfe-report', DFEReport);
//POS
Vue.component('calculator', Calculator);
Vue.component('pos-key-board-number', POSKeyBoardNumber);
Vue.component('pos-side-bar', PosSidebar);
Vue.component('pos-memory-choice', POSMemoryChoice);
Vue.component('pos-memory', POSmemory);
Vue.component('pos-split-bill', POSSplitBill);
Vue.component('pos-product', POSproduct);
Vue.component('pos-product-choice', POSProductChoice);
Vue.component('pos-credit', POSCredit);
Vue.component('pos-marge', POSMarge);
Vue.component('pos-proforma', POSProforma);
Vue.component('pos-reprint-document', POSReprintDocument);
Vue.component('pos-offer', POSOffer);
Vue.component('pos-consumption', POSConsumption);
Vue.component('pos-loss', POSLoss);
Vue.component('pos-user', POSUser);
Vue.component('pos-file-image', POSFileImage);
Vue.component('pos-shift', POSShift);
Vue.component('pos-summary', POSSummary);
// Vue.component('forgot-password', ForgotPassword);
Vue.component('pos-cash-journal', POSCashJournal);
Vue.component('pos-payment', POSPayment);
Vue.component('pos-staff-work-time', POSStaffWorkTime);
Vue.component('pos-edit-change', POSEditChange);
Vue.component('pos-list', POSList);

Vue.use(excel);
Vue.component("download-excel", JsonExcel);
// Vue.component('editor', Editor);
//Vue.component('CKEditor', CKEditor)
//Vue.component('editor', ClassicEditor)

Vue.config.productionTip = false

// store.subscribe((mutation, state) => {
//     sessionStorage.setItem('store', JSON.stringify(state));
// });

Vue.config.errorHandler = function () {
    //err, vm, info
    //console.log(`Error: ${err.toString()}\nInfo: ${info} ${vm}`);
};
Vue.config.warnHandler = function () {
    //msg, vm, trace
    //console.log(`Warn: ${msg}\nTrace: ${trace} ${vm}`);
};
//Vue.prototype.$api = api;
window.onerror = function () {
    //message, source, lineno, colno, error
    //console.log('Exception: ', error)
}

//fetch('/config.json').then(res => res.json()).then(config => {
//config.API_URL;

let API_URL = window.location.origin + "/";
if (API_URL.indexOf("localhost") !== -1) {
    API_URL = "http://localhost:5000/";
} else if (API_URL.indexOf('8080') >= 0){
    // fix temporario pois a coisa não esta a ter em conta o api url defenido no appsettings
    API_URL = API_URL.replace("8080", "5000");
}

localStorage.setItem('API_URL', API_URL);
//Vue.use(NotificationHub, API_URL);
//Vue.use(TaskHub, API_URL);

new Vue({

    data() {
        return {
            apiConnected: true,
            sginalRConnection: null,
            i18n: {}
        }
    },

    renderError(h, err) {
        return h('pre', { style: { color: 'red' } }, err.stack)
    },

    router,

    store,

    // async beforeCreate() {
    //     this.$store.dispatch('initialiseStore');
    // },

    async mounted() {
        //this.$router.replace('/')
        //await this.configureSignalR();
    },

    async update() {
        this.i18n = await this.getLanguage().catch(err => {console.error(err);});;
    },
    
    methods: {

        toIsoString(date) {
            var tzo = -date.getTimezoneOffset(),
                dif = tzo >= 0 ? '+' : '-',
                pad = function(num) {
                    return (num < 10 ? '0' : '') + num;
                };
            return date.getFullYear() +
                '-' + pad(date.getMonth() + 1) +
                '-' + pad(date.getDate()) +
                'T' + pad(date.getHours()) +
                ':' + pad(date.getMinutes()) +
                ':' + pad(date.getSeconds()) +
                '.' + pad(date.getMilliseconds()) + 
                dif + pad(Math.floor(Math.abs(tzo) / 60)) +
                ':' + pad(Math.abs(tzo) % 60);
            },

        formatNumber(value, separatorDecimal = ".") {
            let val;
            try {
                if (value == null && value == undefined) {
                    return null;
                } else {
                    var numero = value.toString().split('.');
                    numero[0] = numero[0].split(/(?=(?:...)*$)/).join(' ');
                    val = numero.join(separatorDecimal);
                }
            } catch {
                val = null;
            }
            return val;
        },

        async evalExpression({ expression, objName, Id, data, returnType = 'string' }) {
            if (objName && Id) {
                data = await this.getObjectRecord({ 'objName': objName, 'Id': Id }).catch(err => {console.error(err);});
            }
            let url = API_URL + 'api/obj/EvalExpression/';
            let result = await base.api("POST", url, {
                "expression": expression,
                "data": data,
                "returnType": returnType
            }).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});
        },

        fieldWithValues(field_type) {
            return ["List", "Many2Many", "Upload", "DICOMImage"].includes(field_type);
        },

        isFieldNumber(field_type) {
            return ["Currency", "Percent", "Decimal", "Integer", "Float"].includes(field_type);
        },

        //temporario para o POS--- > alterar urgente - fix urgent.. 
        // async getPOSTabs() {
        //     let url = API_URL + 'api/obj/RunSql/';
        //     let sql = "select \"PM\".\"Id\", \"PM\".\"Name\" AS \"TabName\",  \"PM\".\"Type\" from \"POSMenu\" \"PM\"  where \"PM\".\"Parent\" is null and \"PM\".\"Active\" = 'true' order by \"PM\".\"Name\" "
        //     let result = await base.api("POST", url, {
        //         "dataSource": 'Main',
        //         "Sql": sql,
        //         "returnType": 'string'
        //     }).catch(err => {console.error(err);});
        //     return await this.ProcessResponse(result).catch(err => {console.error(err);});

        // },

        // se tiver tab, retorna o tab, senão procura por produto e o retorna
        // async getOtherTabsOrProducts(id) {
        //     if (id && id != null) {
        //         let url = API_URL + 'api/obj/RunSql/';
        //         let sql = "select \"PM\".\"Id\", \"PM\".\"Name\", \"PM\".\"Type\"  from \"POSMenu\" \"PM\"  where \"PM\".\"Parent\" = '" + id + "';"
        //         let result = await base.api("POST", url, {
        //             "dataSource": 'Main',
        //             "Sql": sql,
        //             "returnType": 'string'
        //         }).catch(err => {console.error(err);});
        //         return await this.ProcessResponse(result).catch(err => {console.error(err);});
        //     }
        // },

        // async getTabProducts(id) {
        //     if (id && id != null) {
        //         let url = API_URL + 'api/obj/RunSql/';
        //         let sql = "select \"p\".\"Name\" as \"ProductName\" from \"POSMenu\" \"pm\" left join \"POSMenu_Product\" \"pmp\" on \"pmp\".\"POSMenu\" = \"pm\".\"Id\" left join \"Product\" p on p.\"Id\" = pmp.\"Product\"  LEFT JOIN \"AppFile\" as \"appFile\"      on \"appFile\".\"Object\" = 'ProductToSell' and \"appFile\".\"ObjectId\"=\"p\".\"Id\" and \"appFile\".\"FieldName\"='Photo'  where pm.\"Id\" = '" + id + "' order by p.\"Name\" "
        //         let result = await base.api("POST", url, {
        //             "dataSource": 'Main',
        //             "Sql": sql,
        //             "returnType": 'string'
        //         }).catch(err => {console.error(err);});
        //         return await this.ProcessResponse(result).catch(err => {console.error(err);});
        //     }
        // },

        async RunSql(sql) {
            let url = API_URL + 'api/obj/RunSql/';
            let result = await base.api("POST", url, {
                "dataSource": 'Main',
                "Sql": sql,
                "returnType": 'string'
            }).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});

        },

        async getRadioOptions(optionsInfo) {
            if (optionsInfo && optionsInfo.objName) {
                //let filter = optionsInfo.filter;
                let url = API_URL + 'api/obj/RunSql/';
                let sql = "select \"PM\".\"Id\", \"AppFile\".\"Id\" AS \"Image\", \"PM\".\"Name\"  from \"PaymentMethod\" \"PM\" left join \"AppFile\" \"AppFile\" on \"AppFile\".\"ObjectId\" =  \"PM\".\"Id\" and \"AppFile\".\"Object\" = 'PaymentMethod' where \"PM\".\"ActiveInPOS\" = 'true' "
                let result = await base.api("POST", url, {
                    "dataSource": 'Main',
                    "Sql": sql,
                    "returnType": 'string'
                }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            // todas as mensagens devem traduzir usando o i18n, fix later but urgent everywhere
            await this.ShowMessage({ type: "error", message: "Nome do objeto ou da função está nulo!" });
            return null;
        },

        async getChoiceOptions(optionsInfo) {
            if (optionsInfo && optionsInfo.objName && optionsInfo.action) {
                let url = API_URL + 'api/py/'; //GetChoiceOptions/
                let result = await base.api("POST", url, optionsInfo).catch(err => {console.error(err);});
                try {
                    return await this.ProcessResponse(result);
                } catch {
                    return null;
                }
            }
            // todas as mensagens devem traduzir usando o i18n, fix later but urgent everywhere
            await this.ShowMessage({ type: "error", message: "Nome do objeto ou da função está nulo!" });
            return null;
        },

        /*setObjectProperty(obj, path, value){
            var vm = new Vue({data: obj})
            vm.$set(path, value);
            return vm;
        },*/

        async getData({name, join, return_fields}) {
            // we need to start using this more because it is important for performace to only bring what we need nat all fields in a table and respective relations. fix later but important
            if (name) {
                let url = API_URL + 'api/obj/GetData/';
                let result = await base.api("POST", url, {
                    "objName": name,
                    'join': join,
                    'return_fields': return_fields
                }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            await this.ShowMessage({ type: "error", message: "Nome do Objeto nulo!" });
            return null;
        },

        async getListData({ name, currentPage, pageSize, filter, freeSearch, field_names, orderBy, record, recordObj }) {
            // we need to change field_names to return_names because is more clear, is what they are, the fields to return, and also we need to start using this more because it is important for performace to only bring what we need nat all fields in a table and respective relations. fix later but important
            if (name) {
                if (!currentPage) {
                    currentPage = 0;
                }
                if (!pageSize) {
                    pageSize = 0;
                }
                let url = API_URL + 'api/obj/GetData/';
                let result = await base.api("POST", url, {
                    "objName": name,
                    "page": currentPage,
                    "records_per_page": pageSize,
                    'search_string': freeSearch,
                    'filter': filter,
                    'field_names': field_names,
                    'order_by': orderBy,
                    'record': record,
                    'record_obj': recordObj
                }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            await this.ShowMessage({ type: "error", message: "Nome do Objeto nulo!" });
            return null;
        },

        // getPendentForDirectPrint() {
        //     return this.$store.state.pendentForDirectPrint;
        // },

        // setPendentForDirectPrint(content) {
        //     try {
        //         this.$store.dispatch('pendentForDirectPrint', content);
        //     } catch {
        //         return;
        //     }
        // },

        async DirectPrint(printer, print_server_url, text_or_pdf, type) {
            //alert("got in direct print");
            if (printer && print_server_url && text_or_pdf && type) {
                let url = print_server_url + '/print_job';
                let content_type = '';
                if (type == 'text') {
                    content_type = "text/plain";
                } else if (type == 'pdf') {
                    content_type = "application/pdf";
                }
                let headers = { 'Printer': printer, 'Accept': '*/*', "Content-Type": content_type, "Access-Control-Allow-Origin": "*" };
                // let token = JSON.parse(localStorage.getItem('token'));; charset=utf-8;
                //"Access-Control-Allow-Credentials": true,
                // if (token) {
                //     token = token.token;
                //     headers['Authorization'] = `Bearer ${token}`;
                // }
                let requestOptions = {
                    method: "POST",
                    headers: headers,
                    body: text_or_pdf
                };
                //alert(url);
                try {
                    const response = await fetch(url, requestOptions).catch(err => {console.error(err);});
                    //alert(response);
                    if (response.status >= 200 && response.status < 300) {          
                        return true;
                    }
                } catch (error) {
                    return false;
                }
                return false; 
            }
        },

        async ProcessResponse(result) {
            console.log(result);
            let response = await this.getResponse(result).catch(err => {console.error(err);});
            if (response.message) {
                await this.ShowMessage({ type: response.type, message: response.message });
            } else {
                return response;
            }
        },

        async getAllData({ name, where, filter, freeSearch, return_fields, orderBy }) {
            if (name) {
                let url = API_URL + 'api/obj/GetAll/';
                let result = await base.api("POST", url, {
                    "objName": name,
                    "where": where,
                    'search_string': freeSearch,
                    'filter': filter,
                    'return_fields': return_fields,
                    'order_by': orderBy
                }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async GetAllDataToExport({ name, where, filter, freeSearch }) {
            if (name) {
                let url = API_URL + 'api/obj/GetAllDataToExport/';
                let result = await base.api("POST", url, {
                    "objName": name,
                    "where": where,
                    'search_string': freeSearch,
                    'filter': filter
                }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async GetTemplate(model) {
            let row_label = {}
            if (model && model.Fields) {
                for (let field_name in model.Fields) {
                    if (!["UserCreate", "DateCreate", "UserUpdate", "DateUpdate", "LockedBy"].includes(field_name)) {
                        let field = model.Fields[field_name];
                        if (!this.fieldWithValues(field.Type) && field.PersistOnDB != false && field.Type != "Button") {
                            //row_label[field.Label ? field.Label : field.Name] = field.Name;
                            row_label[field.Name] = field.Label ? field.Label : field.Name;
                            if (field.Columns) {
                                for (let column of field.Columns) {
                                    //row_label[column.Label] = field.Name + "." + column.Name;
                                    row_label[field.Name + "." + column.Name] = column.Label;
                                }
                            }
                        }
                    }
                }
            }
            return row_label;
        },

        // async checkPOSMemoryMovement(memoryId) {
        //     if (memoryId) {
        //         let url = API_URL + 'api/pos/CheckPOMMemoryMovement/' + memoryId;
        //         let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
        //         return await this.ProcessResponse(result).catch(err => {console.error(err);});
        //     }
        // },

        async GetModelTemplate(name) {
            let template = {}
            let template_content = [];
            if (name) {
                let model = await this.getModel(name).catch(err => {console.error(err);});
                let row_label = await this.GetTemplate(model).catch(err => {console.error(err);});
                template_content.push(row_label);
                template[name] = template_content;
                for (let field_name in model.Fields) {
                    let field = model.Fields[field_name];
                    if (["List", "Choice"].includes(field.Type) && field.Relation) {
                        let model_rel = await this.getModel(field.Relation).catch(err => {console.error(err);});
                        let row_label_rel = await this.GetTemplate(model_rel).catch(err => {console.error(err);});
                        if (Object.keys(row_label_rel).length > 0) {
                            let template_content_rel = [];
                            template_content_rel.push(row_label_rel);
                            template[field.Relation] = template_content_rel;
                        }
                    }
                }
                for (let field_name in model.Fields) {
                    let field = model.Fields[field_name];
                    if (["Drop-Down", "Radio"].includes(field.Type)) {
                        let field_options;
                        if (field.Options && field.Options[0] && field.Options[0][0] != 'Expression') {
                            field_options = field.Options;
                        } else if (field.Options && field.Options[0] && field.Options[0][0] == 'Expression') {
                            field_options = await this.getOptions({
                                'objName': name,
                                'fieldPath': field_name,
                                'function': field.Options[0][1],
                                'obj': model,
                                'DataSource': model.DataSource
                            }).catch(err => {console.error(err);});
                        }
                        let options = [];
                        for (let opt of field_options) {
                            options.push({ "Key": opt[0], "Value": opt[1] });
                        }
                        template[field_name + "__Options__"] = options;
                    }
                }
            }
            return template;
        },

        SetListFormNavegationInfo(content) {
            this.$store.dispatch('setListFormNavegationInfo', content);
        },

        GetListFormNavegationInfo() {
            return this.$store.state.listFormNavegationInfo;
        },

        async ShowMessage(content) {
            if (content && content.type && content.message) {
                if (["error", "warning"].includes(content.type)) {
                    const d = new Date();
                    const date_time = JSON.stringify(d.toLocaleDateString() + " " + d.toLocaleTimeString());
                    const message = {'message': content.message, 'type': content.type, 'date_time': date_time}
                    this.$store.dispatch('setListSystemMessage', message);
                }
                this.$message(content);
            }
        },

        async GetCalendarEvents(data) {
            let url = API_URL + 'api/calendar/GetEvents/';
            if (!data.filters || data.filters == undefined) {
                data.filters = []
            }
            let result = await base.api("POST", url, data).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});
        },

        async GetUserCalendars() {
            let url = API_URL + 'api/calendar/GetUserCalendars';
            let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});
        },

        // async GetFilterObjectOptions() {
        //     let url = API_URL + 'api/calendar/GetFilterObjectOptions/';
        //     let result = await base.api("POST", url, {}).catch(err => {console.error(err);});
        //     return await this.ProcessResponse(result).catch(err => {console.error(err);});
        // },

        // async getDCMFile(fileInfo) {

        // },

        async getUploadedFile(fileInfo) {
            if (fileInfo.Id) {
                let url = API_URL + 'api/file/Download/' + fileInfo.Id + `/${fileInfo.Saved == undefined ? 'true' : fileInfo.Saved}`;
                let headers = { "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*" };
                let token = await this.$getToken().catch(err => {console.error(err);});
                if (token) {
                    token = token.token;
                    headers['Authorization'] = `Bearer ${token}`;
                }
                let requestOptions = {
                    method: "GET",
                    headers: headers,
                };
                try {
                    const response = await fetch(url, requestOptions).catch(err => {console.error(err);});
                    if (response.status >= 200 && response.status < 300) {
                        return await response.blob().catch(err => {console.error(err);});
                    } else {
                        var error = new Error(response.statusText);
                        //await this.ShowMessage({ message: this.i18n.FileNotFound, type: "error" });
                        return;
                    }
                } catch (error) {
                    await this.ShowMessage({ message: error, type: "error" });
                    return;

                }
            }
            await this.ShowMessage({ message: "File Info null", type: "error" });
            return;
        },

        async getUploadedFileById(file_id) {
            if (file_id) {
                let url = API_URL + 'api/file/Download/' + file_id + '/true';
                let headers = { "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*" };
                let token = await this.$getToken().catch(err => {console.error(err);});
                if (token) {
                    token = token.token;
                    headers['Authorization'] = `Bearer ${token}`;
                } else {

                    url = API_URL + 'api/pos/Download/' + file_id;
                }
                let requestOptions = {
                    method: "GET",
                    headers: headers,
                };
                try {
                    const response = await fetch(url, requestOptions).catch(err => {console.error(err);});
                    if (response.status >= 200 && response.status < 300) {
                        return await response.blob().catch(err => {console.error(err);});
                    } else {
                        var error = new Error(response.statusText);
                        //await this.ShowMessage({ message: this.i18n.FileNotFound, type: "error" });
                        return;
                    }
                } catch (error) {
                    await this.ShowMessage({ message: error, type: "error" });
                    return;
                }
            }
            await this.ShowMessage({ message: "File Info null", type: "error" });
            return;
        },
        // async getLinkFile(content) {
        //     if (content) {
        //         console.log(1111111111)
        //         console.log(content)
        //         let url = API_URL + 'api/imageFile/GetImageLink/' + content.Name + '/' + content.Location + '/' + content.Id;
        //         return url
        //     }
        //     return null;
        // },

        async uploadFile(content) {
            if (content) {
                let url = API_URL + 'api/file/Upload/';
                let result = await base.api("POST", url, content, "file").catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },
        
        //Fix Urgent
        // async getModel(name) {
        //     if (name) {
        //         let model = null;
        //         let models = JSON.parse(localStorage.getItem('models'));
        //         if (models && models[name] != null) {
        //             model = models[name];
        //             return model;
        //         } else {
        //             let url = API_URL + 'api/obj/GetModel/';
        //             let result = await base.api("POST", url, {"objName": name});
        //             model = await this.ProcessResponse(result); 
        //             if (model != null) {
        //                 let modelContent = {"name": name ,"model": model}
        //                 this.setLoadedModel(modelContent);
        //                 return model;
        //             } 
        //         }
        //     }
        //     await this.ShowMessage({ message: "Objeto \"" + name + "\" não existe!", type: "error" });
        //     return null;
        // },
        // setLoadedModel(modelContent) {
        //     try {
        //         let models = JSON.parse(localStorage.getItem("loadedModels"));
        //         if (models && models.length > 0) {
        //             models = models.push(modelContent);
        //             models = typeof models == 'object' ? JSON.stringify(models) : models;
        //             localStorage.setItem("loadedModels", models)
        //         } else {
        //             modelContent = typeof modelContent == 'object' ? JSON.stringify(modelContent) : modelContent;
        //             models.push(modelContent);
        //             localStorage.setItem("loadedModels", models)
        //         }
        //     } catch {
        //         return;
        //     }
        // },

        async getModel(name) {
            // if (name) {
            //     let models =  this.$store.state.loadedModels;
            //     let model = models[name];
            //     if (model) {
            //         return model;
            //     } else {
            let url = API_URL + 'api/obj/GetModel/';
            let result = await base.api("POST", url, { "objName": name }).catch(err => {console.error(err);});
            let model = await this.ProcessResponse(result).catch(err => {console.error(err);});
            if (model != null) {
                //let modelContent = {"name": name ,"model": model}
                //await this.setLoadedModel(modelContent);
                return model;
            }
            //     }
            // }
            await this.ShowMessage({ message: "Objeto \"" + name + "\" não existe!", type: "error" });
            return null;
        },

        // async setLoadedModel(modelContent) {
        //     try {
        //         await this.$store.dispatch('setLoadedModel', modelContent).catch(err => {console.error(err);}).catch(err => {console.error(err);});
        //     } catch {
        //         return;
        //     }
        // },

        // async getModelSync(name) {
        //     if (name) {
        //         let models =  this.$store.state.loadedModels;
        //         //let models = JSON.parse(localStorage.getItem('models'));
        //         let model = models[name];
        //         if (model) {
        //             return model;
        //         } else {
        //             return await this.getModel(name);
        //         }
        //     }
        //     console.log(11111);
        //     await this.ShowMessage({ message: "Objeto \"" + name + "\" não existe!", type: "error" });
        //     return null;
        // },

        getNewObjectId() {
            return "new_" + uuidv4();
        },

        async getObject(objInfo) {
            if (objInfo.objName) {
                if (objInfo.IsRedirect != true && objInfo.IsParent != true && objInfo.IsNewInChoice != true) {
                    let result = await this.getLocalObject(objInfo).catch(err => {console.error(err);});
                    if (result) {
                        return result;
                    }
                }
                let obj = await this.getModel(objInfo.objName).catch(err => {console.error(err);});
                if (obj) {
                    if (obj.WithDefaultActions == false) {
                        if (!objInfo.Id) {
                            let new_id = this.getNewObjectId();
                            objInfo.Id = new_id;
                            obj.Fields.Id.Value = new_id;
                        }
                    }
                    let update_obj = {};
                    if (obj.DataSourceType == 'SQL') {
                        //when the obj doesn't have the form List defined, we return the fisrt Id if exists                
                        if (!objInfo.Id && obj.WithDefaultActions && (!obj.Forms || (obj.Forms && !obj.Forms.List))) {
                            let list_data = await this.$root.getListData({ "name": objInfo.objName }).catch(err => {console.error(err);});
                            if (list_data && list_data[0] && list_data[0][0] && list_data[0][0].Record) {
                                objInfo.Id = list_data[0][0].Record.Id;
                            }
                        } else if (!objInfo.Id && obj.WithDefaultActions) {
                            let new_id = this.getNewObjectId();
                            this.$router.push({ name: 'edit', params: { 'objName': this.objName, 'Id': new_id } }).catch(() => {console.log('erro em router.push de getObject')});
                        }
                        update_obj = await this.getObjectData(objInfo).catch(err => {console.error(err);});
                    } else if (obj.DataSourceType == 'Yaml') {
                        update_obj = await this.getYamlObjectData(objInfo).catch(err => {console.error(err);});
                    }
                    obj.Disabled = typeof update_obj.Disabled == "string" ? update_obj.Disabled == "true" : update_obj.Disabled;
                    if ("Tabs" in update_obj) {
                        for (let tab in update_obj.Tabs) {
                            if ('Hidden' in update_obj.Tabs[tab]) {
                                obj.Tabs[tab]['Hidden'] = update_obj.Tabs[tab]['Hidden'];
                            }
                            if ('Disabled' in update_obj.Tabs[tab]) {
                                obj.Tabs[tab]['Disabled'] = update_obj.Tabs[tab]['Disabled'];
                            }
                        }
                    }
                    obj.ActiveActions = update_obj.ActiveActions;
                    for (let field_name in obj.Fields) {
                        let field = obj.Fields[field_name];
                        let update_field = update_obj.Fields[field_name];
                        if (update_field) {
                            if ("Value" in update_field) {
                                //  && objInfo.IsFilterObj != true testar depois nu advanced filter para ver se não estraga nada
                                field.Value = update_field.Value;
                            } else if ("Values" in update_field) {
                                field.Values = update_field.Values;
                            }
                            if ("ShowValue" in update_field) {
                                field.ShowValue = update_field.ShowValue;
                            }
                            if ("Hidden" in update_field) {
                                field.Hidden = update_field.Hidden;
                            }
                            if ("Disabled" in update_field) {
                                field.Disabled = update_field.Disabled;
                            }
                            if ("Required" in update_field) {
                                field.Required = update_field.Required;
                            }
                            if ("Redirect" in update_field) {
                                field.Redirect = update_field.Redirect;
                            }
                            if ("Properties" in update_field) {
                                field.Properties = update_field.Properties;
                            }
                            if ("Fields" in update_field) {
                                field.Fields = update_field.Fields;
                            }
                            if ("NoAdd" in update_field) {
                                field.NoAdd = update_field.NoAdd;
                            }
                            if ("NoDelete" in update_field) {
                                field.NoDelete = update_field.NoDelete;
                            }
                            if ("NoEdit" in update_field) {
                                field.NoEdit = update_field.NoEdit;
                            }
                        }
                    }
                    let content;
                    if (objInfo.IsParent || objInfo.IsNewInChoice) {
                        content = this.$store.state.loadedObjects;
                    } else {
                        content = {};
                    }
                    if (objInfo.Id) {
                        content[objInfo.objName + objInfo.Id] = obj;
                    } else {
                        content[objInfo.objName] = obj;
                    }
                    this.$store.dispatch('setLoadedObjects', content);
                    return obj;
                } else {
                    return null;
                }
            }
            await this.ShowMessage({ message: "Nome do objeto está nulo!", type: "error" });
            return null;
        },

        async getLocalObject(objInfo) {
            let result = null;
            // let sessionStorage = await base.getSessionStorage().catch(err => {console.error(err);});
            if (objInfo.Id) {
                result = this.$store.state.loadedObjects[objInfo.objName + objInfo.Id];
            } else {
                result = this.$store.state.loadedObjects[objInfo.objName];
            }
            return result;
        },

        getLocalDataObject(objName) {
            return this.$store.state.loadedDataObjects[objName];
        },

        setLocalDataObject(objDataContent) {
            try {
                this.$store.dispatch('setLoadedDataObjects', objDataContent);
            } catch {
                return;
            }
        },

        async getLocalObjectByName(objInfo) {
            let result = null;
            //let sessionStorage = await base.getSessionStorage().catch(err => {console.error(err);});
            result = this.$store.state.loadedObjects[objInfo.objName + objInfo.Id];
            return result;
        },

        async getValidation(objName) {
            if (objName) {
                let url = API_URL + 'api/obj/GetValidation/';
                let result = await base.api("POST", url, { "objName": objName }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        getLoading() {
            return this.$loading({
                lock: true,
                customClass: 'nexus-loading',
                spinner: 'none'
            });
        },

        getLoadingWithText({ text, background }) {
            let content = {
                lock: true,
                customClass: 'nexus-loading',
                spinner: 'none'
            };
            if (text) {
                content.text = text;
            }
            if (background) {
                content.background = background;
            }
            return this.$loading(content);
        },

        getFieldProperty(fieldInfo) {
            var content = this.$store.state.loadedObjects[fieldInfo.objName + fieldInfo.Id];
            fieldInfo.fullFieldName.split('.').forEach(fn => {
                if (fn) {
                    content = content.Fields[fn];
                }
            });
            if (fieldInfo.property) {
                if (typeof content[fieldInfo.property] == 'undefined') {
                    return null;
                }
                return content[fieldInfo.property];
            } else {
                return content;
            }
        },

        async getActiveSidebar() {
            let active_side_bar = this.$store.state.activeSidebar;
            return active_side_bar.content;
        },

        getActivePosMemory() {
            return this.$store.state.activePosMemory;
        },

        setActivePosMemory(content) {
            try {
                this.$store.dispatch('changeActivePosMemory', content);
            } catch {
                return;
            }
        },

        // getCalendarEvents() {
        //     console.log("calendar events from store");
        //     console.log(this.$store.state.calendarEvents);
        //     console.log("calendar events from store");
        //     return this.$store.state.calendarEvents;
        // },

        getBreadCrumbs() {
            return this.$store.state.breadCrumbs;
        },

        // setBreadCrumbs(content) {
        //     try {
        //         this.$store.dispatch('setBreadCrumbs', content);
        //     } catch {
        //         return;
        //     }
        // },

        setBreadCrumb(bread_crumb) {
            try {
                this.$store.dispatch('setBreadCrumb', bread_crumb);
            } catch {
                return;
            }
        },

        // setCalendarEvents(content) {
        //     try {
        //         this.$store.dispatch('setCalendarEvents', content);
        //     } catch {
        //         return;
        //     }
        // },

        // setCalendarEvent(event) {
        //     try {
        //         this.$store.dispatch('setCalendarEvent', event);
        //     } catch {
        //         return;
        //     }
        // },

        isMobileDevice() {
            return this.$store.state.isMobile;
        },

        isMediumScreen() {
            return this.$store.state.isMediumScreen;
        },

        isMenuCollapsed() {
            return this.$store.state.menuCollapsed;
        },

        CollapseMenu() {
            this.$store.dispatch("collapseMenu");
        },

        ToggleMenu() {
            this.$store.dispatch("toggleMenu");
        },

        getproductionHubContent() {
            return this.$store.state.productionHubContent;
        },

        setproductionHubContent(content) {
            try {
                this.$store.dispatch('changeproductionHubContent', content);
            } catch {
                return;
            }
        },

        // getActiveCashJournal() {
        //     return this.$store.state.activeCashJournal;
        // },

        // setActiveCashJournal(content) {
        //     try {
        //         this.$store.dispatch('changeActiveCashJournal', content);
        //     } catch {
        //         return;
        //     }
        // },

        getActivePosMemoryConsumption() {
            return this.$store.state.activePosMemoryConsumption;
        },

        setActivePosMemoryConsumption(content) {
            try {
                this.$store.dispatch('changeActivePosMemoryConsumption', content);
            } catch {
                return;
            }
        },

        async getPosMenus() {
            let pos_menus = this.$store.state.posMenus;
            //alert(JSON.stringify(pos_menus));
            if (pos_menus.length > 0) {
                return pos_menus;
            } else {
                let result = await this.$root.ExecuteActionPOS({'action': 'POS.getPOSFirstMenuMenu', 'args': {}}).catch(err => {console.error(err);});
                if (result[0] == "success" && result[1].length > 0) {
                    pos_menus = typeof result[1] === "string" ? JSON.parse(result[1]) : result[1];
                    // pos_menus_str = typeof pos_menus == 'object' ? JSON.stringify(pos_menus) : pos_menus;
                    this.$store.dispatch('setPosMenus', result[1]);
                    return pos_menus;
                } else {
                    return null;
                }
            }
        },

        getLastPrinted() {
            return this.$store.state.lastPrinted;
        },

        setLastPrinted(content) {
            try {
                this.$store.dispatch('setLastPrinted', content);
            } catch {
                return;
            }
        },

        getSearchPosMenuProducts() {
            console.log("getSearchPosMenuProducts");
            return this.$store.state.searchPosMenuProducts;
        },

        setSearchPosMenuProducts(content) {
            try {
                this.$store.dispatch('setSearchPosMenuProducts', content);
            } catch {
                return;
            }
        },

        getPOSKitchenAccounts() {
            return this.$store.state.posKitchenAccounts;
        },

        setPOSKitchenAccounts(content) {
            try {
                this.$store.dispatch('changePOSKitchenAccounts', content);
            } catch {
                return;
            }
        },

        getStateTabs(tabsInfo) {
            var content = this.$store.state.loadedObjects[tabsInfo.objName + tabsInfo.Id];
            if (tabsInfo.fullFieldName) {
                tabsInfo.fullFieldName.split('.').forEach(fn => {
                    if (fn) {
                        content = content.Fields[fn];
                    }
                });
            }
            return content.Tabs;
        },

        async getObjectTabs(objName) {
            if (objName) {
                var content = await this.getModel(objName).catch(err => {console.error(err);});
                if (content) {
                    return content.Tabs;
                }
            }
            return null;
        },

        getPropertyAsOptions(propertyInfo) {
            let result = [];
            try {
                var content = this.$store.state.loadedObjects[propertyInfo.objName + propertyInfo.Id];
                var arr_property = propertyInfo.property.split('.');
                for (let i = 0; i < arr_property.length - 1; i++) {
                    content = content[arr_property[i]];
                }
                if (content) {
                    for (let index in content) {
                        let opt = content[index][arr_property[arr_property.length - 1]];
                        result.push([opt, opt]);
                    }
                }
                return result;
            } catch {
                return result;
            }
        },

        getSumOfPropertyValue(propertyInfo) {
            let total = 0;
            try {
                var content = {}
                if (propertyInfo.content) {
                    content = propertyInfo.content;
                } else {
                    content = this.$store.state.loadedObjects[propertyInfo.objName + propertyInfo.Id];
                    if (propertyInfo.fieldPath) {
                        let arr_path = [];
                        for (let path of propertyInfo.fieldPath.split(".")) {
                            arr_path.push("Fields");
                            arr_path.push(path);
                        }
                        arr_path.push(propertyInfo.property)
                        propertyInfo.property = arr_path.join(".");
                    }
                }
                if (content) {
                    if (Array.isArray(content)) {
                        for (let idx in content) {
                            propertyInfo.content = content[idx];
                            total += this.getSumOfPropertyValue(propertyInfo);
                        }
                    } else {
                        var arr_property = propertyInfo.property.split('.');
                        for (let i = 0; i < arr_property.length - 1; i++) {
                            content = content[arr_property[i]];
                            if (Array.isArray(content)) {
                                var actPath = arr_property.slice(i + 1, arr_property.length).join(".");
                                propertyInfo.property = actPath;
                                propertyInfo.content = content;
                                total += this.getSumOfPropertyValue(propertyInfo);
                            }
                        }
                    }
                }
                if (content) {
                    for (let index in content) {
                        if (arr_property && content[index] && content[index][arr_property[arr_property.length - 1]]) {
                            total += parseFloat(content[index][arr_property[arr_property.length - 1]]);
                        }
                    }
                }
                return total;
            } catch {
                return total;
            }
        },

        getPropertyValue(propertyInfo) {
            let result = null;
            try {
                var content = this.$store.state.loadedObjects[propertyInfo.objName + propertyInfo.Id];
                var arr_property = propertyInfo.property.split('.');
                for (let i = 0; i < arr_property.length - 1; i++) {
                    content = content[arr_property[i]];
                }
                if (content) {
                    result = content[arr_property[arr_property.length - 1]];
                }
                return result;
            } catch {
                return result;
            }
        },

        getFieldValue(fieldInfo) {
            var content = this.$store.state.loadedObjects[fieldInfo.objName + fieldInfo.Id];
            fieldInfo.fullFieldName.split('.').forEach(fn => {
                if (fn) {
                    content = content.Fields[fn];
                }
            });
            return content.Value;
        },

        getFieldShowValue(fieldInfo) {
            var content = this.$store.state.loadedObjects[fieldInfo.objName + fieldInfo.Id];
            fieldInfo.fullFieldName.split('.').forEach(fn => {
                if (fn) {
                    content = content.Fields[fn];
                }
            });
            if (typeof content.ShowValue == 'undefined') {
                //Vue.set(content, 'ShowValue', "");                    
                return;
            }
            return content.ShowValue;
        },

        getFieldValues(fieldInfo) {
            var content = this.$store.state.loadedObjects[fieldInfo.objName + fieldInfo.Id];
            fieldInfo.fullFieldName.split('.').forEach(fn => {
                if (fn) {
                    content = content.Fields[fn];
                }
            });
            if (typeof content.Values == 'undefined') {
                return [];
            }
            return content.Values;
        },

        setFieldValue(fieldInfo) {
            try {
                this.$store.dispatch('changeObjectFieldValue', fieldInfo);
            } catch {
                return;
            }
        },

        setFieldShowValue(fieldInfo) {
            try {
                this.$store.dispatch('changeObjectFieldShowValue', fieldInfo);
            } catch {
                return;
            }
        },

        setFieldValues(fieldInfo) {
            try {
                this.$store.dispatch('changeObjectFieldValues', fieldInfo);
            } catch {
                return;
            }
        },

        // setChildFieldContent(contentInfo) {
        //     this.$store.dispatch('setChildFieldContent', contentInfo);
        // },

        removeObject(str_key_name) {
            this.$store.dispatch('removeObject', str_key_name);
        },

        removeActivePosMemory() {
            this.$store.dispatch('removeActivePosMemory');
        },

        removePartialPosMemory(row) {
            this.$store.dispatch('removePartialPosMemory', row);
        },

        changePOSMovementQuantity(values) {
            this.$store.dispatch('changePOSMovementQuantity', values);
        },

        removeActivePosMemoryConsumption() {
            this.$store.dispatch('removeActivePosMemoryConsumption');
        },

        // removePosMenus() {
        //     this.$store.dispatch('removePosMenus');
        // },

        // removeLastPrinted() {
        //     this.$store.dispatch('removeLastPrinted');
        // },

        // removeSearchPosMenuProducts() {
        //     this.$store.dispatch('removeSearchPosMenuProducts');
        // },

        setObjectSaved(propertyInfo) {
            this.$store.dispatch('setObjectSaved', propertyInfo);
        },

        async updateObjectProperty(propertyInfo) {
            this.$store.dispatch('changeObjectProperty', propertyInfo);
        },

        async setUploadFieldSaved({ objName, Id, fields, path }) {
            if (fields) {
                let str_path = path;
                for (let field_name in fields) {
                    if ((fields[field_name].Type == 'Upload' || fields[field_name].Type == 'DICOMImage') && fields[field_name].Values) {
                        if (str_path) {
                            await this.updateObjectProperty({ 'objName': objName, 'Id': Id, 'path': str_path + '.Fields.' + field_name + '.Values', 'value': { 'Saved': 'true' } }).catch(err => {console.error(err);});
                        } else {
                            await this.updateObjectProperty({ 'objName': objName, 'Id': Id, 'path': 'Fields.' + field_name + '.Values', 'value': { 'Saved': 'true' } }).catch(err => {console.error(err);});
                        }
                    } else if (fields[field_name].Type == 'List') {
                        let str_path_new = str_path ? str_path + ".Fields." + field_name : 'Fields.' + field_name;
                        await this.setUploadFieldSaved({ 'objName': objName, 'Id': Id, 'fields': fields[field_name].Fields, 'path': str_path_new }).catch(err => {console.error(err);});
                    }
                }
            }
        },

        setListFieldValuesSaved(objName, Id, fields) {
            if (fields) {
                for (let field_name in fields) {
                    if (fields[field_name].Type == 'List' && fields[field_name].Values) {
                        let content = this.getFieldValues({ 'objName': objName, 'Id': Id, 'fullFieldName': field_name });
                        this.removeNewPartFromId(content);
                    }
                }
            }
        },

        removeNewPartFromId(content) {
            if (content) {
                if (Array.isArray(content)) {
                    for (let obj of content) {
                        if (obj.Id && obj.Id.startsWith("new_")) {
                            obj.Id = obj.Id.substring(4);
                        }
                        for (let field of Object.keys(obj)) {
                            this.removeNewPartFromId(obj[field]);
                        }
                    }
                }
            }
        },

        async recalculateValueOfFieldFunction(objName, Id, objContent, path) {
            //alert("recalculateValueOfFieldFunction: " + path);
            if (objName && objContent) {
                let str_path = path;
                let actionName;
                //alert(actionName);
                let valueRes;
                for (let field_name in objContent.Fields) {
                    if (objContent.Fields[field_name].Function) {
                        if (str_path) {
                            null;
                        } else {
                            actionName = objName + '.' + objContent.Fields[field_name].Name + "Function";
                            valueRes = await this.GetFieldFunctionValue({ 'objName': objName, 'Id': objContent.Fields.Id.Value, 'action': actionName, 'field_path': 'Fields.' + field_name, 'args': {} }).catch(err => {console.error(err);});
                            //alert(JSON.stringify(valueRes));
                            if (valueRes.Value_show) {
                                await this.updateObjectProperty({ 'objName': objName, 'Id': Id, 'path': 'Fields.' + field_name, 'value': { 'Value_show': valueRes.Value_show } }).catch(err => {console.error(err);});
                            }
                            await this.updateObjectProperty({ 'objName': objName, 'Id': Id, 'path': 'Fields.' + field_name, 'value': { 'Value': valueRes.Value } }).catch(err => {console.error(err);});
                        }
                    }
                    //recursive call for list fields
                    if (objContent.Fields[field_name].Type == 'List') {
                        let str_path_new = str_path ? str_path + ".Fields." + field_name : 'Fields.' + field_name;
                        await this.recalculateValueOfFieldFunction(objName, Id, objContent.Fields[field_name], str_path_new).catch(err => {console.error(err);});
                    }
                }
            }
        },

        async updateObjectAfterSaved(objInfo) {
            let obj = await this.getObject(objInfo).catch(err => {console.error(err);});
            //set saved=true in upload fields 
            await this.setUploadFieldSaved({ 'objName': objInfo.objName, 'Id': objInfo.Id, 'fields': obj.Fields }).catch(err => {console.error(err);});
            //remove "new_" from all Id in List Field Values
            this.setListFieldValuesSaved(objInfo.objName, objInfo.Id, obj.Fields);
        },

        async updateObjectBeforeSave(objInfo) {
            let obj = await this.getObject(objInfo).catch(err => {console.error(err);});
            //alert("objInfo: " + JSON.stringify(objInfo));
            //recalculate value of fields function            
            await this.recalculateValueOfFieldFunction(objInfo.objName, objInfo.Id, obj).catch(err => {console.error(err);});
        },

        async getObjectRecord(content) {
            let record = {};
            let obj = null;
            if (content.obj) {
                obj = content.obj;
            } else if (content.objName && content.Id) {
                obj = await this.getLocalObjectByName({ 'objName': content.objName, 'Id': content.Id }).catch(err => {console.error(err);});
            }
            if (obj != null && obj.Fields != null && Object.keys(obj.Fields).length > 0) {
                for (let field of Object.keys(obj.Fields)) {
                    if (this.fieldWithValues(obj.Fields[field].Type) && "Values" in obj.Fields[field]) {
                        record[field] = obj.Fields[field].Values;
                    } else if ("Value" in obj.Fields[field]) {
                        record[field] = obj.Fields[field].Value;
                        /*if (this.isFieldNumber(obj.Fields[field].Type)) {
                            if (obj.Fields[field].Value) {
                                record[field] = Number(obj.Fields[field].Value);
                            } else {
                                record[field] = null;
                            }                                
                        } else {
                            record[field] = obj.Fields[field].Value;
                        }*/
                        if ("ShowValue" in obj.Fields[field]) {
                            record[field + "_show"] = obj.Fields[field].ShowValue;
                        }
                    }
                }
            }
            return record;
        },

        async GetFieldFunctionValue(content) {
            //alert("GetFieldFunctionValue");
            if (content) {
                content.record = await this.getObjectRecord(content).catch(err => {console.error(err);});
                let url = API_URL + 'api/py/'; //GetFieldFunctionValue/
                let result = await base.api("POST", url, content).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        // async GetFieldFunctionValues(content) {
        //     // isto está bem?? check e fix if necessary por que chama aqui o GetFieldFunctionValue, então chaaria logo na raiz e isto seria desnecessário
        //     return await this.GetFieldFunctionValue(content).catch(err => {console.error(err);});
        // },

        async ExecuteOnChange(content) {
            if (content) {
                //alert(1);
                //alert(JSON.stringify(content));
                let url = API_URL + 'api/py/'; //ExecuteOnChange/
                let result = await base.api("POST", url, content).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async Authenticate(payload) {
            let url = API_URL + 'api/login/authenticate/';
            let result = await base.api("POST", url, payload).catch(err => {console.error(err);});
            const res = await this.getResponse(result).catch(err => {console.error(err);});
            if (res.token) {
                return { success: "OK" };
            } else {
                return res;
            }
        },

        // async Login(payload) {
        //     this.$worker.run((payload) => {
        //         this.LoginWorker(payload).then((data) => {
        //            return data
        //         })
        //     }).then(result => {
        //         console.log(result)
        //     }).catch(e => {
        //         console.error(e)
        //     })
        // },
        
        async Login(payload) {
            let url = API_URL + 'api/login/authenticate/';
            let result = await base.api("POST", url, payload).catch(err => {console.error(err);});
            const res = await this.getResponse(result).catch(err => {console.error(err);});
            if (res.token) {
                let token = res.token;
                let tokenContent = await base.decodeToken(token).catch(err => {console.error(err);})
                tokenContent["token"] = token;
                if (tokenContent != null) {
                    tokenContent = typeof tokenContent == 'object' ? JSON.stringify(tokenContent) : tokenContent;
                    localStorage.setItem('token', tokenContent)
                }
                if (payload.type && payload.type == "pos") {
                    localStorage.setItem('pos', true);
                    // let posUsers = await this.$root.getPOSUsers();
                    // if (posUsers != null) {
                    //     posUsers = typeof posUsers == 'object' ? JSON.stringify(posUsers[0]) : posUsers[0];
                    //     localStorage.setItem('posUsers', posUsers);
                    // }
                    let contentInfo = {};
                    contentInfo["sidebarName"] = "POSSidebar";
                    contentInfo["obj"] = null;
                    let sidebar = await this.getSidebar("POSSidebar", "AppUser").catch(err => {console.error(err);});
                    console.error(contentInfo)
                    console.error(sidebar)
                    this.$store.dispatch('setActiveSidebar', { "contentInfo": contentInfo, "content": sidebar });
                } else {
                    localStorage.setItem('pos', false);
                }
                if (!payload.change_user) {
                    let i18n = await this.getI18n().catch(err => {console.error(err);});
                    if (i18n != null) {
                        i18n = typeof i18n == 'object' ? JSON.stringify(i18n) : i18n;
                        localStorage.setItem('i18n', i18n);
                    }
                    let pageHeader = await this.getPageHeader().catch(err => {console.error(err);});
                    if (pageHeader != null) {
                        pageHeader = typeof pageHeader == 'object' ? JSON.stringify(pageHeader) : pageHeader;
                        localStorage.setItem('pageHeader', pageHeader);
                    }
                    let calendars = await this.GetUserCalendars().catch(err => {console.error(err);});
                    if (calendars != null) {
                        calendars = typeof calendars == 'object' ? JSON.stringify(calendars) : calendars;
                        localStorage.setItem('calendars', calendars);
                    }
                }
                return { success: "OK" };
            } else {
                return res;
            }
        },

        async Logout() {
            let pos = localStorage.getItem('pos');
            if (pos == "true") {
                router.push('/pos/auth/login').catch(() => {console.log('erro de router.push em logout')});
            } else {
                router.push(`/auth/login?redirect=${this.$route.fullPath}`).catch(() => {console.log('erro de router.push em rediret to login')});
            }
            localStorage.removeItem('pageHeader');
            localStorage.removeItem('calendars');
            localStorage.removeItem('i18n');
            localStorage.removeItem('token');
            localStorage.removeItem('enterpriseInfo');
            localStorage.removeItem('posUsers');
            this.$store.dispatch('removeListFormNavegationInfo');
            this.$store.dispatch('removeActivePosMemoryConsumption');
            this.$store.dispatch('removeActivePosMemory');
            this.$store.dispatch('removePosMenus');
            this.$store.dispatch('removeLastPrinted');
            this.$store.dispatch('removeSearchPosMenuProducts');
            this.$store.dispatch('removeLoadedObjects');
            this.$store.dispatch('removeLoadedDataObjects');
            this.$store.dispatch('removeActiveSidebar');
            this.$store.dispatch('removeLoadedModels');
            this.$store.dispatch('removeListSystemMessage');
            this.$store.dispatch('removeBreadCrumbs');
            //this.$store.dispatch('removeCalendarEvents');
        },

        // async ResetPassword(email) {
        //     console.log(email);
        // },

        async getDashboard(dashboardName, args) {
            if (dashboardName) {
                let url = API_URL + 'api/dashboard/getDashboard';
                let result = await base.api("POST", url, { "dashboardName": dashboardName, "args": args }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
        },

        async getResponse(response) {
            if (response.status >= 200 && response.status < 300) {
                let res = await response.json().catch(err => {console.error(err);});
                try {
                    return JSON.parse(res);
                } catch {
                    return res;
                }
            } else if (response.status == 401) {
                return { type: "error", message: "Sem Autorização!!" };
            } else if (response.status == 403) {
                this.$router.go(-1)
                return { type: "error", message: "Proibido!!" };
                // return null;
            } else if (response.status == 406) {
                let res = await response.json().catch(err => {console.error(err);});
                await this.ShowMessage({type: "error", message: res});
                return {type: "error", message: res};
            } else if (response.success == false) {
                this.apiConnected = false;
                return { type: "error", message: "Sem Conexão com o servidor. Contacte o administrador!" };
            } else {
                let message_txt = await response.status;
                if (response.hasOwnProperty("text")) {
                    message_txt = await response.text().catch(err => {console.error(err);});
                }
                if (!message_txt) {
                    message_txt = "Erro do servidor, Contacte o Administrador!!"
                }
                return { type: "error", message: message_txt };
            }
        },


        async getI18n() {
            let url = API_URL + 'api/action/GetAppTranslation';
            let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});
        },


        async getGIS(name) {
            // let sessionStorage = await base.getSessionStorage().catch(err => {console.error(err);});
            let _defaultMap = this.$store.state.defaultMap;
            if (_defaultMap && (Object.keys(_defaultMap).length > 0 && _defaultMap.constructor === Object)) {
                return _defaultMap;
            } else {
                if (name) {
                    let url = API_URL + 'api/gis/' + name;
                    let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
                    let res = await this.ProcessResponse(result).catch(err => {console.error(err);});
                    if (res != null) {
                        this.$store.dispatch('setDefaultMap', res);
                    }
                    return res;
                }
            }
            return null;
        },

        async getPageHeader() {
            let headerName = "PageHeader";
            let url = API_URL + 'api/pageheader/getPageHeader';
            let result = await base.api("POST", url, { "headerName": headerName }).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});

        },

        // async getAllModels() {
        //     let url = API_URL + 'api/obj/GetAllModels/';
        //     let result = await base.api("POST", url, {}).catch(err => {console.error(err);});
        //     return await this.ProcessResponse(result).catch(err => {console.error(err);});
        // },

        async changeActiveSidebar(contentInfo) {
            if (contentInfo.sidebarName != undefined) {
                // let sessionStorage = await base.getSessionStorage().catch(err => {console.error(err);});
                let active_side_bar = this.$store.state.activeSidebar;
                let sidebar = null;
                if (active_side_bar && active_side_bar.contentInfo == contentInfo) {
                    sidebar = active_side_bar.content;
                } else {
                    sidebar = await this.getSidebar(contentInfo.sidebarName, contentInfo.obj).catch(err => {console.error(err);});
                }
                this.$store.dispatch('setActiveSidebar', { "contentInfo": contentInfo, "content": sidebar });
            }
        },

        async getSidebar(sideBarName, obj) {
            if (sideBarName) {
                let record = await this.getObjectRecord({ 'obj': obj }).catch(err => {console.error(err);});
                let url = API_URL + 'api/sidebar/getSidebar';
                let result = await base.api("POST", url, { "sideBarName": sideBarName, "Record": record }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
        },

        async getLanguage() {
            let result = localStorage.getItem('i18n');
            if (result && result != null) {
                return typeof result == 'string' ? JSON.parse(result) : result;
            } else {
                result = await this.getI18n().catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
        },

        async getPOSUsers() {
            let result = localStorage.getItem('posUsers');
            // alert(JSON.stringify(result));
            if (result && result != null) {
                return typeof result == 'string' ? JSON.parse(result) : result;
            } else {
                let url = API_URL + 'api/pos/getPOSUsers';
                let result = await base.api("POST", url).catch(err => {console.error(err);});
                let pos_users = await this.ProcessResponse(result).catch(err => {console.error(err);});
                if (pos_users != null) {
                    pos_users = typeof pos_users == 'object' ? JSON.stringify(pos_users) : pos_users;
                    localStorage.setItem('posUsers', pos_users);
                }
                return typeof pos_users == 'string' ? JSON.parse(pos_users) : pos_users;
            }
        },

        async getEnterpriseInfo() {
            let result = localStorage.getItem('enterpriseInfo');
            if (result && result != null) {
                return typeof result == 'string' ? JSON.parse(result) : result;
            } else {
                let url = API_URL + 'api/pos/getEnterpriseInfo';
                let result = await base.api("POST", url).catch(err => {console.error(err);});
                let enterprise_info = await this.ProcessResponse(result).catch(err => {console.error(err);});
                enterprise_info = enterprise_info[0][0];
                if (enterprise_info != null) {
                    enterprise_info = typeof enterprise_info == 'object' ? JSON.stringify(enterprise_info) : enterprise_info;
                    localStorage.setItem('enterpriseInfo', enterprise_info);
                }
                return enterprise_info;
            }
        },

        // async getEnterpriseInfoForCalendar() {
        //     let url = API_URL + 'api/calendar/getEnterpriseInfoForCalendar';
        //     let result = await base.api("POST", url);
        //     return await this.ProcessResponse(result);
        // },

        async getObjectData(objInfo) {
            if (objInfo.objName) {
                let url = API_URL + 'api/obj/' + objInfo.objName + '/' + objInfo.Id;
                let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            await this.ShowMessage({ type: "error", message: "Nome do objeto vazio!" });
            return null;
        },

        async userIsCashier() {
            // isto não faz sentido estar aqui pois é muito especifico, fix later
            let token = await this.$getToken().catch(err => {console.error(err);});
            let teams = token.Teams;
            if (typeof teams == "string") {
                teams = JSON.parse(teams);
            }
            return teams.some((team, index, array) => team.Role_show === 'Responsável de Caixa');
        },

        async getYamlObjectData(objInfo) {
            if (objInfo.objName) {
                let url = API_URL + 'api/admin/' + objInfo.objName + '/' + objInfo.Id;
                let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            await this.ShowMessage({ type: "error", message: "Nome do objeto vazio!" });
            return null;
        },

        async deleteObj(Name, Id) {
            if (Name && Id) {
                let url = API_URL + 'api/obj/' + Name + '/' + Id;
                let result = await base.api("DELETE", url, {}).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async saveObj(name, obj) {
            //we do not need the full obj send only the record, a few bytes performance booster, heheheeheh
            if (name && obj) {
                let record = {};
                let active_tabs = [];
                if (obj.data.Tabs) {
                    for (let tab in obj.data.Tabs) {
                        if (obj.data.Tabs[tab].Hidden != "true") {
                            active_tabs.push(tab);
                        }
                    }
                }
                /*for (let field of Object.keys(obj.data.Fields)) {
                    if (obj.data.Fields[field].Hidden != "true" || field == "Id"
                    || obj.data.Fields[field].Default != null) {
                        if ((active_tabs.length == 0) || field == "Id" || ((active_tabs.length > 0) && active_tabs.includes(obj.data.Fields[field].Tab))) {
                            if ("Value" in obj.data.Fields[field] && !this.fieldWithValues(obj.data.Fields[field].Type)) {
                                if (obj.data.Fields[field].Type != "Separator") {                            
                                    if (["Currency", "Percent", "Decimal", "Integer", "Float" ].includes(obj.data.Fields[field].Type)) {
                                        record[field] = Number(obj.data.Fields[field].Value);
                                    } else {
                                        record[field] = obj.data.Fields[field].Value;
                                    }
                                }
                            }
                            if ("Values" in obj.data.Fields[field] && this.fieldWithValues(obj.data.Fields[field].Type)) {
                                record[field] = obj.data.Fields[field].Values;
                            }
                        }
                    }
                }*/
                record = await this.getObjectRecord({ "obj": obj.data }).catch(err => {console.error(err);});
                let contents = { "duplicate": obj.duplicate, "data": record };
                let url = API_URL + 'api/obj/' + name;
                let result = await base.api("POST", url, contents).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async BulkSaveObj(name, all_content) {
            if (name && all_content) {
                let contents = { "duplicate": false, "data": all_content, objName: name };
                let url = API_URL + 'api/obj/BulkSave';
                let result = await base.api("POST", url, contents).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async ExecuteAction(content) {
            if (content) {
                if (!content.form || content.form != 'List') {
                    content.record = await this.getObjectRecord({ 'obj': content.record }).catch(err => {console.error(err);});
                }
                let url = API_URL + 'api/py/'; //ExecuteAction/
                console.log('------------------------------------------before execute action----------------------------------')
                let result = await base.api("POST", url, content).catch(err => {console.error(err);});
                console.log('------------------------------------------execute action----------------------------------')
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async ExecuteActionPOS(content) {
            if (content) {
                // console.log(content);
                // alert("vê a console");
                let url = API_URL + 'api/py/'; //ExecuteAction/
                let result = await base.api("POST", url, content).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async GetConsumptionTableData(memory) {
            if (memory) {
                let token = await this.$getToken().catch(err => {console.error(err);});
                let url = API_URL + 'api/obj/GetAll/';
                let where = `"POSMemoryMovement"."POSMemoryId" = '${memory.Id}' and "POSMemoryMovement"."POSId" = '${token.POSId}'`;
                let return_fields = ["POSMemoryId", "Description", "ProductId", "UnitId", "Quantity", "SalePrice", "SalePriceWithoutTax", "TotalValue", "UserId", "POSId", "Active", "Id", "ProductId.Name", "UnitId.Symbol"]
                let result = await base.api("POST", url, {
                    "objName": "POSMemoryMovement",
                    "where": where,
                    "return_fields": return_fields,
                }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
        },

        async GetCashJournal() {
            let token = await this.$getToken().catch(err => {console.error(err);});
            let terminalId = token.POSId;
            if (terminalId) {
                let url = API_URL + 'api/pos/getCashJournal/';
                let result = await base.api("POST", url, {
                    "objName": "POSCashJournal",
                    "terminalId": terminalId
                }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
        },

        async ApplyProperties(contentInfo) {
            if (contentInfo) {
                let url = API_URL + 'api/obj/ApplyProperties';
                let result = await base.api("POST", url, contentInfo).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
        },

        async getGisFileContent(name) {
            if (name) {
                let url = API_URL + 'api/gis/getJsonContent';
                let result = await base.api("POST", url, { "fileName": name }).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null
        },

        async getGantt(objName, Id) {
            if (objName) {
                let url = API_URL + 'api/gantt/' + objName + '/' + Id;
                let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async getAdminFileList(type) {
            let url = API_URL + 'api/admin/' + type + '/list';
            let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});
        },

        async getProcessList() {
            let url = API_URL + 'api/process/getProcessList';
            let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});
        },

        async getBPMFile(name) {
            if (name) {
                let url = API_URL + 'api/process/GetBPMFile/' + name;
                let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        async getDMNFile(name) {
            if (name) {
                let url = API_URL + 'api/rule/GetDMNFile/' + name;
                let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        // async getReport(reportName, args) {
        //     if (reportName) {
        //         let url = API_URL + 'api/report/getReport';
        //         let headers = { "Content-Type": "application/json; charset=utf-8;", "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*" };
        //         let token = await this.$getToken().catch(err => {console.error(err);});
        //         if (token) {
        //             token = token.token;
        //             headers['Authorization'] = `Bearer ${token}`;
        //         }
        //         if (args == undefined) {
        //             args = {};
        //         }
        //         args["reportName"] = reportName;
        //         let extension = ".docx";
        //         if (args["Type"] == "Excel") {
        //             extension = ".xlsx";
        //         }
        //         let body = JSON.stringify(args);
        //         let requestOptions = {
        //             method: "POST",
        //             headers: headers,
        //             body: body,
        //         };
        //         try {
        //             const response = await fetch(url, requestOptions).catch(err => {console.error(err);});
        //             if (response.status >= 200 && response.status < 300) {
        //                 let blob = await response.blob().catch(err => {console.error(err);});
        //                 let link = document.createElement("a");
        //                 link.download = reportName + extension;
        //                 let file = window.URL.createObjectURL(blob);
        //                 link.href = file;
        //                 document.body.appendChild(link);
        //                 link.click();
        //                 document.body.removeChild(link);
        //                 window.URL.revokeObjectURL(file);
        //                 this.$router.go(-1);
        //             } else {
        //                 var error = new Error(response.statusText);
        //                 //error.response = response;
        //                 //throw error;
        //                 await this.ShowMessage({ message: error, type: "error" });
        //             }
        //         } catch (error) {
        //             await this.ShowMessage({ message: error, type: "error" });
        //         }
        //     }
        // },

        async getRuleList() {
            let url = API_URL + 'api/rule/getRuleList';
            let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
            return await this.ProcessResponse(result).catch(err => {console.error(err);});
        },

        async getTree(objName) {
            if (objName) {
                let url = API_URL + 'api/tree/' + objName;
                let result = await base.api("GET", url, {}).catch(err => {console.error(err);});
                return await this.ProcessResponse(result).catch(err => {console.error(err);});
            }
            return null;
        },

        // timeout(ms) {
        //     return new Promise(resolve => setTimeout(resolve, ms));
        // },

        formatColumnValue(field, cellValue, cellValueShow = null, list_fild_relation = null) {
            if (cellValue != undefined) {
                const field_type = field.Type;
                if (field_type) {
                    if (this.isFieldNumber(field_type) || ["Date-Time", "Time", "Date", "Color", "Boolean", "Parent", "Choice", "Url"].includes(field_type)) {
                        if (this.isFieldNumber(field_type)) {
                            if (field_type == "Percent") {
                                return this.formatNumber(cellValue) + " <small><small>%</small></small>"
                            } else if (field_type == "Currency") {
                                return this.formatNumber(cellValue) + " <small><small>" + (field.Symbol ? field.Symbol : "CVE</small></small>");
                            }
                            return this.formatNumber(cellValue);
                        } else if (field_type == 'Color') {
                            return `<div style="background-color: ${cellValue}">&nbsp</div>`;
                        } else if (field_type == 'Boolean') {
                            return `<div role="switch" class="el-switch ${cellValue == true ? 'is-checked' : ''}">
                                        <span class="el-switch__core" style="width: 40px;
                                            border-color: ${cellValue == true ? 'rgb(19, 206, 102)' : 'rgb(255, 73, 73, 0.2)'}; 
                                            background-color: ${cellValue == true ? 'rgb(19, 206, 102)' : 'rgb(255, 73, 73, 0.05)'};"
                                        />
                                    </div>`;
                        } else if (["Parent", "Choice"].includes(field_type)) {
                            var num = Number(cellValueShow);
                            if (num || num === 0) {
                                return this.formatNumber(num);
                            }
                            if (field.Relation && !field.RelationFields) {
                                return `<a target="_blank" href="/view/${field.Relation}/${cellValue}/true"><i class="el-icon-link"> ${cellValueShow}</i></a>`;
                            }
                        } else if (field_type == 'Url') {
                            return `<a target="_blank" href="${cellValue}"><i class="el-icon-link" style="color:#3498DB"> ${cellValue}</i></a>`;
                        } else {
                            const d = new Date(cellValue);
                            if (field_type == "Date-Time") {
                                return d.toLocaleDateString() + " " + d.toLocaleTimeString();
                            } else if (field_type == "Date") {
                                return d.toLocaleDateString();
                            } else if (field_type == "Time") {
                                if (field.Format && field.Format == "mm:ss") {
                                    return d.toLocaleTimeString([], { minute: '2-digit', second: '2-digit' });
                                } else {
                                    return d.toLocaleTimeString();
                                }
                            }
                        }

                    } else if (field_type == "Guid" && field.Name == "Id" && list_fild_relation) {
                        return `<a href="/edit/${list_fild_relation}/${cellValue}"><i class="far fa-edit"> Editar</i></a>`;
                    }
                }
            }
            return cellValueShow ? cellValueShow : cellValue;
        },

        //toda a funcao no edit e list deverá passar para aqui
        async processCommandResponse(response) {
            console.error('------------------------------- process command response--------------------------')
            console.error(response)
            if (response) {
                if (response.direct_print) {
                    let pendent_for_direct_print = this.$store.state.pendentForDirectPrint;
                    for (let print_obj of response.direct_print) {
                      let printer = print_obj.printer;
                      if ('text_to_print' in print_obj) {
                        if (print_obj.memory_name in pendent_for_direct_print) {
                            pendent_for_direct_print[print_obj.memory_name].push(print_obj);
                        } else {
                            pendent_for_direct_print[print_obj.memory_name] = [print_obj];
                        }
                        //let text_to_print = print_obj.text_to_print;
                        //let print_server_url = print_obj.print_server_url;
                        //let result = 
                        // this.$root.DirectPrint(printer, print_server_url, text_to_print, 'text').then (
                        //     async(result) => { if (result != true) {
                        //         await this.$root.ShowMessage({ message: 'Erro a contactar o servidor de impressão', type: 'warning'});
                        // }});
                      }
                      if ('content' in print_obj) {
                        let content = print_obj.content;
                        let print_server_url = print_obj.print_server_url;
                        //let result = 
                        this.$root.DirectPrint(printer, print_server_url, content, 'pdf').then (
                            async(result) => { if (result != true) {
                                await this.$root.ShowMessage({ message: 'Erro a contactar o servidor de impressão', type: 'warning'});
                        }});
                        // let result = await this.$root.DirectPrint(printer, print_server_url, content, 'pdf');
                        // //alert(result);
                        // if (result != true) {
                        //   await this.$root.ShowMessage({ message: 'Erro a contactar o servidor de impressão', type: 'warning'});
                        // }
                      }
                    }
                    this.$store.dispatch('pendentForDirectPrint', pendent_for_direct_print);
                }
                if (response.run_next) {
                    for (var rn in response.run_next) {
                        if (response.run_next[rn][0] == 'redirect') {
                            this.$router.push({ path: response.run_next[rn][1] + '/true' }).catch(() => {console.log('erro de router push em process comand response, redirect')});
                        } else if (response.run_next[rn][0] == 'closeDialog') {
                            this.$emit("closeDialog");
                        } else if (response.run_next[rn][0] == 'print') {
                            if (response["content"]) {
                                var mywindow = window.open('', '_new');
                                mywindow.document.write(response["content"]);
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10*/
                                setTimeout(() => { mywindow.print(); mywindow.close(); }, 500);
                            }
                            return true;
                        } else if (response.run_next[rn][0] == 'show_document') {
                            if (response["content"]) {
                                var mywindow = window.open('', '_new');
                                mywindow.document.write(response["content"]);
                                mywindow.document.close(); // necessary for IE >= 10
                                mywindow.focus(); // necessary for IE >= 10*/
                            }
                            return true;
                        } else if (response.run_next[rn][0] == 'removePosMemoryData') {
                            this.removeActivePosMemory();
                            this.removeActivePosMemoryConsumption();
                        } else if (response.run_next[rn][0] == 'removePartialPosMemoryData') {
                            this.removePartialPosMemory(response.values);
                        } else if (response.run_next[rn][0] == 'changePOSMovementQuantity') {
                            this.changePOSMovementQuantity(response.values);
                        } else if (response.run_next[rn][0] == 'exportToExcel') {
                            var wb = XLSX.utils.book_new();
                            for (let sheet_name of Object.keys(response.obj)) {
                                var sheet = XLSX.utils.json_to_sheet(response.obj[sheet_name]);
                                XLSX.utils.book_append_sheet(wb, sheet, sheet_name);
                            }
                            XLSX.writeFile(wb, 'Anexo.xlsx');
                        } else if (response.run_next[rn][0] == 'download_to_file') {
                            let dataExport = response["content"]
                            let fileName = response["fileName"]
                            let mimeType = response["mimeType"]
                            if (this.encoding === "utf-8") {
                                dataExport = "\ufeff" + dataExport;
                            }
                            let blob = new Blob([dataExport], {
                                type: mimeType + ";charset=" + this.encoding
                            });
                            saveAs(blob, fileName);
                        // } else if (response.run_next[rn][0] == 'updateInterfaceRoute') {
                        //     await this.UpdateInterfaceRoute(response.record);
                        // } else {
                            /*console.warning(this.i18n.NotImplementedCommand.replace('{command_name}', response.run_next[rn][0]));*/
                        }
                    }
                }

                if (response.messages) {
                    for (let idx in response.messages) {
                        await this.ShowMessage({ message: response.messages[idx][1], type: response.messages[idx][0] });
                    }
                }
            }
        },

        // async UpdateInterfaceRoute(record) {
        //     if (record && record.ProductionCenterId) {
        //         //let type = record.ProductionCenterId_show;
        //         // let where = `"ProductionCenter"."Id" = '${record.ProductionCenterId}'`;
        //         // let return_fields = ["Type"]
        //         // let result = await this.getAllData({name:"ProductionCenter", where: where,  return_fields: return_fields});
        //         // if(result.length > 0) {
        //         //     type = result[0].Type;
        //         // }
        //         /* 
        //             * ISTO DEVERÁ FICAR NO HUB DO FRONTEND
        //             */
        //         //content["route"] = type;
        //         //content["data"] = record.ProductionOrderLines;
        //         let result = await this.$root.ExecuteActionPOS({ 'action': "ProductionOrder.generateHubMovement", 'args': { 'record': record } });
        //         await this.syncResults(result.ProductionOrderLines);
        //     }
        // },

        // //TEMPORARIO
        // async syncResults(results) {
        //     if (results != null) {
        //         this.sginalRConnection.invoke('SendProductionCenterMovement', results)
        //         // .cacth(function (err) {
        //         //     console.log(err.toString());
        //         // });
        //     }
        // },

        // //TEMPORARIO
        // async configureSignalR() {
        //     var API_URL = localStorage.getItem('API_URL');
        //     this.sginalRConnection = new HubConnectionBuilder()
        //         .withUrl(API_URL + 'getProductionCenterMovementHub')
        //         .configureLogging(LogLevel.Information)
        //         .build();
        //     this.sginalRConnection.start().then(() => {
        //     });
        // },

        // async getNews() {
        //     return await this.$root.ExecuteActionPOS({ 'action': "Utils.GetNews", 'args': {} });
        // }

    },

    watch: {
        apiConnected: async function (isConnected) {
            if (isConnected == null || !isConnected) {
                let result = null;
                let isRun = true;
                let mainLoading = this.getLoadingWithText({ text: "Sem Conexão com o servidor. Contacte o administrador!", background: "rgba(0, 0, 0, 0.8)" });
                while (isRun == true) {
                    result = await base.pingToBackend().catch(err => {console.error(err);});
                    if (result && result == 'OK') {
                        mainLoading.close();
                        this.apiConnected = true;
                        isRun = false;
                    }
                    await this.timeout(1500);
                }
                mainLoading.close();
                await this.ShowMessage({ type: "success", message: "Conexão estabelicida com o servidor!" });
            }
        },

        $route(to, from, next) {
            /*let confirmed = null;
            await this.$confirm(this.i18n.confirmMessage.replace('{actionName}', null), 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                confirmed = true;
            }).catch(() => {
                confirmed = false;
            });*/
            if (from.name == 'edit' && from.params.objName && from.params.Id) {
                if (!to.params.objName || to.params.objName != from.params.objName || (from.params.objName == to.params.objName && from.params.Id != to.params.Id)) {
                    try {
                        base.unlockObject(from.params.objName, from.params.Id);
                    } catch {
                        // just a test to see if it explodes here
                        alert("só um teste!");
                    }
                }
            }
            if (to.name == 'edit' && to.params.objName && to.params.Id) {
                base.lockObject(to.params.objName, to.params.Id);
            }
            next();
        },
    },

    render: (h) => h(app),
}).$mount('#app');

export {
    Vue
}

router.replace('/'); 
