<template>
    <el-col class="memory-column" :xs="12" :sm="8" :md="5" :lg="4" :xl="4" v-if="renderCondition!=false"> 
      <el-result class="memory_name"
        @click.native="setMemory()" >
        <template slot="icon">
          <pos-file-image 
            :fileId="objContent['AppFileId']" 
            :active="active"
            :type="objContent.Type?objContent.Type:'account'"
            :size="115">
          </pos-file-image>
          <p>{{objContent.Name}}</p>
        </template>
      </el-result>
    </el-col> 
</template>
<script>

export default {
  name: "POSmemory",
  props:["objContent", "renderCondition", "busyAccounts"],
  data() {
      return {
          url:'',
          active: "active"
      }
  },
  methods: {
    setMemory() {
      this.$emit("memorySelected", this.objContent);
    },
  
    // async getLink() {            
    //   if (this.objContent['AppFile.Id'] != "") {
    //       let blob = await this.$root.getUploadedFileById(this.objContent['AppFileId']); 
    //       if (blob) {
    //           return window.URL.createObjectURL(blob); 
    //       }                             
    //   } 
    //   return null;
    // }
  },

  async mounted() {
    if (this.busyAccounts.length > 0) {
      this.busyAccounts.forEach(element => {
        if (element.POSMemoryId == this.objContent.Id) {
          this.active = "inactive";
          return;
        }        
      });
    }
  }
}
</script>

<style scoped>
  ::v-deep .memory_name p {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px;
  }
</style>

<style>
  .image {
    width: 100%;
    display: block;
  }

  .clearfix{
    font-size: 8px;
  } 
  .memory-column {
    margin: 8px 9px 2px 8px;
    cursor: pointer;
 
  }
  .center {
    text-align: center;
  }
</style>