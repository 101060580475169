<template>
  <div>
    <el-dialog 
      :visible.sync="showModal"
      width="40%"
      :before-close="HandleClose"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      class="modal-reprint-memory"
    >
      <edit-form
        :objName=objName
        :Id="new_id"
        :is_pos="true"
        :showTitle="false"
        :canRedirect="false"
        @closeDialog = "closeDialog()"
      />
    </el-dialog>
  </div>
</template>

<script>

export default {
    props: [
      "dialogReprintDocumentVisible",
      "documentType",
      "posId",
      "i18n"
    ],

    data() {
      return {
        i18n: null,
        data: [],
        value: ["_rigth_"],
        new_id: "",
        objName: "POSReprintDocument",
        showModal: false
      };
    },


    methods: {

        async HandleClose(done) {
          this.dialogReprintDocumentVisible=false;
          this.$emit("closed");
          done();
        },

        async closeDialog() {
          this.dialogReprintDocumentVisible=false;
          this.$emit("closed");
        },
    },

    async created() {
      // alert(this.dialogReprintDocumentVisible);
      if (this.dialogReprintDocumentVisible) {
        let obj = await this.$root.getObject({ "objName":this.objName }).catch(err => {console.error(err);});
        this.new_id = this.$root.getNewObjectId();
        let loaded_objs = {};
        loaded_objs[this.objName + this.new_id] = obj;
        this.$store.dispatch('setLoadedObjects', loaded_objs);
        let last_printed = this.$root.getLastPrinted();
        // alert(JSON.stringify(last_printed));
        let document_id = last_printed[this.documentType];
        // alert(document_id);
        // alert(this.documentType);
        this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "DocumentObjName", 'value':this.documentType});
        this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "DocumentId", 'value':document_id});
        this.showModal = true;
      }
    },

}
</script>

<style scoped>
  .modal-reprint-memory >>> .scrollbar-edit{
    height: 30%;
  }
  .reprint-memory {
    font-weight: 500;
    font-size: 13px;
  }
</style>

<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>