<template>
  <div>
    <el-dialog
      :visible.sync="showModal"
      width="90%"
      :before-close="HandleClose"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      class="modal-payment"
    >
      <edit-form
        :objName=objName
        :Id="new_id"
        :is_pos="true"
        :documentType=documentType
        :showTitle="false"
        :canRedirect="false"
        @closeDialog="HandleCloseDialog()"
      />
      <!-- <pos-memory-choice
          v-if="showDialogMesa==true"
          Id="new_id"
          :dialogMesaVisible="showDialogMesa"
          @closed="showDialogMesa=false"
          @memorySelected="HandleActivePosMemorySelected"
      /> -->
    </el-dialog>
  </div>
</template>

<script>
//import { uuid } from 'vue-uuid';
export default {
    props: [
      "dialogPaymentVisible",
      "activePosMemory",
      "documentType",
      "i18n"
    ],

    data() {
      return {
        i18n: null,
        data: [],
        value: ["_rigth_"],
        //temporario, pois deve instalar o uuid
        new_id: "",
        objName: "POSPayment",
        showModal: false
      };
    },

    async created() {
      if (this.dialogPaymentVisible) {
        let obj = await this.$root.getObject({ "objName":this.objName}).catch(err => {console.error(err);}).catch(err => {console.error(err);});
        this.new_id = this.$root.getNewObjectId();
        let loaded_objs = {};
        loaded_objs[this.objName + this.new_id] = obj;
        this.$store.dispatch('setLoadedObjects', loaded_objs);
        if (this.activePosMemory) {
          let consumptionTable = await this.$root.GetConsumptionTableData(this.activePosMemory).catch(err => {console.error(err);});
          let total = 0;
          consumptionTable.forEach(product => {
            total = total + product.TotalValue;
          });
          this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "TotalDebt", 'value':total});
          this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "TempTotalValue", 'value':total});
          this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "TotalDelivered", 'value':total});
          if (this.documentType == "CustomerInvoiceReceipt") {
            this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "CustomerBillOfSale", 'value': false});
            let propVal = {}
            propVal["Hidden"] = "false";
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.CustomerId",'value': propVal}).catch(err => {console.error(err);});
            propVal["Required"] = "true";
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.CustomerId",'value': propVal}).catch(err => {console.error(err);});
            propVal["Hidden"] = "true";
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.CountryId",'value': propVal}).catch(err => {console.error(err);});
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.MustValidate",'value': propVal}).catch(err => {console.error(err);});
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.TaxId",'value': propVal}).catch(err => {console.error(err);});
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.TaxIdChoice",'value': propVal}).catch(err => {console.error(err);});
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.OfficialName",'value': propVal}).catch(err => {console.error(err);});
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.TaxId",'value': propVal}).catch(err => {console.error(err);});
          }
        }
        this.showModal = this.dialogPaymentVisible;
      }
    },

    methods: {
        async HandleSaveTransfer() {
          let confirmed = true;
          await this.$confirm('Criar nota de oferta?', 'Warning', {
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                type: 'warning',
                title: 'Atenção!'
            }).then(() => {
                confirmed = true;
            }).catch(() => {
                confirmed = false;
            }
          );
        },

        async HandleClose(done) {
            this.dialogPaymentVisible=false;
            this.$emit("closed");
            done();
        },

        HandleCloseDialog() {
          this.dialogPaymentVisible=false;
          this.$emit("closed");
        },

        async SetCustomerValue (val) {

        },
        async SetCustomerShowValue (val) {

        },
        async GetCustomerOptions() {
          return await this.$root.getOptions({'objName': 'Customer', 'fieldPath': this.fullFieldName, 'function': val, 'obj': obj, 'DataSource':data_source}).catch(err => {console.error(err);});
        }
    },

}
</script>

<style scoped>
  .modal-payment >>> .scrollbar-edit{
    height: 30%;
  }

</style>

<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>
