<template>
  <div>
    <el-dialog 
        :visible.sync="showBoard" 
        width="60%" 
        :before-close="HandleClose" 
        :close-on-click-modal="false" 
        class="pos-change-delete-board"
        :show-close="false"
        append-to-body
    >   
    <edit-form
        :objName="objName"                  
        :is_pos="true" 
        :id="new_id" 
        :showTitle="false"
        :posMemory="posMemory"
        :rowInEdit="rowInDelete"
        :canRedirect="false"  
        @closeDialog = "closeDialog"         
    />
     <!-- <span> <p class="confirm-text">Deseja editar a quantidade ou remover o produto da conta?</p></span>
    <span slot="footer" class="dialog-footer">
        <el-row :gutter="20">
            
            <el-col  class="dialog-change-input">
                <el-input v-model="editNumber"  > </el-input>
            </el-col>
            
        </el-row>
        <el-row :gutter="20" id="main-content">
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">          
                <el-button 
                    style="width:100%;height:70px; font-size:20px"
                    class="board-button-change"
                    @click="editQuantity"
                    type="success" plain :size="buttonSize?buttonSize:'mediumn'">
                    <i class="fas fa-edit"> {{i18n.Edit}}</i>
                </el-button>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <el-button
                    style="width:100%;height:70px; font-size:20px" 
                    class="keyboard-button-delete" 
                    type="danger" plain :size="buttonSize?buttonSize:'mediumn'"
                    @click="removeLine">
                    <i class="fas fa-trash"> {{i18n.Delete}}</i>
                </el-button>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <el-button 
                    style="width:100%;height:70px; font-size:20px"
                    class="keyboard-button-cancel" 
                    type="info" plain :size="buttonSize?buttonSize:'mediumn'"
                    @click="HandleClose">
                    <i class="fas fa-trash"> {{i18n.Cancel}}</i>
                </el-button>
            </el-col>
        </el-row>
   
       </span> -->
    </el-dialog>
  </div>
</template>

<script>
//import { uuid } from 'vue-uuid'; 
import LongPress from 'vue-directive-long-press'
export default {
    Name: "POSEditChange",

    directives: {
        'long-press': LongPress
    },

    props: ["value", "buttonSize", "backgroundColor", "rowInDelete", "posMemory"],

    data() {
        return {
            showBoard: false,
            i18n: "",
            //temporario, pois deve instalar o uuid
            new_id: "ec315f6f-5aa1-4f55-a6f3-9bc6836b2475",
            objName:"POSEditAccount"    

        };
    },
    
    async created() {
        this.i18n = await this.$root.getLanguage().catch(err => {console.error(err);});
        // this.value = 10;
        let obj = await this.$root.getObject({ "objName":this.objName}).catch(err => {console.error(err);});
        let content = {};
        content[this.objName + this.new_id] = obj;
        this.$store.dispatch('setLoadedObjects', content);
        this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "Quantity", 'value':this.rowInDelete.Quantity});
        this.showBoard = true;

    },

    methods:{
        // async removeLine () {
        //     let confirmed = true;
        //     let actionKey = "Eliminar";
        //     await this.$confirm(this.i18n.confirmMessage.replace('{actionName}', actionKey), 'Warning', {
        //         confirmButtonText: this.i18n.Yes,
        //         cancelButtonText: this.i18n.No,
        //         type: 'warning'
        //     }).then(() => {
        //         confirmed = true;
        //     }).catch(() => {
        //         confirmed = false;
        //     });

        //     if(confirmed) {
        //         this.rowInDelete["MemoryId"] = this.posMemory.Id;
        //         let response = await this.$root.ExecuteActionPOS({'action': "POS.RemoveLineFromTableConsumption", 'args': {'row': this.rowInDelete}});
        //         if (response.messages) {                        
        //                 for (var idx in response.messages) {
        //                     this.$root.ShowMessage({message: response.messages[idx][1], type: response.messages[idx][0]});
        //                 }
        //             }
        //             if (response.run_next) {
        //                 for (var rn in response.run_next) {
        //                     if (response.run_next[rn][0] == 'closeDialog') {
        //                        await this.HandleClose();
        //                     } else if (response.run_next[rn][0] == 'removePartialPosMemoryData') {
        //                         this.$root.removePartialPosMemory(this.rowInDelete);                          
        //                     } else {
        //                         /*console.warning(this.i18n.NotImplementedCommand.replace('{command_name}', response.run_next[rn][0]));*/
        //                     }
        //                 }
        //             }

        //     }
        // },
        async HandleClose(done) {  
            this.showBoard=false;
            this.$emit("closed");
            done();
        },
        async closeDialog() {
          this.showBoard=false;
          this.$emit("closed");
        },

        onLongPressStart () {
            this.value=0;
        },

        onLongPressStop () {
            this.clearNumber();
        },
    },
  
    computed:{
        displayValue: {
            get: function () {
                return this.value;
            },
            set: function(newValue){
                this.value=newValue;
            }
        },
    }
};
</script>

<style scoped>
::v-deep #main-content {
    margin: 15px 0px;
}
.confirm-text {
    font-size: 20px;
}
::v-deep .el-input__inner {
    font-size: 20px;
}

.pos-change-delete-board >>> .scrollbar-edit{
    height: auto;
}
</style>