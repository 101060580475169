import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';
const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
});
Vue.use(Vuex);

const store = new Vuex.Store({
    namespaced: true,
    state: {
        loadedModels: {},
        loadedObjects: {},
        calendarEvents: [],
        menuCollapsed: false,
        isMobile: false,
        isMediunScreen: false,
        activeSidebar: {},
        defaultMap: {},
        loadedDataObjects: {
            allProducts: {},
        },
        activePosMemory: {},
        productionHubContent: [],
        activePosMemoryConsumption: [],
        posMenus: [],
        lastPrinted: {},
        pendentForDirectPrint: {},
        searchPosMenuProducts: {},
        posKitchenAccounts: [],
        listFormNavegationInfo: null,
        listSystemMessage:[]
    },
    getters: {
        events: state => state.calendarEvents,
    },
    mutations: {
        // INITIALIZE_STORE(state) {
        //     if (sessionStorage.getItem('store')) {				
        //         var _store = sessionStorage.getItem('store');
        //         if (typeof sessionStorage.getItem('store') === "string") {
        //             _store = JSON.parse(_store);
        //         }
        //         this.replaceState(
        //             Object.assign(state, _store)
        //         );
        //     }
        // },

        SET_CHILD_FIELD_CONTENT(state, contentInfo) {            
            let content = state['loadedObjects'][contentInfo.objName + contentInfo.Id];
            contentInfo.fullFieldName.split('.').forEach(fn => {
                if (fn) {
                    content = content['Fields'][fn];
                }
            });
            content['Fields'] = typeof contentInfo.content === 'string' ? JSON.parse(contentInfo.content) : contentInfo.content;
            content['FlagNexusChildLoaded'] = true;            
        },

        REMOVE_LIST_FORM_NAVIGATION_INFO(state) {
            state.listFormNavegationInfo = {};
        },

        SET_LIST_FORM_NAVIGATION_INFO(state, contentInfo) {
            state.listFormNavegationInfo = contentInfo;
        },

        REMOVE_LOADED_OBJECTS(state) {
            state.loadedObjects = {};
        },

        REMOVE_LOADED_MODELS(state) {
            state.loadedModels = {};
        },

        REMOVE_LOADED_DATA_OBJECTS(state) {
            state.loadedDataObjects = {};
        },

        REMOVE_ACTIVE_SIDEBAR(state) {
            state.activeSidebar = {};
        },

        REMOVE_LIST_SYSTEM_MESSAGE(state) {
            state.calendarEvents = [];
        },
        REMOVE_CALENDAR_EVENTS(state) {
            state.listSystemMessage = [];
        },

        SET_LIST_SYSTEM_MESSAGE(state, content) {
            let exist_content = state.listSystemMessage;            
            if (exist_content == null || exist_content.length==0) {
                exist_content = [];
                exist_content.unshift(content);
            } else {
                if (exist_content.length <= 10) {
                    exist_content.unshift(content);
                } else {
                    exist_content.splice(-1);
                    exist_content.unshift(content);
                }
            }
            state.listSystemMessage = exist_content;
        },

        // setConnectedToBackend(state, status) {
        //     state.connectedToBackend = status;
        // },

        SET_DEFAULT_MAP(state, content) {
            state.defaultMap = content;
        },

        SET_LOADED_OBJECTS(state, objContent) {
            state.loadedObjects = objContent;
        },

        SET_LOADED_DATA_OBJECTS(state, objDataContent) {
            var content = state.loadedDataObjects;
            if (content) {
                Vue.set(content, objDataContent.objName, objDataContent.content);
            }
        },

        SET_ACTIVE_SIDEBAR(state, sidebar) {
            state.activeSidebar = sidebar;
        },
        
        SET_OBJECT_SAVED(state, propertyInfo) {
            if (propertyInfo.objName && propertyInfo.Id) {                
                var content = state['loadedObjects'][propertyInfo.objName + propertyInfo.Id];
                if (content) {                    
                    Vue.set(content, '__is_saved__', propertyInfo.value);
                }
            }
        },

        REMOVE_PARTIAL_MOVEMENT(state, row) {
            if(row.ProductId) {
                var content = state["activePosMemoryConsumption"].filter(function( obj ) {
                    return obj.ProductId !== row.ProductId;
                });
                state.activePosMemoryConsumption = content;
            }
        },

        CHANGE_PARTIAL_MOVEMENT(state, values) {
            var content = state["activePosMemoryConsumption"];
            for (let obj of content) {
                if(values["ProductId"] == obj["ProductId"]) {
                    obj["Quantity"] = values["Quantity"];
                    obj["TotalValue"] = values["TotalValue"];
                }
            }
        },

        SET_OBJECT_PROPERTY(state, propertyInfo) {
            if (propertyInfo.objName && propertyInfo.Id) {
                var content = state['loadedObjects'][propertyInfo.objName + propertyInfo.Id];
                if (propertyInfo.path) {
                    propertyInfo.path.split('.').forEach(fn => {
                        if (fn) {
                            content = content[fn];
                        }
                    });
                }
                if (content) {
                    if (typeof propertyInfo.value == 'string') {
                        if (Array.isArray(content)) {
                            for (let obj of content) {
                                Vue.set(obj, propertyInfo.value);
                            }
                        } else {
                            Vue.set(content, propertyInfo.value);
                        }
                    } else {
                        if (Array.isArray(content)) {
                            for (let obj of content) {
                                Object.keys(propertyInfo.value).forEach(key => {
                                    Vue.set(obj, key, propertyInfo.value[key]);
                                });
                            }
                        } else {
                            Object.keys(propertyInfo.value).forEach(key => {
                                Vue.set(content, key, propertyInfo.value[key]);
                            });
                        }
                    }
                }
            }
        },

        SET_OBJECT_FIELD_VALUE(state, fieldInfo) {
            var content = state['loadedObjects'][fieldInfo.objName + fieldInfo.Id];
            var field_value = fieldInfo.value;
            // console.log(content);
            fieldInfo.fullFieldName.split('.').forEach(fn => {
                //alert(fn);
                if (fn) {
                    content = content['Fields'][fn];
                }
            });
            if (typeof content['Value'] == 'undefined') {                    
                Vue.set(content, 'Value', field_value);
                return;           
            }
            Vue.set(content, 'Value', field_value);
        },

        SET_OBJECT_FIELD_SHOW_VALUE(state, fieldInfo) {            
            var content = state['loadedObjects'][fieldInfo.objName+fieldInfo.Id];
            fieldInfo.fullFieldName.split('.').forEach(fn => {
                if (fn) {
                    content = content['Fields'][fn];
                }
            });
            if (typeof content['ShowValue'] == 'undefined') {                    
                Vue.set(content, 'ShowValue', fieldInfo.value);
                return;            
            }
            content['ShowValue'] = fieldInfo.value;
        },

        SET_OBJECT_FIELD_VALUES(state, listInfo) {            
            var content = state['loadedObjects'][listInfo.objName+listInfo.Id];
            listInfo.fullFieldName.split('.').forEach(fn => {
                if (fn) {
                    content = content['Fields'][fn];
                }
            });
            if(typeof content['Values'] == 'undefined') {                    
                Vue.set(content, 'Values', listInfo.value);
                return;              
            }
            content['Values'] = listInfo.value;           
        },

        REMOVE_OBJECT(state, str_key_name) {
            delete state.loadedObjects[str_key_name];
        },

        REMOVE_ACTIVE_POS_MEMORY(state) {
            state.activePosMemory = {};
        },

        REMOVE_ACTIVE_POS_MEMORY_CONSUMPTION(state) {
            state.activePosMemoryConsumption = [];
        },

        REMOVE_POS_MENUS(state) {
            state.posMenus = [];
        },

        REMOVE_LAST_PRINTED(state) {
            state.lastPrinted = {};
        },

        REMOVE_PENDENT_FOR_DIRECT_PRINT(state) {
            state.pendentForDirectPrint = {};
        },

        REMOVE_SEARCH_POS_MENU_PRODUCTS(state) {
            state.searchPosMenuProducts = {};
        },

        REMOVE_POS_KITCHEN_ACCOUNTS(state) {
            state.posKitchenAccounts = [];
        },

        COLLAPSE_MENU(state) {
            state.menuCollapsed = true;
        },
        
        TOGGLE_MENU(state) {
            state.menuCollapsed = !state.menuCollapsed;
        },
        
        REMOVE_LOADED_OBJECTS(state) {
            state.loadedObjects = {};
        },

        REMOVE_LOADED_DATA_OBJECTS(state) {
            state.loadedDataObjects = {};
        },

        REMOVE_ACTIVE_SIDEBAR(state) {
            state.activeSidebar = {};
        },

        REMOVE_BREAD_CRUMBS(state) {
            state.breadCrumbs = [];
        },

        RESIZE(state, payload) {
            // state.isMobile = payload.width < 650;
            // state.isMediunScreen = payload.width >= 650 && payload.width < 1000;
            state.isMobile = payload.width < 768;
            state.isMediunScreen = payload.width >= 768 && payload.width < 1024;
        },

        SET_ACTIVE_POS_MEMORY(state, payload) {
            Vue.set(state, 'activePosMemory', payload);
            return;
        },

        SET_BREAD_CRUMBS(state, payload) {
            Vue.set(state, 'breadCrumbs', payload);
            return;
        },

        SET_BREAD_CRUMB(state, content) {
            let bread_crumbs = state.breadCrumbs;            
            if ( bread_crumbs == null || bread_crumbs.length == 0 ) {
                bread_crumbs = [];
                bread_crumbs.push(content);
            } else {
                if ( !bread_crumbs.some(bread_crumb => bread_crumb.path == content.path) ) {
                    if ( bread_crumbs.length <= 5 ) {
                        bread_crumbs.push(content);
                    } else {
                        bread_crumbs.splice(0);
                        bread_crumbs.push(content);
                    }
                }
            }
            state.breadCrumbs = bread_crumbs;
        },

        SET_CALENDAR_EVENTS(state, payload) {
            Vue.set(state, 'calendarEvents', payload);
            return;
        },

        SET_PRODUCTION_HUB_CONTENT(state, payload) {
            if (state["productionHubContent"].length == 0) {
                Vue.set(state, 'productionHubContent', payload);
                return;
            } else {
                let content = state['productionHubContent'];
                payload.forEach(element => {
                    content.push(element);
                });
                Vue.set(state, 'productionHubContent', content);
                return;
            }
        },

        SET_ACTIVE_POS_MEMORY_CONSUMPTION(state, payload) {
            Vue.set(state, 'activePosMemoryConsumption', payload);
            return;
        },

        SET_POS_MENUS(state, payload) {
            Vue.set(state, 'posMenus', payload);
            return;
        },

        SET_LAST_PRINTED(state, payload) {
            Vue.set(state, 'lastPrinted', payload);
            return;
        },

        SET_PENDENT_FOR_DIRECT_PRINT(state, payload) {
            Vue.set(state, 'pendentForDirectPrint', payload);
            return;
        },

        SET_SEARCH_POS_MENU_PRODUCTS(state, payload) {
            Vue.set(state, 'searchPosMenuProducts', payload);
            return;
        },

        SET_POS_KITCHEN_ACCOUNTS(state, payload) {
            let aux = {};
            let memory_ = payload.POSMemoryName + "/" + payload.POSMemorySectionName;
            aux[memory_] = [];
            aux[memory_].unshift({
                "Date" : payload.Date,
                "POSMemoryId" : payload.POSMemoryId,
                "POSMemoryName" : payload.POSMemoryName,
                "POSMemorySectionName" : payload.POSMemorySectionName,
                "ProductName" : payload.ProductName,
                "Quantity" : payload.Quantity,
            });
            if (state['posKitchenAccounts'].length == 0) {
                Vue.set(state, 'posKitchenAccounts', aux);
            } else {
                let content = state['posKitchenAccounts'][memory_]
                if (content == undefined) {
                    let exist_content_ = state['posKitchenAccounts'];
                    aux = Object.assign(exist_content_, aux);
                    Vue.set(state, 'posKitchenAccounts', aux);
                    return;
                } else {
                    var exist_content = state['posKitchenAccounts'][memory_]
                    exist_content.push(
                        {
                            "Date" : payload.Date,
                            "POSMemoryId" : payload.POSMemoryId,
                            "POSMemoryName" : payload.POSMemoryName,
                            "POSMemorySectionName" : payload.POSMemorySectionName,
                            "ProductName" : payload.ProductName,
                            "Quantity" : payload.Quantity,
                        }
                    );
                    if (exist_content.length == 1) {
                        aux[memory_] = exist_content;
                        Vue.set(state, 'posKitchenAccounts', aux);
                        return;
                    } else {
                        let full_content = state['posKitchenAccounts'].slice(memory_);
                        aux = Object.assign(full_content, exist_content);
                        Vue.set(state, 'posKitchenAccounts', aux);
                        return;

                    }
                }

            } 
        },

        SET_LOADED_MODEL(state, payload) {
            let content = state.loadedModels;
            if (payload.name && payload.model) {
                content[payload.name] = payload.model;
            }
        },
    },

    actions: {
        resize({ commit }, payload) {
            // console.error('resize');
            commit('RESIZE', payload);
        },

        toggleMenu({ commit }) {
            // console.error('toggleMenu');
            commit('TOGGLE_MENU');
        },

        collapseMenu({ commit }) {
            // console.error('collapseMenu');
            commit('COLLAPSE_MENU');
        },

        setObjectSaved({ commit }, propertyInfo) {
            // console.error('setObjectSaved');
            commit('SET_OBJECT_SAVED', propertyInfo);
        },

        setLoadedModel({ commit }, payload) {
            // console.error('setLoadedModel');
            commit("SET_LOADED_MODEL", payload);
        },

        setLoadedObjects({ commit }, objContent) {
            // console.error('setLoadedObjects');
            commit('SET_LOADED_OBJECTS', objContent);
        },

        setLoadedDataObjects({ commit }, objDataContent) {
            // console.error('setLoadedDataObjects');
            commit('SET_LOADED_DATA_OBJECTS', objDataContent);
        },

        setActiveSidebar({ commit }, sidebarContent) {
            // console.error('setActiveSidebar');
            commit('SET_ACTIVE_SIDEBAR', sidebarContent);
        },

        // initialiseStore({ commit }) {
        //     commit('INITIALIZE_STORE');
        // },

        changeObjectProperty({ commit }, propertyInfo) {
            // console.error('changeObjectProperty');
            commit('SET_OBJECT_PROPERTY', propertyInfo);
        },

        removePartialPosMemory({ commit }, row) {
            // console.error('removePartialPosMemory');
            commit('REMOVE_PARTIAL_MOVEMENT', row);
        },

        changePOSMovementQuantity({commit}, values) {
            // console.error('changePOSMovementQuantity');
            commit('CHANGE_PARTIAL_MOVEMENT', values);

        },
        changeObjectFieldValue({ commit }, fieldInfo) {
            // console.error('changeObjectFieldValue');
            commit('SET_OBJECT_FIELD_VALUE', fieldInfo);
        },

        changeObjectFieldShowValue({ commit }, fieldInfo) {
            // console.error('changeObjectFieldShowValue');
            commit('SET_OBJECT_FIELD_SHOW_VALUE', fieldInfo);
        },

        changeObjectFieldValues({ commit }, listInfo) {
            // console.error('changeObjectFieldValues');
            commit('SET_OBJECT_FIELD_VALUES', listInfo);
        },

        setChildFieldContent({ commit }, contentInfo) {
            // console.error('setChildFieldContent');
            commit('SET_CHILD_FIELD_CONTENT', contentInfo);
        },

        setListFormNavegationInfo({ commit }, contentInfo) {
            // console.error('setListFormNavegationInfo');
            commit('SET_LIST_FORM_NAVIGATION_INFO', contentInfo);
        },

        removeListFormNavegationInfo({ commit }) {
            // console.error('removeListFormNavegationInfo');
            commit('REMOVE_LIST_FORM_NAVIGATION_INFO');
        },        

        removeLoadedDataObjects({ commit }) {
            // console.error('removeLoadedDataObjects');
            commit('REMOVE_LOADED_DATA_OBJECTS');
        },

        removeLoadedObjects({ commit }) {
            // console.error('removeLoadedObjects');
            commit('REMOVE_LOADED_OBJECTS');
        },

        removeLoadedModels({ commit }) {
            // console.error('removeLoadedModels');
            commit('REMOVE_LOADED_MODELS');
        },

        removeActiveSidebar({ commit }) {
            // console.error('removeActiveSidebar');
            commit('REMOVE_ACTIVE_SIDEBAR');
        },

        removeBreadCrumbs({ commit }) {
            // console.error('removeBreadCrumbs');
            commit('REMOVE_BREAD_CRUMBS');
        },

        removeListSystemMessage({ commit }) {
            // console.error('removeListSystemMessage');
            commit('REMOVE_LIST_SYSTEM_MESSAGE');
        },

        removeCalendarEvents({ commit }) {
            // console.error('removeCalendarEvents');
            commit('REMOVE_CALENDAR_EVENTS');
        },

        setListSystemMessage({ commit }, contentInfo) {
            // console.error('setListSystemMessage');
            // console.error(contentInfo);
            commit('SET_LIST_SYSTEM_MESSAGE', contentInfo);
        },

        removeObject({ commit }, str_key_name) {
            // console.error('removeObject');
            commit('REMOVE_OBJECT', str_key_name);
        },

        removeActivePosMemory({ commit }) {
            // console.error('removeActivePosMemory');
            commit('REMOVE_ACTIVE_POS_MEMORY');
        },

        removeActivePosMemoryConsumption({ commit }) {
            // console.error('removeActivePosMemoryConsumption');
            commit('REMOVE_ACTIVE_POS_MEMORY_CONSUMPTION');
        },

        removePosMenus({ commit }) {
            // console.error('removePosMenus');
            commit('REMOVE_POS_MENUS');
        },

        removeLastPrinted({ commit }) {
            // console.error('removeLastPrinted');
            commit('REMOVE_LAST_PRINTED');
        },

        removePendentForDirectPrint({ commit }) {
            // console.error('RemovePendentForDirectPrint');
            commit('REMOVE_PENDENT_FOR_DIRECT_PRINT');
        },

        removeSearchPosMenuProducts({ commit }) {
            // console.error('removeSearchPosMenuProducts');
            commit('REMOVE_SEARCH_POS_MENU_PRODUCTS');
        },

        removeposKitchenAccounts({ commit }) {
            // console.error('removeposKitchenAccounts');
            commit('REMOVE_POS_KITCHEN_ACCOUNTS');
        },

        changeActivePosMemory({ commit }, content) {
            // console.error('changeActivePosMemory');
            commit('SET_ACTIVE_POS_MEMORY', content);
        },

        changeproductionHubContent({ commit }, content) {
            // console.error('changeproductionHubContent');
            commit('SET_PRODUCTION_HUB_CONTENT', content);
        },

        changeActivePosMemoryConsumption({ commit }, content) {
            // console.error('changeActivePosMemoryConsumption');
            commit('SET_ACTIVE_POS_MEMORY_CONSUMPTION', content);
        },

        setPosMenus({ commit }, content) {
            // console.error('setPosMenus');
            commit('SET_POS_MENUS', content);
        },

        setLastPrinted({ commit }, content) {
            // console.error('setLastPrinted');
            commit('SET_LAST_PRINTED', content);
        },

        setPendentForDirectPrint({ commit }, content) {
            // console.error('setPendentForDirectPrint');
            commit('SET_PENDENT_FOR_DIRECT_PRINT', content);
        },
        

        setSearchPosMenuProducts({ commit }, content) {
            // console.error('setSearchPosMenuProducts');
            // console.error(content);
            commit('SET_SEARCH_POS_MENU_PRODUCTS', content);
        },

        // setBreadCrumbs({commit}, content) {
        //     commit('SET_BREAD_CRUMBS', content);
        // },

        setBreadCrumb({commit}, bread_crumb) {
            // console.error('setBreadCrumb');
            commit('SET_BREAD_CRUMB', bread_crumb);
        },

        // setCalendarEvents({commit}, content) {
        //     commit('SET_CALENDAR_EVENTS', content);
        // },

        // setCalendarEvent({commit}, event) {
        //     commit('SET_CALENDAR_EVENT', event);
        //     //isto não existe, ver depois
        // },

        changePOSKitchenAccounts({ commit }, content) {
            // console.error('changePOSKitchenAccounts');
            commit('SET_POS_KITCHEN_ACCOUNTS', content);
        },
    },

    plugins: [vuexLocal.plugin],

});

export default store;

