<template>
    <div class="sidebar-scrollbar">
        <span v-for="(element, idx) in elementsValues" :key="idx">
            <span v-if="element.Label != null">
                <!-- <el-tooltip :content="element.Label" placement="top"> -->
                    <el-button :type="element.Type" @click="HandleActionCommand(element.Actions)" :icon="element.Icon + ' fa-2x sidebar-button'" class="pos-button-sidebar">
                        <br class="br-sidebar">
                    <span> <p class="sidebar-button-text">{{element.Label}}</p> </span> 
                    </el-button>
                <!-- </el-tooltip> -->
            </span>
        </span>
        <!-- MODAL PARA PERMITIR REIMPRIMIR UM DOCUMENTO CASO TENHA DADO ERRO AO IMPRIMIR-->
        <pos-reprint-document
            v-if="showDialogPosReprintDocument==true" 
            :dialogReprintDocumentVisible="showDialogPosReprintDocument"
            :documentType="documentType"
            :posId="posId"
            :i18n="i18n"
            @closed="showDialogPosReprintDocument=false" />
        <!-- MODAL PARA ESCOLHER MESAS E CARTOES -->
        <pos-memory-choice
            v-if="showDialogMesa==true"
            :dialogMesaVisible="showDialogMesa"
            :splitAccount="splitAccount"
            @closed="showDialogMesa=false"
            @memorySelected="HandleActivePosMemorySelected" />
        <!-- PERMITE AOS FUNCIONARIOS REGISTAR AS HORAS DE ENTRADA E SAIDA-->
        <pos-staff-work-time
            v-if="showDialogPosStaffWorkTime"
            :dialogPosStaffWorkTimeVisible="showDialogPosStaffWorkTime"
            @closed="showDialogPosStaffWorkTime=false" />
        <!-- MODAL PARA TRANSFERIR/DIVIDIR CONSUMOD ENTRE  MESAS E CARTOES -->
        <pos-split-bill
            v-if="showDialogChangeMesa"
            :dialogChangeMesaVisible="showDialogChangeMesa"
            :sourceMemory="activePosMemory"
            :i18n="i18n"
            @closed="showDialogChangeMesa=false"
            @memorySelected="HandleActivePosMemorySelected"
             />
        <pos-payment
            v-if="showDialogPayment"
            :dialogPaymentVisible="showDialogPayment"
            :activePosMemory="activePosMemory"
            :i18n="i18n"
            :documentType="documentType"
            @closed="showDialogPayment=false"
            @memorySelected="HandleActivePosMemorySelected" />
        <!-- MODAL PARA CREDIT -->
        <pos-credit
            v-if="showDialogCredit"
            :dialogCreditVisible="showDialogCredit"
            :activePosMemory="activePosMemory"
            :i18n="i18n"
            @closed="showDialogCredit=false"
            @memorySelected="HandleActivePosMemorySelected" />
        <pos-proforma
            v-if="showDialogProforma"
            :dialogProformaVisible="showDialogProforma"
            :i18n="i18n"
            :activePosMemory="activePosMemory"
            @closed="showDialogProforma=false"
            @memorySelected="HandleActivePosMemorySelected" />
        <pos-offer
            v-if="showDialogOffer"
            :dialogOfferVisible="showDialogOffer"
            @closed="showDialogOffer=false"
            @memorySelected="HandleActivePosMemorySelected" />
        <pos-loss
            v-if="showDialogLoss"
            :dialogLossVisible="showDialogLoss"
            @closed="showDialogLoss=false"
            @memorySelected="HandleActivePosMemorySelected" />
        <pos-consumption
            v-if="showDialogConsumption"
            :dialogConsumptionVisible="showDialogConsumption"
            @closed="showDialogConsumption=false"
            @memorySelected="HandleActivePosMemorySelected" />
        <pos-cash-journal
            v-if="showDialogCashJournal"
            :dialogCashJournalVisible="showDialogCashJournal"
            @closed="showDialogCashJournal=false" />
        <pos-shift
            v-if="showDialogShift"
            :dialogShiftVisible="showDialogShift"
            :i18n="i18n"
            :objName="objName"
            @closed="showDialogShift=false" />
        <pos-summary
            v-if="showDialogSummary"
            :dialogSummaryVisible="showDialogSummary"
            @closed="showDialogSummary=false" />
        <pos-marge
            v-if="showDialogMarge"
            :dialogMargeVisible="showDialogMarge"
            @closed="showDialogMarge=false" />
        <pos-list
            v-if="showDialogList"
            :dialogListVisible="showDialogList"
            :i18n="i18n"
            :objName="objName"
            @closed="showDialogList=false" />
        
        <!--REMOVER TODOS OS DIALOG ACIMA E USAR UM UNICO PARA CHAMAR O EDIT (PASSA O OBJECT NAME E O ID COMO ARGUMENTOS) terminar urgent -->
        <!-- <pos-dialog-edit 
            v-if="showDialogShift" 
            :dialogShiftVisible="showDialogShift"  
            @closed="showDialogShift=false" 
        /> -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: "PosSideBarElement",
        props: {Elements: {type: Object, required: true}},
        data: function () {
            return {
                i18n: "",
                showDialogPosReprintDocument: false,
                showDialogMesa: false,
                showDialogMarge: false,
                showDialogChangeMesa: false,
                showDialogCredit: false,
                showDialogProforma: false,
                showDialogOffer: false,
                showDialogLoss: false,
                showDialogConsumption: false,
                showDialogShift: false,
                showDialogSummary: false,
                showDialogCashJournal: false,
                showDialogPayment: false,
                showDialogPosStaffWorkTime: false,
                showDialogList: false,
                splitAccount: false,
                documentType: "",
                objName: null,
                token: {},
                posId: null,
            };
        },

        computed: {

            elementsValues: {
                get(){
                    return this.Elements;
                }
            },

            ...mapGetters({
               isMobile: 'isMobile',  
               isMediumScreen: "isMediumScreen",
               //menuCollapsed: 'menuCollapsed',   
            }),

            activePosMemory: {
                get: function() {
                    let res = this.$root.getActivePosMemory();
                    return res;
                },
                set: function(new_val) {
                    this.$root.setActivePosMemory(new_val);
                }
            },

            activePosMemoryConsumption: {
                get: function() {
                    return this.$root.getActivePosMemoryConsumption();
                },
                set: function(new_val) {
                    this.$root.setActivePosMemoryConsumption(new_val);
                }
            }
        },

        methods: {

            ...mapActions({
                toggleMenu: 'toggleMenu',
            }),

            async HandleActivePosMemorySelected(new_val) {
                this.activePosMemory = new_val;
                this.activePosMemoryConsumption = await this.$root.GetConsumptionTableData(new_val).catch(err => {console.error(err);});
            },

            async HandleActionCommand(actions) {
                //alert(JSON.stringify(actions));
                let loading = this.$root.getLoading();  
                let confirmed = true;
                //ver depois quais botoes necessitam de confirmacao
                if (actions.Confirm == "true") {                    
                    await this.$confirm(this.i18n.confirmMessage.replace('{actionName}', actions.Name), 'Warning', {
                        confirmButtonText: this.i18n.Yes,
                        cancelButtonText: this.i18n.No,
                        type: 'warning'
                    }).then(() => {
                        confirmed = true;
                    }).catch(() => {
                        confirmed = false;
                    });
                }
                if (confirmed) {
                    if (actions.Execute) {
                        if (actions.Print && actions.Print == "true") {
                            //quando entrar aqui devera chamar o getReportContent que é usado para os DFE
                        } else {
                            let active_pos_memory = "";
                            if (this.activePosMemory) {
                                active_pos_memory = this.activePosMemory;
                            } else {
                                await this.$root.ShowMessage({ message: this.i18n.NoAccountIsSelected, type: "warning" });
                                loading.close();
                                return;
                            }
                            let result = await this.$root.ExecuteActionPOS({'action': actions.Execute, 'args': {'activePosMemory': active_pos_memory,'PosId': this.token.POSId}}).catch(err => {console.error(err);});
                            await this.$root.processCommandResponse(result).catch(err => {console.error(err);});
                        }
                    } else {
                        // fecha o drawer para os dispositivos pequenos
                        // if (this.isMobile || this.isMediumScreen) {
                        document.querySelector(".el-drawer__wrapper").style.display = "none";
                        // }
                        if (actions.Name == 'Account') {
                            this.showDialogMesa = true;
                        } else if (actions.Name == 'StaffWorkTime') {
                            // alert(1);
                            this.showDialogPosStaffWorkTime = true; 
                        } else if (actions.Name == 'ChangeAccount') {
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length === 0) ) {
                                await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            }  else {
                                this.showDialogChangeMesa = true;  
                            }  
                        } else if (actions.Name == 'InvoiceReceipt') {
                            this.documentType = "CustomerInvoiceReceipt";
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length === 0) ) {
                                // alert("1");
                                // abrir dialogo para print um documento anterior avisando que não tem conta selecionada
                                this.showDialogPosReprintDocument = true;
                                // await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            }  else {
                                this.showDialogPayment = true;
                            }                     
                        } else if (actions.Name == 'BillOfSale') {
                            this.documentType = "CustomerBillOfSale";
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length === 0) ) {
                                // abrir dialogo para print um documento anterior avisando que não tem conta selecionada
                                this.showDialogPosReprintDocument = true;
                                // await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            } else {
                                this.showDialogPayment = true;
                            }
                        } else if (actions.Name == 'ProformaInvoice') {
                            this.documentType = "ProformaInvoice";
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length == 0) ) {
                                // abrir dialogo para print um documento anterior avisando que não tem conta selecionada
                                this.showDialogPosReprintDocument = true;
                                // await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            } else {
                                this.showDialogProforma = true; 
                            }
                        } else if (actions.Name == 'CustomerInvoice') {
                            this.documentType = "CustomerInvoice";
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length === 0) ) {
                                // abrir dialogo para print um documento anterior avisando que não tem conta selecionada
                                this.showDialogPosReprintDocument = true;
                                // await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            } else {
                                this.showDialogCredit = true;
                            }
                        } else if (actions.Name == 'Offer') {
                            this.documentType = "OfferNote";
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length === 0) ) {
                                // abrir dialogo para print um documento anterior avisando que não tem conta selecionada
                                this.showDialogPosReprintDocument = true;
                                // await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            } else {
                                this.showDialogOffer = true; 
                            }
                        } else if (actions.Name == 'Consumption') {
                            this.documentType = "ConsumptionNote";
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length === 0) ) {
                                // abrir dialogo para print um documento anterior avisando que não tem conta selecionada
                                this.showDialogPosReprintDocument = true;
                                // await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            } else {
                                this.showDialogConsumption = true;
                            }
                        } else if (actions.Name == 'Loss') {
                            this.documentType = "LossNote";
                            if (!this.activePosMemory || (this.activePosMemory && Object.keys(this.activePosMemory).length === 0) ) {
                                // abrir dialogo para print um documento anterior avisando que não tem conta selecionada
                                this.showDialogPosReprintDocument = true;
                                // await this.$root.ShowMessage({ type: "warning", message: this.i18n.NoAccountSelected });
                            } else {
                                this.showDialogLoss = true; 
                            }
                        } else if (actions.Name == 'PrintShift') {
                            this.objName = "POSShift";
                            this.showDialogShift = true;    
                        } else if (actions.Name == 'PrintSummary') {
                            this.showDialogSummary = true;    
                        } else if (actions.Name == 'CashJournal') {
                            this.showDialogCashJournal = true; 
                        } else if (actions.Name == 'PrintMarge') {
                            this.showDialogMarge = true; 
                        } else if (actions.Name == 'PrintDocuments') {
                            this.objName = "POSDailyDocumentsView";
                            this.showDialogList = true;
                        } else if (actions.Name == 'Logout') {
                            await this.$root.Logout().catch(err => {console.error(err);});                        
                        }
                    } 
                }
                loading.close();
            },
        },

        async created() {
            this.i18n = await this.$root.getLanguage().catch(err => {console.error(err);});
            this.token = await this.$getToken().catch(err => {console.error(err);});
            this.posId = this.token.POSId;
        },
    }
</script>

<style scoped>
.el-button {
  padding: 0px 3px 0px 0px;
  font-weight: 600;
  margin-right: 0px;
}
.el-dialog__body {
  padding: 10px 40px;
  font-size: 14px;
  word-break: break-all;
}
p {
    margin: 4px 0px;
}
</style>
