<template>
    <div>
      <el-dialog 
        :visible.sync="dialogListVisible"
        width="90%"
        :before-close="HandleClose"
        :close-on-click-modal="false"
        :show-close="true"
        append-to-body
        class="modal-loss"
      >
        <list-form
          :objName=objName                  
          :showTitle="false"
          @closeDialog = "HandleCloseDialog()"         
        />
      </el-dialog> 
    </div>
</template>

<script>
export default {
    props: [
      "dialogListVisible",
      "objName"
    ],

    // data() {
    //   return {
    //     objName: "POSDocumentsList",
        
    //   };
    // },

    methods: {
        async HandleClose(done) {     
            this.dialogListVisible=false;
            this.$emit("closed");
            done();
        },
        async closeDialog() {
          this.dialogListVisible=false;
          this.$emit("closed");
        }
    },
    async created(){
      this.new_id = this.$root.getNewObjectId();
    }
}
</script>

<style  scoped>
  .modal-loss >>> .scrollbar-list{
    height: 20%;
  }
</style>

<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>