<template>
  <div class="memory-content">
    <el-dialog
          :visible.sync="showDialog"
          width="80%"
          :before-close="HandleClose"
          :close-on-click-modal="false"
          append-to-body
          class="pos-memory-choice">
        <el-tabs class="tab-pos-memory">
          <el-tab-pane v-for="(tabTypeName, idx) in memory_type_tabs" :key="idx" >
              <span slot="label">
                <el-button class="tab-account" type="primary" style="width:100%; height:100%">{{tabTypeName}}</el-button>
              </span>
              <el-row>
                <el-tabs tab-position="left">
                  <el-tab-pane v-for="(tabSectionName, idx2) in memory_section_tabs[tabTypeName]" :key="idx2">
                    <span slot="label" >
                      <el-button type="info" class="tab-account" style="width:100%; height:100%">{{tabSectionName}}</el-button>
                    </span>
                    <el-col class="margin-red">
                      <el-row class="memory-scrollbar">
                        <pos-memory v-for="(rec, index) in data_options" :key="index"
                          :objContent="rec"
                          :busyAccounts="busyAccounts"
                          :renderCondition="rec.Type==tabTypeName && rec.POSMemorySectionName==tabSectionName"
                          @memorySelected="HandleMemorySelected" />
                        <!-- {{rec}} -->
                      </el-row>
                    </el-col>
                  </el-tab-pane>
                </el-tabs>
              </el-row>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label" >
              <el-button class="tab-account" type="secondary" style="width:100%; height:100%">Cartão</el-button>
            </span>
            <el-input placeholder="Cartão ou código" v-model="card_or_code" autofocus @change="GetCard">
              <el-button slot="append" icon="el-icon-search" @click="GetCard"></el-button>
            </el-input>
            <el-col class="margin-red">
              <el-row class="memory-scrollbar">
                <pos-memory v-for="(rec, index) in data_options" :key="index" 
                  :objContent="rec"
                  :busyAccounts="busyAccounts"
                  :renderCondition="rec.Type=='Cartão'"
                  @memorySelected="HandleMemorySelected" />
              </el-row>
            </el-col> 
          </el-tab-pane>
        </el-tabs>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "POSMemoryChoice",
  props: [
    "dialogMesaVisible", "splitAccount"
  ],

  data: function () {
    return {
      showDialog: false,
      busyAccounts: [],
      card_or_code: ''
    };
  },

  methods: {
    HandleClose(done) {     
      this.dialogMesaVisible=false;
      this.$emit("closed");
      done();
    },

    async HandleMemorySelected(new_val) {
      let old_memory = this.activePosMemory;
      //alert(JSON.stringify(old_memory));
      if (old_memory && old_memory.Name) {
        let old_memory_name = old_memory.Name;
        let pendent_for_direct_print = this.$store.state.pendentForDirectPrint;
        if (old_memory_name in pendent_for_direct_print) {
          let header = `*******************************************
          pedido da memória: ${old_memory}
          *******************************************
          `
          let footer = `
          *******************************************

        





 
          `
          let printers = {};
          let print_servers = {};
          for (let print_obj of pendent_for_direct_print[old_memory_name]) {
            //alert(JSON.stringify(print_obj));
            //alert(JSON.stringify(printers));
            if (!print_obj.printer in printers) {
              printers[print_obj.printer] = header;
              print_servers[print_obj.printer] = print_obj.print_server_url;
            }
            printers[print_obj.printer] = printers[print_obj.printer] + print_obj.text_to_print;
          }
          for (let p in printers) {
            printers[p] = printers[p] + footer
            this.$root.DirectPrint(p, print_servers[p], printers[p], 'text').then (
              async(result) => { 
                if (result == true) {
                  pendent_for_direct_print[old_memory_name] = []
                  this.$store.dispatch('pendentForDirectPrint', pendent_for_direct_print);
                } else {
                  await this.$root.ShowMessage({ message: 'Erro a contactar o servidor de impressão', type: 'warning'});
                }
              });
          }
        }
      }
      if (this.splitAccount) {
        this.activePosMemory = new_val;
        this.activePosMemoryConsumption = await this.$root.GetConsumptionTableData(new_val).catch(err => {console.error(err);});
      }
      this.$emit("memorySelected", new_val);
      this.$emit("closed");
    },

    async loadOptions() {     
        try { 
          // if (!this.data_options) {
          let result = await this.$root.getAllData({
            "name":"POSMemoryView",
            "order_by": "\"POSMemoryView\".\"Type\" and \"POSMemoryView\".\"Position\" "
          }).catch(err => {console.error(err);});      
          if (result.length > 0) {
            this.data_options = typeof result === "string" ? JSON.parse(result) : result;      
          } else {
            this.data_options = [];
          }
          // alert(JSON.stringify(this.data_options));
          loading.close();
          // }
        } catch {
          //alert("Erro em carregar a informação das memórias");
        }  
    },

    async GetCard() {
      // se a memoria existir seleciona-a se não adiciona
      let exists = false
      let selected_mem = null
      if (this.card_or_code) {
        for (let mem of this.data_options) {
          if (mem.Id == this.card_or_code) {
            exists = true;
            selected_mem = mem;
            break;
          }
        }
        if (!exists) {
          selected_mem = {
            "Id": this.card_or_code,
            "Type": "Cartão",
            "Name": this.card_or_code,
            "Position": null,
            "State": 'Ativa',
            "POSMemorySectionId": null,
            "POSMemorySectionName": null,
            "POSMemorySectionDescription": null,
            "PaidAmount": 0,
            "Description": null,
            "AppFileId": null
          };
          this.data_options.push(selected_mem);
          let result = await this.$root.ExecuteActionPOS({'action': "POS.CreateTempPOSMemory", 'args': {'selected_memory':selected_mem}}).catch(err => {console.error(err);});
          if (result[0] != "success") {
            alert("Erro ao registar o cartão");
          }
        }
        this.activePosMemory = selected_mem;
        this.activePosMemoryConsumption = await this.$root.GetConsumptionTableData(selected_mem).catch(err => {console.error(err);});
        this.$emit("memorySelected", selected_mem);
        this.$emit("closed");
      }
    },
  },

  computed:{
    
    data_options:{
      get() {
        return this.$root.getLocalDataObject('POSAccounts');
      },
      set(new_val) {
        this.$root.setLocalDataObject({objName: 'POSAccounts', content: new_val});
      }
    },

    activePosMemory:{
        get: function() {
            let res = this.$root.getActivePosMemory();
            return res;
        },
        set: function(new_val) {
            this.$root.setActivePosMemory(new_val);
        }
    },

    activePosMemoryConsumption:{
        get: function() {
            return this.$root.getActivePosMemoryConsumption();
        },
        set: function(new_val) {
            this.$root.setActivePosMemoryConsumption(new_val);
        }
    },

    memory_type_tabs:{
      get() {
        let res = [];
        for (var value of this.data_options) {
          if (!res.includes(value.Type) && value.Type != 'Cartão') {
            res.push(value.Type);
          }
        }
        return res;
      }
    },

    memory_section_tabs:{
      get() {
        let res = {};
        for (var type of this.memory_type_tabs) { 
          res[type]=[];
          for (var value of this.data_options) {
            if (type == value.Type && !res[type].includes(value.POSMemorySectionName)) {
              res[type].push(value.POSMemorySectionName);
            }
          }
        }
        return res;
      }
    }
  },

  async created() { 
    if (this.dialogMesaVisible) {
      await this.loadOptions().catch(err => {console.error(err);});
      // alert('localStorage: ' + JSON.stringify(localStorage).length);
      // alert('sessionStorage: ' + JSON.stringify(sessionStorage).length);
      let result = await this.$root.ExecuteActionPOS({'action': "POS.CheckAccountMovement", 'args': {}}).catch(err => {console.error(err);});
      if (result[0] == "success") {
        this.busyAccounts = result[1];
      }
      this.showDialog = this.dialogMesaVisible;
    }
      
  }, 

  watch: {
    dialogMesaVisible: async function (val) {
      if (val) {
        this.loadOptions();
      } else {
        this.data_options = [];
        this.totalRecord = 0;
        //this.$root.removeObject(this.relation + this.new_id);
      }      
    },
  },
};
</script>

<style scoped>
.tab-account {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.el-input--suffix >>> .el-input__inner {
  padding-right: 55px;
}
 
.pos-memory-choice >>> .el-dialog__body{
  height: calc(100vh - 200px);
  overflow: hidden;
}
.pos-memory-choice >>> .el-tabs__content{
  height: calc(100vh - 279px);
  overflow-y: auto;
}
</style>
<style>
.el-tabs__item.is-top {
  margin-right: 0px;
}
.el-tabs__item {
    padding: 0 5px;
    height: 50px;
    line-height: 40px;
    font-size: 14px;
}
.el-tabs__nav-wrap.is-top {
  margin-bottom: 0px;
}
.el-tabs--left .el-tabs__active-bar.is-left {
    width: 0px;
}
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding: 0 3px 0px 3px;
}
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:last-child {
  padding: 0 3px 0px 3px;
}
.el-tabs--left .el-tabs__header.is-left {
    margin-right: 0px;
}
.el-tabs__header.is-top {
  padding-top: 5px;
  margin: 0px 0 10px 10px;
}
 .el-tabs__nav-wrap.is-top{
 padding-bottom: 5px;
}
.el-tabs__item.is-left {
    padding: 0 3px;
    height: 50px;
    line-height: 40px;
    font-size: 14px;
    margin: 5px;
}
.el-tabs__nav-prev {
  font-size: 20px;
}
.el-tabs__nav-next {
  font-size: 20px;
}
</style>

<style lang="scss" scoped>
  ::v-deep .el-tabs__nav.is-left {
    height: calc(100vh - 280px);
  }
  @import '@/assets/styles/scss/color-themes.scss';
  ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
      background-color: $modal__background__color;
  }
  ::v-deep .el-dialog__headerbtn {
      font-size: 25px;
      font-weight: bold;
  }
  ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
    background-color: $modal__input__background__color;
    border-color: $modal__input__border__color;
  }
</style>
