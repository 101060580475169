<template>
    <el-avatar style="background-color:#C0C4CC" fit="scale-down" :class="'col ' + active " shape="square" :size="size" :src="url">
    </el-avatar>
</template>
<script>

export default {
    props: ["fileId", "type", "size", "active"],

    data () {
        return {
            url:'',
        }
    },
    
    // async updated() {
    //     alert('update');
    //     this.url = await this.getLink();
    // },

    async created() {
        // alert("create");
        this.url = await this.getLink().catch(err => {console.error(err);});        
    },

    methods: {
        async getLink() {            
            if (this.fileId) {
                let blob = await this.$root.getUploadedFileById(this.fileId).catch(err => {console.error(err);}); 
                if (blob) {
                    return window.URL.createObjectURL(blob); 
                }
            } else if (this.type == "Cartão") {
                return require('@/assets/images/pos/cartao.png');
            } else if(this.type == "Mesa") {
                return require('@/assets/images/pos/mesa.png');
            } else if (this.type == "user") {
                return require('@/assets/images/user-avatar.png');
            } else if (this.type == "account") {
                return require('@/assets/images/pos/mesa.png');
            } else {
                return null;
            }
        },
    },
};
</script>

<style scoped>
.active {
    opacity: 0.8;
    outline: green 3px solid;
}
.inactive {
    opacity: 0.7;
    outline: #F39C12 3px solid;
}
.col:hover {
    outline:brown 2px solid;
}
</style>