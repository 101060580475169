<template>
    <div>
        <el-dialog 
            :visible.sync="dialogOfferVisible"
            width="90%"
            :before-close="HandleClose"
            :close-on-click-modal="false"
            :show-close="false"
            append-to-body
            class="modal-offer"
        >
          <edit-form
            objName="POSOffer"
            Id="new_id"
            :is_pos="true"
            :showTitle="false"
            :canRedirect="false"
            @closeDialog = "closeDialog"
          />
            <el-row>
              <el-col :span="24">
                <p class="offer-memory">
                  # {{activePosMemory.Name}} / {{activePosMemory["POSMemorySectionId.Name"]}} #
                </p>
              </el-col>
            </el-row>  
        </el-dialog>
         <!-- MODAL PARA ESCOLHER MESAS/CARTOES de destino -->
        <pos-memory-choice
          v-if="showDialogMesa"
          :dialogMesaVisible="showDialogMesa"
          @closed="HandlePosMemoryChoiceClose"
          @memorySelected="HandleTargetMemorySelected"
        />        
    </div>
</template>

<script>

export default {
    props: [
      "dialogOfferVisible"
    ],

    data() {
      return {
        data: [],
        value: ["_rigth_"],
        showDialogMesa: false,
      };
    },

    methods: {
        async HandleSaveTransfer() {
          let confirmed = true;
          await this.$confirm('Criar nota de oferta?', 'Warning', {
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            type: 'warning',
            title: 'Atenção!'
            }).then(() => {
                confirmed = true;
            }).catch(() => {
                confirmed = false;
            }
          );
        },

        HandleTargetMemorySelected(new_val) {
          this.activePosMemory = new_val;                     
        },

        async HandleClose(done) {
          alert(11);
          this.dialogOfferVisible=false;
          this.$emit("closed");
          done();
        },

        HandleReasonValueChange(value) {
          this.formData.creditReason=value;
        },

        HandlePosMemoryChoiceClose() {
          this.showDialogMesa=false;
          if (Object.keys(this.activePosMemory).length == 0) {
            this.dialogOfferVisible=false;
            this.$emit("closed");
          }
        },
        async closeDialog() {
          this.dialogCashJournalVisible=false;
          this.$emit("closed");
        }
    },

    mounted() {
      if (!this.activePosMemory || Object.keys(this.activePosMemory).length == 0) {
        this.showDialogMesa=true;
      }
    },

    computed: {
      activePosMemory: {
          get: function() {
            let res = this.$root.getActivePosMemory();
            return res;
          },

          set: function(new_val) {
            this.$root.setActivePosMemory(new_val);
          }
      },
    }
}
</script>

<style scoped>
  .modal-offer >>> .scrollbar-edit{
    height: 30%;
  }
  .offer-memory {
    font-weight: 500;
    font-size: 13px;
  }
</style>

<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>