<template>
  <div class="product-content">
    <span>
      <el-tabs v-model="activeName">
        <el-tab-pane v-for="menu of main_menu" :name="menu.MenuName" :key="menu.MenuId">
          <span slot="label" >
            <el-button class="tab-categoria" @click="getNext(menu.MenuId, p1)" :type="menu.MenuType.toLowerCase()" style="width:100%; height:94%">{{menu.MenuName}}</el-button>
          </span>
        </el-tab-pane>
      </el-tabs>
      <span v-if="Object.hasOwn(otherContent1, 'menus')">
        <el-tabs :tab-position="ltr">
          <el-tab-pane v-for="menu1 of otherContent1['menus']" :key="menu1.MenuId" >
            <span v-if="menu1.MenuName" slot="label" >
              <el-button @click="getNext(menu1.MenuId, p2)" class="tab-categoria" :type="menu1.MenuType.toLowerCase()" style="width:100%; height:94%">{{menu1.MenuName}}</el-button>
            </span>
          </el-tab-pane>
        </el-tabs>
      </span>
      <span v-else-if="Object.hasOwn(otherContent1, 'products')">
        <el-row>
          <el-col id="container" class="product_scrollbar">
            <pos-product v-for="prod1 in otherContent1['products']" :key="prod1.ProductId"
              :objContent="prod1"
              :renderCondition="(prod1.ProductId)"
              @productSelected="HandleProductSelected" />
          </el-col>
        </el-row>
      </span>
      <!-- POSIÇÃO 2 - VER SE TEM TAB OU PRODUTO -->
      <span v-if="Object.hasOwn(otherContent2, 'menus')">
        <el-tabs :tab-position="top">
          <el-tab-pane v-for="menu2 of otherContent2['menus']" :key="menu2.MenuId" >
            <span v-if="menu2.MenuName" slot="label" >
              <el-button @click="getNext(menu2.MenuId, p3)" class="tab-categoria" :type="menu2.MenuType.toLowerCase()" style="width:100%; height:94%">{{menu2.MenuName}}</el-button>
            </span>
          </el-tab-pane>
        </el-tabs>
      </span>
      <span v-else-if="Object.hasOwn(otherContent2, 'products')">
        <el-row>
          <el-col id="container" class="product_scrollbar">
            <pos-product v-for="prod2 in otherContent2['products']" :key="prod2.ProductId"
              :objContent="prod2"
              :renderCondition="(prod2.ProductId)"
              @productSelected="HandleProductSelected" />
          </el-col>
        </el-row>
      </span>
      <!-- POSIÇÃO 3 - VER SE TEM TAB OU PRODUTO -->
      <span v-if="Object.hasOwn(otherContent3, 'menus')">
        <el-tabs  :tab-position="top">
          <el-tab-pane v-for="menu3 of otherContent3['menus']" :key="menu3.MenuId" >
            <span v-if="menu3.MenuName" slot="label" >
              <el-button @click="getNext(menu3.MenuId, p4)"  class="tab-categoria" :type="menu3.MenuType.toLowerCase()" style="width:100%; height:94%">{{menu3.MenuName}}</el-button>
            </span>
          </el-tab-pane>
        </el-tabs>
      </span>
      <span v-else-if="Object.hasOwn(otherContent3, 'products')">
        <el-row>
          <el-col id="container" class="product_scrollbar">
            <pos-product  v-for="prod3 in otherContent3['products']" :key="prod3.ProductId"
              :objContent="prod3"
              :renderCondition="(prod3.ProductId)"
              @productSelected="HandleProductSelected"
            />
          </el-col>
        </el-row>
      </span>
      <!-- POSIÇÃO 4 - VER SE TEM TAB OU PRODUTO -->
      <span v-if="Object.hasOwn(otherContent4, 'menus')">
        <el-tabs :tab-position="top">
          <el-tab-pane v-for="menu4 of otherContent4['menus']" :key="menu4.MenuId" >
            <span v-if="menu4.MenuName" slot="label" >
              <el-button @click="getNext(menu4.MenuId, p5)" class="tab-categoria" :type="menu4.MenuType.toLowerCase()" style="width:100%; height:94%">{{menu4.MenuName}}</el-button>
            </span>
          </el-tab-pane>
        </el-tabs>
      </span>
      <span v-else-if="Object.hasOwn(otherContent4, 'products')">
        <el-row>
          <el-col id="container" class="product_scrollbar">
            <pos-product v-for="prod4 in otherContent4['products']" :key="prod4.ProductId"
              :objContent="prod4"
              :renderCondition="(prod4.ProductId)"
              @productSelected="HandleProductSelected" />
          </el-col>
        </el-row>
      </span>
      <!-- POSIÇÃO 5 - LEVO EM CONTA DOMENTE 4 NIVEIS - DEPOIS QUANDO ESTIVER RECURSIVO TERÁ n NIVEIS -->
      <span v-if="Object.hasOwn(otherContent5, 'products')">
        <el-row>
          <el-col id="container" class="product_scrollbar">
            <pos-product  v-for="prod5 in otherContent5['products']" :key="prod5.ProductId"
              :objContent="prod5"
              :renderCondition="(prod5.ProductId)"
              @productSelected="HandleProductSelected" />
          </el-col>
        </el-row>
      </span>
    </span>
  </div>
</template>

<script>
  import { mapGetters} from "vuex";

  export default {
    name: "POSProductChoice",

    data: function () {
      return {
        activeName: '',
        main_menu: [],
        p1:1,
        p2:2,
        p3:3,
        p4:4,
        p4:5,
        otherContent1: {},
        otherContent2: {},
        otherContent3: {},
        otherContent4: {},
        otherContent5: {}
      };
    },

    methods: {

      handleTab(tab_name) {
        // alert(tab_name);
        this.activeName = tab_name;
      },

      HandleProductSelected(selectedInfo){
        this.$emit("productSelected", selectedInfo);
      },

      async loadOptions() {
        if (this.main_menu === undefined ||  this.main_menu.length == 0) {
          let loading = this.$root.getLoading(); 
          this.main_menu = await this.$root.getPosMenus().catch(err => {console.error(err);});
          loading.close();
        }
      },

      async getNext(parent_id, position) {
        let loading = this.$root.getLoading();
        if (parent_id == "search_menu") {
          let search_products_list = this.$root.getSearchPosMenuProducts();
          //console.log(search_products_list);
          this.otherContent1 = search_products_list;
          this.otherContent2 = {};
          this.otherContent3 = {};
          this.otherContent4 = {};
          this.otherContent5 = {};
        } else {
          try {
            let result = await this.$root.ExecuteActionPOS({'action': 'POS.getPOSProductsOrMenu', 'args': {'Id':parent_id}}).catch(err => {console.error(err);});
            // console.log("result");
            //console.log(JSON.stringify(result));
            // console.log("position");
            //console.log(position);
            if (result[0] == "success") {
              if (position == 1) {
                this.otherContent1 = result[1];
                this.otherContent2 = {};
                this.otherContent3 = {};
                this.otherContent4 = {};
                this.otherContent5 = {};
              } else if(position == 2) {
                this.otherContent2 = result[1];
                this.otherContent3 = {};
                this.otherContent4 = {};
                this.otherContent5 = {};
              } else if(position == 3) {
                this.otherContent3 = result[1];
                this.otherContent4 = {};
                this.otherContent5 = {};
              } else if(position == 4) {
                this.otherContent4 = result[1];
                this.otherContent5 = {};
              } else if(position == 5) {
                this.otherContent5 = result[1];
              }
            } else {
              if (position == 1) {
                this.otherContent1 = {};
                this.otherContent2 = {};
                this.otherContent3 = {};
                this.otherContent4 = {};
                this.otherContent5 = {};
              } else if(position == 2) {
                this.otherContent2 = {};
                this.otherContent3 = {};
                this.otherContent4 = {};
                this.otherContent5 = {};
              } else if(position == 3) {
                this.otherContent3 = {};
                this.otherContent4 = {};
                this.otherContent5 = {};
              } else if(position == 4) {
                this.otherContent4 = {};
                this.otherContent5 = {};
              } else if(position == 5) {
                this.otherContent5 = {};
              }
            }
          } catch {
            loading.close();
          }
        }
        loading.close();
      }
    },

    computed:{
      ...mapGetters({
        collapsed: "menuCollapsed",
        isMobile: "isMobile",
        isMediumScreen: "isMediumScreen",
      }),
    },

    async created() {
      await this.loadOptions().catch(err => {console.error(err);});
    },

  }
</script>

<style>
  .el-input--suffix >>> .el-input__inner {
    padding-right: 55px;
  }
  .el-tabs__item.is-top {
    margin-right: 0px;
  }
  .el-tabs__item {
    padding: 0 5px;
    height: 50px;
    line-height: 40px;
    font-size: 14px;
  }
  .el-tabs__nav-wrap.is-top {
    margin-bottom: 0px;
  }
  .el-tabs--left .el-tabs__active-bar.is-left {
    width: 0px;
  }
  .el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2), .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    padding: 0 3px 0px 3px;
  }
  .el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:last-child {
    padding: 0 3px 0px 3px;
  }
  .el-tabs--left .el-tabs__header.is-left {
    margin-right: 0px;
  }
  .el-tabs__header.is-top {
    padding-top: 0px;
    margin: 0px 0 0px 10px;
  }
  .el-tabs__nav-wrap.is-top{
  padding-bottom: 4px;
  }
  .el-tabs__item.is-left {
    padding: 0 3px;
    height: 50px;
    line-height: 40px;
    font-size: 14px;
    margin: 5px;
  }
  .el-tabs__nav.is-left {
    overflow-y: auto;
    height: calc(100vh - 160px);
    margin-right: 0px;
    border-right: none;
  }
  .el-tabs__nav-prev {
    font-size: 20px;
  }
  .el-tabs__nav-next {
    font-size: 20px;
  }
</style>

<style scoped>
  ::v-deep .el-icon-arrow-up, ::v-deep .el-icon-arrow-down {
    width: 20px;
    font-size: 30px;
    font-weight: bold;
    color: white;
  }
  ::v-deep .button-scroll {
    margin-right: 20px;
    margin-left: 10px;
  }
  ::v-deep .scroll-down, .scroll-up {
    padding: 15px 0;
    width: 100%;
  }
  .tab-categoria {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .product_scrollbar {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 190px);
    /* width: 97%; */
  }
  .product-content{
    height: calc(100vh - 79px);
    margin-right: 0px;
  }
  ::v-deep .el-tabs__item {
      height: 50px;
  }
  .el-tabs__nav-scroll {
    height: 150px;
  }
  /* #container {
    overflow-y: scroll;
    margin-right: 50px;
  } */
</style>

