<template>
    <div>
        <el-dialog 
            :visible.sync="dialogChangeMesaVisible"
            width="100%"
            :before-close="HandleClose"
            :close-on-click-modal="false"
            :show-close="false"
            append-to-body
            @closeDialog = "HandleCloseDialog()" >
        <!-- > @memorySelected = "HandleCloseDialog()" -->
          <div >
            <el-row style="text-align: right; padding-right:10px;">
              <el-button v-if="value.length > 1 && targetMemory.Id != null"                                   
                  size="large" 
                  type="success"
                  plain
                  class="btn-pos-save-account"
                  icon="fas fa-save"
                  @click="HandleSaveTransfer"
                  > Transferir
              </el-button>
              <el-button                                    
                  size="large" 
                  type="primary"
                  class="btn-pos-choice-account"
                  plain
                  icon="fas fa-cogs"
                  @click.prevent="renderDialogMesa()"
                  > Conta de Destino
              </el-button>
              <el-button                                    
                  size="large" 
                  type="danger"
                  class="btn-pos-cancel-account"
                  plain
                  icon="fas fa-times"
                  @click.prevent="HandleClose"
                  > Cancelar
              </el-button>
            </el-row>
            <br/>
            <el-row class="nexus-transfer">
              <el-transfer
                  style="text-align: left; display: inline-block"
                  :data="consumptionTableData"
                  :props="{key:'productId', label:'productId_show', disabled: 'isDisabled'}"
                  v-model="value"                             
                  :render-content="renderContent"
                  :filter-placeholder="i18n.Search"
                  :titles="getTransferBoxTitle()" 
                  :format="{
                      noChecked: '${total}',
                      hasChecked: '${checked}/${total}'
                  }"
                  @change="HandleChange"                      
              />
            </el-row>
          </div>
        </el-dialog>
        <pos-memory-choice 
          v-if="showDialogMesa" 
          :dialogMesaVisible="showDialogMesa" 
          :splitAccount="splitAccount" 
          @closed="showDialogMesa=false" 
          @memorySelected="HandleTargetMemorySelected" 
        />
    </div>
</template>

<script>
  export default {
    props: [
      "dialogChangeMesaVisible", 
      "sourceMemory",
      "i18n"
    ],

    data() {
      return {
        data: [],
        value: ["_rigth_"],
        targetMemory: {},
        showDialogMesa: false,
        splitAccount: false,
        consumptionTableData:[],
        ProductsIdToMove: [],
        initialSourceMemory: this.sourceMemory
      };
    },

    methods: {

      async HandleSaveTransfer() {
        let confirmed = true;
        await this.$confirm('Deseja efetuar transfência entre contas ?', 'Atenção', {
          confirmButtonText: this.i18n.Yes,
          cancelButtonText: this.i18n.No,
          type: 'warning'
        }).then(() => {
          confirmed = true;
        }).catch(() => {
          confirmed = false;
        });
        if (confirmed) {
          let args = {};
          args["sourceMemoryId"] = this.initialSourceMemory.Id;
          args["targetMemoryId"] = this.targetMemory.Id;
          this.ProductsIdToMove.shift();
          args["productsIdToMove"] = this.ProductsIdToMove;
          let response = await this.$root.ExecuteActionPOS({'action': "POS.SplitAccount", 'args': args}).catch(err => {console.error(err);});
          if (response) {
            if (response.messages) {                        
              for (var idx in response.messages) {
                await this.$root.ShowMessage({message: response.messages[idx][1], type: response.messages[idx][0]});
              }
            }
            if (response.run_next) {
              for (var rn in response.run_next) {
                if (response.run_next[rn][0] == 'closeDialog') {
                  this.$emit("closed");
                }
                else if (response.run_next[rn][0] == 'removePosMemoryData') {
                  this.$root.removeActivePosMemory();
                }
                else if (response.run_next[rn][0] == 'changeSelectedMemory') {
                  if (response.obj.targetMemory) {
                    this.activePosMemory = this.targetMemory;
                    this.activePosMemoryConsumption =  await this.$root.GetConsumptionTableData(this.activePosMemory).catch(err => {console.error(err);});
                    this.$emit("memorySelected", this.targetMemory);
                  }
                }
              }
            }
          }
        }
      },

      renderDialogMesa() {
        this.splitAccount = true
        this.showDialogMesa = true;
      },

      renderContent(h, option) {
        // alert(JSON.stringify(h));
        return  <el-row gutter={10} style="width: 100%;">
                  <el-col lg={4}  md={4}  sm={4} xs={4} class="transfer-quantity" style="text-align: left;">{ option.quantity }</el-col>
                  <el-col lg={10} md={10} sm={10} xs={10} class="transfer-product" style="text-align: left;">{ option.productId_show }</el-col>
                </el-row>
                ;
      },

      HandleChange(value, direction, movedKeys) {
        // alert(JSON.stringify(this.consumptionTableData));
        // alert(JSON.stringify(value));
        // alert(direction);
        // alert(JSON.stringify(movedKeys));
        this.ProductsIdToMove = value;
      },

      getTransferBoxTitle() {
        let res = [];
        if (this.initialSourceMemory != null && this.initialSourceMemory.Id) {
          res.push(this.i18n.SourceAccount + ' - [' + this.initialSourceMemory.Name + ' / ' + this.initialSourceMemory.POSMemorySectionName + ']');
        } else {
          res.push(this.i18n.SourceAccountUnknown);
        }
        if (this.targetMemory != null && this.targetMemory.Id) {
          res.push(this.i18n.DestinyAccount + ' - [' + this.targetMemory.Name + ' / ' + this.targetMemory.POSMemorySectionName + ']');
        } else {
          res.push(this.i18n.DestinyAccountUnknown);
        }
        //alert(JSON.stringify(res));
        return res;
      },

      async HandleTargetMemorySelected(new_val) {
        // alert(2);
        // alert(JSON.stringify(this.initialSourceMemory));
        //alert(JSON.stringify(this.sourceMemory));
        if (new_val.Id == this.initialSourceMemory.Id) {
          await this.$root.ShowMessage({type:'error', message:'Não pode mover produtos/serviços para a mesma conta!'});
        } else {
          this.targetMemory = new_val;
        }            
      },

      async HandleClose(done) {     
          this.dialogChangeMesaVisible = false;
          this.$emit("closed");
          done();
      },

      HandleCloseDialog() {
        this.dialogChangeMesaVisible = false;
        this.$emit("closed");
      }
    },

    async mounted() {
      let args = {};
      args["PosMemory"] = this.initialSourceMemory;
      let products = await this.$root.ExecuteActionPOS({'action': "POS.CallPosMemoryMovement", 'args': args}).catch(err => {console.error(err);});
      let aux = [];
      products.forEach(product => {
        for (const x of Array(parseInt(product.Quantity)).keys()) {
          aux.push({
            "quantity": 1,
            "productId": product.ProductId + '&' + x,
            "productId_show": product["ProductId.Name"],
            "total": product.TotalValue
          })
        }
      });
      this.consumptionTableData = aux;
    },

    // computed: {
    //   activePosMemory: {
    //     get: function() {
    //         let res = this.$root.getActivePosMemory();
    //         return res;
    //     },
    //     set: function(new_val) {
    //         this.$root.setActivePosMemory(new_val);
    //     }
    //   },

    //   activePosMemoryConsumption: {
    //     get: function() {
    //       return this.$root.getActivePosMemoryConsumption();
    //     },
    //     set: function(new_val) {
    //       this.$root.setActivePosMemoryConsumption(new_val);
    //     }
    //   },
    // },

    // watch: {
    //   activePosMemory: async function() {
    //     this.activePosMemoryConsumption =  await this.$root.GetConsumptionTableData(new_value);
    //   }
    // }
  }
</script>

<style>
 .btn-pos-choice-account, .btn-pos-save-account, .btn-pos-cancel-account {
    font-size: 18px;
  }
</style>

<style  scoped>
  ::v-deep .transfer-quantity {
    margin-left: 20px;
    margin-top: 7px;
    font-size: 20px;
  }
  ::v-deep .transfer-product {
    margin-left: -10px;
    margin-top: 7px;
    font-size: 20px;
    word-break: break-all;
  }
  ::v-deep .transfer-total {
    margin-left: 20px;
    margin-top: 7px;
  }
  .nexus-transfer{   
    text-align: center;
    width: 100%; 

    display:grid;
  }
  ::v-deep .el-checkbox__input {
    margin-right: 20px;
  }
  .nexus-transfer >>> .el-transfer-panel {
      width: 40%;  
      font-size: 30px;
  }
  ::v-deep .el-transfer-panel .el-checkbox__inner {
    height: 35px;
    width: 35px;
  }
  ::v-deep .el-transfer-panel__item {
    height: 60px;
  }
  ::v-deep .el-checkbox__label {
    font-size: 20px;
  }
  ::v-deep .el-transfer-panel .el-checkbox .el-checkbox__label {
    font-size: 20px;
    font-weight: 500;
  }
 









</style>
<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }

</style>