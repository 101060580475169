<template>
  <div>
    <el-dialog 
        :visible.sync="showkeyBoard" 
        width="25%" 
        :before-close="HandleClose" 
        :close-on-click-modal="false" 
        class="pos-numeric-keyboard"
        append-to-body
    >      
        <el-input
            ref="inputNumber"
            readonly
            style="font-size: 40px"
            :min="min ? min : Number.MIN_SAFE_INTEGER"
            :max="max ? max : Number.MAX_SAFE_INTEGER"
            v-model.number="displayValue" 
            @keyup.enter.native="approveValue" 
            @keyup.native="HandleKeyPress"

        ></el-input>
        <div class="btn_container blocks">
            <el-row  type="flex" justify="space-around">  
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">          
                    <el-button @click="setDigit(7)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'" >7</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
                    <el-button @click="setDigit(8)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">8</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
                    <el-button @click="setDigit(9)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">9</el-button>
                </el-col>
            </el-row>
            <el-row type="flex" justify="space-around">            
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">          
                    <el-button @click="setDigit(4)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">4</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
                    <el-button @click="setDigit(5)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">5</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
                    <el-button @click="setDigit(6)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">6</el-button>
                </el-col>
            </el-row>
            <el-row type="flex" justify="space-around">            
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">          
                    <el-button @click="setDigit(1)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">1</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
                    <el-button @click="setDigit(2)" class="keyboard-button"  type="info" :size="buttonSize?buttonSize:'mediumn'">2</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
                    <el-button @click="setDigit(3)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">3</el-button>
                </el-col>
            </el-row>
            <el-row type="flex" justify="space-around">            
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">          
                    <el-button @click="setDigit(0)" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">0</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">          
                    <el-button @click="setDigit('-')" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">-</el-button>
                </el-col>
                <el-col :xs="7" :sm="7" :md="7" :lg="7" :xl="7">
                    <el-button @click="setDigit('.')" class="keyboard-button" type="info" :size="buttonSize?buttonSize:'mediumn'">.</el-button>
                </el-col>
            </el-row>
            <el-row type="flex" justify="space-around">            
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">          
                    <el-button 
                    class="keyboard-button-cancel"
                        v-long-press="300"
                        @long-press-start="onLongPressStart"
                        @long-press-stop="onLongPressStop"
                        type="danger" plain :size="buttonSize?buttonSize:'mediumn'">
                        <i class="fas fa-times"></i>
                    </el-button>
                </el-col>
                
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" :offset="6">
                    <el-button class="keyboard-button-check" type="success" plain :size="buttonSize?buttonSize:'mediumn'" @click="approveValue">
                        <i class="fas fa-check"></i>
                    </el-button>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import LongPress from 'vue-directive-long-press'
export default {
    Name: "POSKeyBoardNumber",

    directives: {
        'long-press': LongPress
    },

    props: ["value", "buttonSize", "backgroundColor", "stateField", "fieldRulePath"],

    data() {
        return {
            showkeyBoard: false,
        };
    },
    updated(){
        this.$refs.inputNumber.focus();
    },

    methods:{
        async HandleClose(done) {     
            this.showkeyBoard=false;
            this.$emit("closed");
            done();
        },

        HandleKeyPress: function(event) {            
            if(["0","1","2","3","4","5","6","7","8","9",".", "-"].includes(event.key)){
                this.setDigit(event.key);
            }else if(event.code == "Backspace"){
                this.clearNumber();
            }
        },

        setDigit(val){ 
            if(val == "-"){
                if(this.value.toString().indexOf("-")>=0){
                    return;
                }else if(this.value.toString()=="0"){
                    this.value = val;
                    return;
                }else if(this.value.toString().length>0){
                    return;
                }
            }else if(val == "."){
                if(this.value.toString().indexOf(".")>=0){
                    return;
                }else if(!this.value || this.value.toString().length==0){
                    this.value =0;
                }
            }else if(val==0){
                if(!this.value && this.value.toString().indexOf(".")<0){
                    this.value =0;
                    return;
                }
            }else if(this.value == "0"){
                this.value =val;
                return;
            } 
            this.value = this.value.toString()+val;
        },

        clearNumber(){
            this.value = this.value.toString().substring(0, this.value.toString().length-1);
        },

        onLongPressStart () {
            this.value=0;
        },

        onLongPressStop () {
            this.clearNumber();
        },

        async approveValue(){
            if(this.displayValue==""){
                this.$emit("valueChange", this.displayValue);            
                this.showkeyBoard=false;
                if (this.stateField && this.stateField.OnChange) {
                    await this.CallRunOnchange().catch(err => {console.error(err);});
                }
                done();
                
            }else if(Number(this.displayValue).toString()!="NaN" && Number(this.displayValue) != 0){  
                this.$emit("valueChange", this.displayValue?Number(this.displayValue):this.displayValue);   
                this.showkeyBoard=false;
                if (this.stateField && this.stateField.OnChange) {
                    await this.CallRunOnchange().catch(err => {console.error(err);});
                }
                done();
                
            }
            
        },

        async CallRunOnchange() {
            if (this.stateField.OnChange) {
                let field_path = null;
                if (this.fieldRulePath) {
                    field_path = this.fieldRulePath + '.Fields.' + this.stateField.Name;
                } else {
                    field_path = 'Fields.' + this.stateField.Name;
                }
                let field_value;
                if (this.$root.fieldWithValues(this.stateField.Type)) {
                    field_value = this.values;
                } else {
                    field_value = this.value;
                }   
                this.$emit('runOnChange', 
                    {'objName': this.objName, 
                    'Id':this.Id,
                    'action': this.stateField.OnChange, 
                    'value': field_value, 
                    'field_path': field_path, 
                    'args': {}
                    }
                );
                
            }
        },

    },

    mounted() {
        this.showkeyBoard = true;
        this.value = 0;
    },
  
    computed:{
        displayValue: {
            get: function () {
                return this.value;
            },
            set: function(newValue){
                this.value=newValue;
            }
        },
    }
};
</script>

<style scoped>
    .el-input__inner {
        font-size: 20em;
    }
    .blocks >>> .el-button {
        width:100%;
        margin: 5px 0px 5px 0px;
        padding: 16px 18px;
        font-size: 20px;
    }
    .btn_container {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
    }
    .el-row {
        width:100%;
    }
    .blocks >>>.keyboard-button-check {
        background-color: #409167;
    }
    .blocks >>>.keyboard-button-cancel {
        background-color: #B3450E;
        color: #FFF;
    }
    .blocks >>>.keyboard-button {
        background-color: #0A76A4;
        color: #FFF;
    }

</style>
<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $numeric__keyboard__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 26px;
        margin-top: -15px;
        font-weight: bold;
        border: 3px solid #263445;
        margin-right: 5px;
    }
    ::v-deep .el-input__inner {
        font-size: 35px;
        margin-left: 6px;
        font-weight: bold;
        width: 96%;
    }
</style>