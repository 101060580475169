<template>

  <div id="calWrap">
      <div class="top">
          <input type="text" class="input-total" disabled v-model="total" />
      </div>
      <div id="keys">

          <div v-on:click="clear()" style="width:47.5%" class="clear">C</div>
          <div v-on:click="removeLastNumber('back')">←</div>
          <!-- <div v-on:click="key('%')" >%</div> -->
          <div v-on:click="key('/')">/</div>


          <div v-on:click="key(7)">7</div>
          <div v-on:click="key(8)">8</div>
          <div v-on:click="key(9)">9</div>
          <div v-on:click="key('*')">x</div>
          <div v-on:click="key(4)">4</div>
          <div v-on:click="key(5)">5</div>
          <div v-on:click="key(6)">6</div>
          <div v-on:click="key('-')">-</div>
          <div v-on:click="key(1)">1</div>
          <div v-on:click="key(2)">2</div>
          <div v-on:click="key(3)">3</div>
          <div v-on:click="key('+')">+</div>
          <div v-on:click="key(0)">0</div>


          <div v-on:click="key('.')">.</div>
          <div style="width:47.5%" v-on:click="equal()">=</div>
      </div>
  </div>  

    
</template>
<script>
export default {
  name: 'component.vue',
  data() {
    return { total: 0 }
  },
  methods: {
    key: function(num) {
      if (this.total != 0) {
        let op = this.total.toString().charAt(this.total.toString().length-1)
        if ( (op == "/" || op == "*" || op =="-" || op == "+" || op == ".") && (num == "/" || num == "*" || num =="-" || num == "+" || num == ".")) {
          return this.total;
        }
      }
      else {
        if (num == "/" || num == "*" || num == "+") {
          return this.total;
        }
        return this.total = num;
      }
      return this.total = this.total+ "" + num;
    },
    clear: function() {
      return this.total = 0;
    },
    equal: function() {
      let equal = this.total;
      return this.total = eval(equal);
    },  
    removeLastNumber: function() {
      if (this.total != 0) {
        this.total = this.total.toString().slice(0, -1);
        if (this.total == "") {
          this.total = 0;
          return this.total;
        }
        return this.total;
      }
    }       
  }
  
};
</script>
<style scoped>

#calWrap {
  background-color: #77889a;
  padding: 2em 1em 1em 1.5em;
  margin: auto;
  width: 320px;
  clear: both;
  display: table;
}
.top input {
  height: 50px;
  width: 76%;
  font-size: 32px;
  font-weight: bold;
  margin: 0 0 14px 0;
  border: 0;
  color: #606266;
  padding: 0 1em;
  box-shadow: 0px 4px rgba(0, 0, 0, 0.3);
  background-color: #FFFFFF;
}
#keys div {
  cursor: pointer;
  width: 70px;
  height: 60px;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  line-height: 2em;
  background: white;
  box-shadow: 0px 4px rgba(0, 0, 0, 0.3);
  margin: 0 7px 11px 0;
  float: left;
  transition: all 0.2s ease;
  -webkit-user-select: none;  
  -moz-user-select: none;     
  -ms-user-select: none;      
  user-select: none; 
}
#keys div:hover {
  background-color: #D6D6D6;
}
#keys .zero {
  width: 138px !important;
}
#keys .clear {
  background-color: #ffb900 !important;
}

</style>