<template>
  <el-col class="product-select" :xs="24" :sm="8" :md="8" :lg="8" :xl="6" v-if="renderCondition!=false">
    <el-row  style="margin: 13px 5px 0px 5px; cursor:pointer; border: #C0C4CC 1px solid;" @click.native.prevent="HandleProductClick">
      <el-col style="padding:3px 0 0 3px" :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <pos-file-image 
        class="zoom center"
        :fileId="objContent.ProductPhoto" 
        v-long-press="900"
        @long-press-start="onLongPressStart"
        :size="getSize()">
      </pos-file-image>
      </el-col>
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
        <div>
          <p :class="getClass(objContent.ProductName)">
            <el-tooltip class="item" effect="light" :content="objContent.ProductName" placement="bottom">
              <span>{{objContent.ProductName}}</span>
            </el-tooltip>
          </p>
        </div>
      </el-col>
    </el-row>
    <pos-key-board-number 
      v-if="isKeyBoardNumberVisivle" 
      :value="0"    
      backgroundColor="black"         
      @valueChange="HandleQuantityChange"
      @closed="isKeyBoardNumberVisivle=false" />
  </el-col>
</template>
<script>

import LongPress from 'vue-directive-long-press'

export default {
    Name: "POSproduct",
    directives: {
        'long-press': LongPress
    },
    props:["objContent", "renderCondition"],

    data() {
        return {
            isKeyBoardNumberVisivle: false,
            keyNumberValue: 0,
        }
    },

    computed: {

      isMediumScreen: {
        get() {
          return this.$root.isMediumScreen();
        },
      },

      isMobile: {
        get() {
          return this.$root.isMobileDevice();
        },
      },
    },

    methods: {
      getClass(name) {
        if(name == name.toUpperCase()) {
          return 'p-product-uppercase';
        } else {
          return 'p-product';
        }
      },

      getSize() {
        let num = 80;
        if(this.isMobile || this.isMediumScreen) {
          num = 75;
        } 
        return num;
      },

      setProduct(quantity){
        this.$emit("productSelected", {Quantity: quantity, Product:this.objContent});
      },

      onLongPressStart () {
          this.isKeyBoardNumberVisivle = true;
      },

      HandleProductClick () {
          this.setProduct(1);
      }, 
      
      HandleQuantityChange(new_val){
          this.isKeyBoardNumberVisivle = false;
          this.setProduct(new_val);
      },
      // getSize(name) {
      //   if (name.length <=15) {
      //     return 'font-size:12px';
      //   } else if (name.length >15 && name.length <=20){
      //     return 'font-size:11px';
      //   } else {
      //     return 'font-size:10px';
      //   }
      // }
    }

}
</script>

<style scoped>
.zoom:hover {
  -ms-transform: scale(1.4); /* IE 9 */
  -webkit-transform: scale(1.4); /* Safari 3-8 */
  transform: scale(1.4);
}
.p-product-uppercase {
  font-size:13px;
  line-height: 1.2;
  margin: 5px 5px 0 30px;
  height: 80px;
  overflow-x: hidden;
  overflow-y: auto;
  
  color:#fff;
  text-align: justify;
}
.p-product {
  font-size:15px;
  line-height: 1.2;
  margin: 5px 5px 0 10px;
  height: 80px;
  overflow: auto;
  font-size: "13px";
  color:#fff;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
}
.p-product::-webkit-scrollbar, .p-product-uppercase::-webkit-scrollbar {
        width: 0px;
    }
/* .product-name {
  font-size: 14px;
} */
  /* .card-header-content {
      font-weight: 600;
      font-size: 2rem;
      background-color: #FFFFFF;
  }
  .product-select {
    cursor: pointer;
    text-align: center;
  }
  .card-product {
    margin: 8px 6px 0 3px;
  } */

</style>
<style>
  /* .el-image {
    width: 100%;
  }
  .el-result {
    padding: 5px 0px 0px 0px;
  }
  .el-result__title p {
      font-size: 12px;
      max-height: 35px;
      height: 25px;
  }
  .el-result__title {
    margin-top: 0px;
  } */
  
</style>
