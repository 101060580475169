<template>
    <el-dialog 
        :visible.sync="showModal"
        width="55%"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body
        :close-on-press-escape = "false"
        class="modal-staff-work-time">
        <div style="display:none">
            <el-input                      
                style="font-size: 14px"
                v-model="displayPOSUser" 
                mini
                :disabled="true"
            ></el-input>
            <el-input
                type= "password"
                style="font-size: 16px"
                v-model="displayPOSCode" 
                :disabled="true"
            ></el-input>
        </div>
        <el-card class="el-card-login staff-work-time" @closeDialog = "closeDialog">
            <el-row>
                <el-col class="users-column scroolbar" :xs="24" :sm="24" :md="12" :lg="14" :xl="14">
                    <el-row class="scrollbar">
                        <div class="demo-fit">
                            <el-col v-for="(user, idx) of users" :key="idx" @click.native="setUser(user.Login, idx)" :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                                <pos-file-image
                                    type="user"
                                    :fileId="user['AppFile.Id']" 
                                    :size="80"
                                    :class="{ active : isActive == idx }"
                                >
                                </pos-file-image>
                                <p class="username-login">{{user.Name}}</p>
                            </el-col>
                        </div>
                    </el-row>
                    <el-row>
                        <el-button
                            v-if="!entrada"
                            class="keyboard-button"
                            type="warning"
                            @click="setEntrada()"
                            >
                            Entrada
                        </el-button>
                        <el-button
                            v-else
                            class="keyboard-button"
                            type="success"
                            >
                            Entrada
                        </el-button>
                        <el-button
                            v-if="!saida"
                            class="keyboard-button"
                            type="warning"
                            @click="setSaida()"
                            >
                            Saida
                        </el-button>
                        <el-button
                            v-else
                            class="keyboard-button"
                            type="success"
                            >
                            Saida
                        </el-button>
                        <el-button
                            class="keyboard-button"
                            type="danger"
                            @click="closeDialog()"
                            >
                            Cancelar
                        </el-button>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
                    <el-row :gutter="10">
                        <el-button class="keyboard-button" type="info" @click="setDigit(7)">7</el-button>
                        <el-button class="keyboard-button" type="info" @click="setDigit(8)">8</el-button>
                        <el-button class="keyboard-button" type="info" @click="setDigit(9)">9</el-button>
                    </el-row>
                    <el-row :gutter="10">
                        <el-button class="keyboard-button" type="info" @click="setDigit(4)">4</el-button>
                        <el-button class="keyboard-button" type="info" @click="setDigit(5)">5</el-button>
                        <el-button class="keyboard-button" type="info" @click="setDigit(6)">6</el-button>
                    </el-row>
                    <el-row :gutter="10">
                        <el-button class="keyboard-button" type="info" @click="setDigit(1)">1</el-button>
                        <el-button class="keyboard-button" type="info" @click="setDigit(2)">2</el-button>
                        <el-button class="keyboard-button" type="info" @click="setDigit(3)">3</el-button>
                    </el-row>
                    <el-row :gutter="10">
                        <el-button type="danger" style="max-width:83.35px" class="fas fa-times keyboard-button-cancel" @click="clearNumber"></el-button>
                        <el-button class="keyboard-button" type="info" @click="setDigit(0)">0</el-button>
                        <el-button type="success" style="max-width:83.35px" class="fas fa-check keyboard-button-check" @click="authenticate()"></el-button>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
    </el-dialog>
</template>

<script>
import screenfull from 'screenfull';
export default {
    components:{
        screenfull
    },

    props: [
      "dialogPosStaffWorkTimeVisible"
    ],

    data() {
       return {
           lastUserId: null,
           posUser: null,
           posCode: null,
           entrada: false,
           saida: false,
           i18n: null,
           users: null,
           isActive:null,
           showModal: false,
           page_loading :null,
       } 
    },

    methods: {
        async getPhoto(fileId) {
            let blob = await this.$root.getUploadedFileById(fileId).catch(err => {console.error(err);}); 
            if (blob) {
                return window.URL.createObjectURL(blob); 
            } 
        },

        setDigit(val) { 
            this.posCode = this.posCode + val.toString();
        },

        setEntrada() {
            this.entrada = true;
            this.saida = false;
        },

        setSaida() {
            this.entrada = false;
            this.saida = true;
        },

        clearNumber() {
            this.posCode = "";
            //this.posUser = "";
        },

        setUser(user, idx) {
            this.isActive = idx;
            this.posUser = user;
            this.posCode = "";
        },

        async closeDialog() {
          this.dialogPosStaffWorkTimeVisible = false;
          this.$emit("closed");
        }, 

        async authenticate() {
            if (this.posUser == "") {
                await this.$root.ShowMessage({ message: "Selecione um Utilizador", type: "error" });
                return;
            }
            if (this.posCode == "") {
                await this.$root.ShowMessage({ message: "o Código não pode ser nulo", type: "error" });
                return;
            }
            this.page_loading = this.$root.getLoading();
            const payload = {
                username: this.posUser,
                password: this.posCode,
                type: "pos",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                change_user: true
            };
            let result = await this.$root.Authenticate(payload).catch(err => {console.error(err);});
            if (result.success == "OK") {



                // call function to write in StaffTimeTable


                
                await this.closeDialog().catch(err => {console.error(err);});
                if (!screenfull.isFullscreen) {
                    screenfull.toggle()
                }
                this.$router.push("/pos").catch(() => {console.log('erro de router.push em staffworktime')});
            } else {
                await this.$root.ShowMessage({ type: result.type, message: result.message });
            }
            this.page_loading.close();
        },

        getSize(name) {
          if (name.length <= 15) {
            return 'font-size:12px';
          } else if (name.length > 15 && name.length <= 20) {
            return 'font-size:11px';
          } else {
            return 'font-size:10px';
          }
        } 
    },

    computed:{
        displayPOSCode: {
            get: function () {
                return this.posCode;
            },
            set: function(newValue){
                this.posCode = newValue;
            }
        },

        displayPOSUser: {
            get: function () {
                return this.posUser;
            },
            set: function(newValue) {
                this.posUser = newValue;
            }
        },
    },

    async created() {
        // alert("aa");
        this.users = JSON.parse(localStorage.getItem("posUsers"));
        // ir buscar os pos users no storage
        if (this.users == null || this.users == []) {
            this.users == await this.$root.getPOSUsers().catch(err => {console.error(err);});
        }
        this.lastUserId = localStorage.getItem("user-id");
        if (this.dialogPosStaffWorkTimeVisible) {
            this.showModal = this.dialogPosStaffWorkTimeVisible;   
        //     localStorage.removeItem('user-photo');
        //     localStorage.removeItem('user-id');
        //     localStorage.removeItem('username');
        //     localStorage.removeItem('enterprise_name');
        //     localStorage.removeItem('user-email');
        //     localStorage.removeItem('user-language');
        //     localStorage.removeItem('user-timezone');
        //     localStorage.removeItem('user-terminal-id');
        //     localStorage.removeItem('teams');
        //     localStorage.removeItem('userTheme');
        //     localStorage.removeItem('token-expires');
        //     localStorage.removeItem('token-iat');
        //     localStorage.removeItem('token');     
        //     localStorage.removeItem('i18n');
        }
    },
};
</script>

<style scoped>
    .modal-staff-work-time {
        max-height: 100%;
        overflow: hidden;
    }
    div >>> .el-dialog__header {
        margin-top: 20px;
        display: none;
    }
    div >>>.el-dialog__body {
        padding: 0 0;
        border: none;
    }
    .mask {
        font-size: 30px;
        color: #F3F3F3;
    }
    .user-photo-login:hover, .user-photo-login:enabled {
        border: rgb(10, 10, 9) 1px solid;
    }
    .keyboard-button-check {
        background-color: #409167;
    }
    .keyboard-button-cancel {
        background-color: #B3450E;
    }
    .keyboard-button {
        background-color: #0A76A4;
    }
    p {
        color: white;
    }
    .el-card-login {
        background-color: #000;
        border:#F9F9F9 1px solid;
        opacity: 0.8;
    }
    .active {
        background:#53895A;
        opacity: 0.7;
    }
    .darkgreen {
        background-color: black;
    }
    /* .inactive {
        background-color: none;
    } */
    .el-button {
        padding: 27px 35px;
        margin: 3px 3px 3px 3px;
        font-size: 20px;
    }
    .hidden {
        display: none;
    }
    .login {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login .el-card {
        width: 700px;
        justify-content: center;
    }
    .center {
        text-align: center;
    }
    .user-box-card {
        width: 33%;
        margin: 0px 0px 0px 0px;
    }
    .users-column {
        margin: 3px -1px 0px 0px;
        cursor: pointer;
    }
    .demo-fit {
        text-align: center;
    }
    .login-user {
        margin-left: 0px;
    }
    .username-login {
        margin-top: 0px;
        margin-bottom: 4px;
        font-size: 14px;
    }
    @media (max-width: 2500px) {
        .scrollbar {
            height: calc(100vh - 65vh);
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 2px;
        }
    }
    @media (max-width: 1900px) {
        .scrollbar {
            height: calc(100vh - 53vh);
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 2px;
        }
    }
    @media (max-width: 1500px) {
        .scrollbar {
            height: calc(100vh - 50vh);
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 2px;
        }
    }
    @media (max-width: 767px) {
        .scrollbar {
            height: calc(100vh - 72vh);
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 2px;
        }
    }
</style>
