<template>
    <div>
      ff
        <el-dialog 
          :visible.sync="dialogPosUserVisible"
          width="60%"
          :before-close="HandleClose"
          :close-on-click-modal="false"
          append-to-body
          class="modal-pos-user"
        >
          <edit-form
            objName="POSUser" 
            :Id="userId"           
            :showTitle="false"
            :canRedirect="false"           
          />
          <br/>
        </el-dialog>     
    </div>
</template>

<script>
export default {
    props: [
      "dialogPosUserVisible", "userId", "title"
    ],

    // data() {
    //   return {
    //     data: [],
    //     value: ["_rigth_"],
    //     showDialogMesa: false,
    //   };
    // },

    methods: {
        // async HandleSaveTransfer(){
        //   let confirmed = true;
        //   this.$confirm('Cria o Credito ?', 'Warning', {
        //         confirmButtonText: "Sim",
        //         cancelButtonText: "Não",
        //         type: 'warning',
        //         title: 'Atenção!'
        //     }).then(() => {
        //         confirmed = true;
        //     }).catch(() => {
        //         confirmed = false;
        //     }
        //   );
        // },

        async HandleClose(done) {     
            this.dialogPosUserVisible=false;
            this.$emit("closed");
            done();
        },
    },

}
</script>

<style  scoped>
  .modal-pos-user >>> .scrollbar-edit{
    height: 40%;
  }
</style>

<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>