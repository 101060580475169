<template>
    <div>
        <el-dialog 
            :visible.sync="dialogSummaryVisible"
            width="90%"
            :before-close="HandleClose"
            :close-on-click-modal="false"
            :show-close="false"
            append-to-body
            class="modal-shift"
        >
          <edit-form
            objName="POSSummary" 
            Id="new_id"  
            :is_pos="true"          
            :showTitle="false"
            :canRedirect="false"       
            @closeDialog = "closeDialog"    
          />
        </el-dialog>      
    </div>
</template>

<script>
export default {
    props: [
      "dialogSummaryVisible"
    ],

    data() {
      return {
        data: [],
        value: ["_rigth_"],
      };
    },

    methods: {
        async HandleSaveTransfer(){
          let confirmed = true;
          await this.$confirm('Criar nota de oferta?', 'Warning', {
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                type: 'warning',
                title: 'Atenção!'
            }).then(() => {
                confirmed = true;
            }).catch(() => {
                confirmed = false;
            }
          );
        },
        async HandleClose(done) {     
            this.dialogSummaryVisible=false;
            this.$emit("closed");
            done();
        },
        async closeDialog() {
          this.dialogSummaryVisible=false;
          this.$emit("closed");
        }
    },


}
</script>
<style  scoped>
  .modal-shift >>> .scrollbar-edit{
    height: 30%;
  }

</style>
<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>