import Vue from 'vue';
var API_URL = localStorage.getItem('API_URL');

// async function api(method, url, params, content_type) {
//     let token = JSON.parse(localStorage.getItem('token'));
//     const args = {'method':method, 'url':url, 'params':params, 'content_type':content_type, 'token': token};
//     let res = null;
//     console.error('yes ------------------------------------');
//     const worker = new Worker(new URL('../service-worker.js', import.meta.url));
//     worker.postMessage(args);
//     worker.onmessage = (event) => {
//         console.error('result from worker');
//         console.error(event.data);
//         console.log('end');
//         res = event.data;
//         console.log(res);
//         return res;
//     };
// }


async function api(method, url, params, content_type) {
    let headers = { 'Accept': 'application/json', "Content-Type": "application/json; charset=utf-8;","Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*" };
    if (content_type == "file") {
        headers = {'Accept': 'application/json', "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*" };
    }
    let token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        token = token.token;
        headers['Authorization'] = `Bearer ${token}`;
    }
    let requestOptions = {
        method: method,
        headers: headers
    };
    let body = JSON.stringify(params);
    if (content_type == "file") {
        const formData = new FormData();
        formData.append('file', params);
        body = formData;
    }
    if (method == "POST") {
        requestOptions = {
            method: method,
            headers: headers,
            body: body,
        };
    }
    const response = await fetch(url, requestOptions).catch(err => {return {success: false, result: "Sem Conexão com o servidor. Contacte o administrador!"};});
    return response;
}

async function decodeToken(token) {
    if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var result = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    return JSON.parse(result);        
}

async function getToken() {
    let token = JSON.parse(localStorage.getItem('token'));
    return token;
}
async function getTokenString() {
    let token = JSON.parse(localStorage.getItem('token'));
    return token.token;
}

// async function getSessionStorage () {
//     let result = JSON.parse(window.sessionStorage.getItem('store'));
//     return result;
// }

async function lockObject(name, id) {
    if (name && id) {        
        let url = API_URL + 'api/obj/Lock';
        api("POST", url, {"objName":name, "obj_id":id});
    }
}

async function unlockObject(name, id) {
    if (name && id) {        
        let url = API_URL + 'api/obj/Unlock';
        api("POST", url, {"objName":name, "obj_id":id});
    }
}

async function pingToBackend() {  
    let response;
    try {   
        let url = API_URL + 'api/obj/Ping';
        response = await api("GET", url, {});    
        if (response.status >= 200 && response.status < 300) {          
            return 'OK';
        }
    } catch (err) {
        if (response.status == 200) {
            return 'OK';
        }
        return null;
    }
    return null;     
}

Vue.prototype.$api = api;
Vue.prototype.$decodeToken = decodeToken;
// Vue.prototype.$getSessionStorage = getSessionStorage;
Vue.prototype.$lockObject = lockObject;
Vue.prototype.$unlockObject = unlockObject;
Vue.prototype.$pingToBackend = pingToBackend;
Vue.prototype.$getToken = getToken;
Vue.prototype.$getTokenString = getTokenString;

export default {
    api,
    decodeToken,
    // getCurrentUserPhoto,
    // getCurrentUserId,
    // getSessionStorage,
    lockObject,
    unlockObject,
    pingToBackend,
    // getCurrentUserName,
    // getEnterpriseName,
    // getCurrentTheme,
    // getCurrentUserTerminalId,
    // getCurrentLanguage,
    getToken,
    getTokenString
}
