<template>
  <div>
    <el-dialog 
            :visible.sync="dialogDFEReportVisible"
            width="70%"
            :before-close="HandleClose"
            :close-on-click-modal="false"
            append-to-body
            class="modal-dfe-report"
        >
          <!-- ver com ivanir (botao fica escondido se is_pos for false e neste caso nao é pos) -->
          <edit-form
            :objName="objName" 
            :is_pos="true" 
            :showTitle="false"
            :canRedirect="false"   
            @closeDialog = "HandleCloseDialog()"        
          />
                   
    </el-dialog>
  </div>   
</template>
<script>
export default {
  name: 'dfeReport',
  props: [
    "dialogDFEReportVisible",
    "objName",
    "documentType"
  ],
  data () {
    return {
      reportPath: {}
    }    
  },
  async created() {
    //this.dialogDfeReport=true;
    // if(this.$route.params.reportName) {
    //   await this.$root.getReport(this.$route.params.reportName, this.args);
    // }
  },
  methods: {
    async HandleClose(done) {     
      this.dialogDfeReport=false;
      this.$emit("closed");
      done();
    },
    async HandleCloseDialog() {
      this.dialogDfeReport=false;
      this.$emit("closed");
    }
  }
};
</script>
<style scoped>
.modal-dfe-report >>> .scrollbar-edit{
    height: 30%;
  }
</style>
