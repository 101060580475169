<template>
    <div id="app" oncontextmenu="return false;" class="prevent-select">
        <transition mode="out-in" name="fade">
            <router-view />
        </transition>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'app',
        created() {
            window.addEventListener('resize', this.HandleResize);
            this.HandleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.HandleResize);
        },
        methods: {
            ...mapActions({
            resize: 'resize',
            }),
            HandleResize() {
            this.resize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            },
        },
    };
</script>
<style lang="scss">
//   .prevent-select {
//     -webkit-user-select: none; /* Safari */
//     -ms-user-select: none; /* IE 10 and IE 11 */
//     user-select: none; /* Standard syntax */
//   }
</style>
