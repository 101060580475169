import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/context-store';

Vue.use(Router);

const router = new Router({
  //mode: 'history',
  mode: 'abstract',
  routes: [
    {
      path: '/pos-menu',
      component: () => import('@/modules/layout/posComponents/pos-menu.vue'),
    },
    {
      path: '/test-chart',
      component: () => import('@/modules/layout/posComponents/chart.vue'),
    },
    {
      path: '/pos-tv',
      component: () => import('@/modules/layout/posComponents/pos-tv.vue'),
    },
    {
      path: '/pos/auth/login',
      component: () => import('@/modules/auth/posComponents/login.vue'),
    },
    {
      path: '/pos',
      component: () => import('@/modules/layout/posComponents/layout.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          name: 'homePOS',
          path: '/',
          component: () => import('@/modules/layout/posComponents/home.vue'),
        },
        {
          name: 'posKitchen',
          path: '/pos-kitchen',
          component: () => import('@/modules/layout/posComponents/forms/posKitchen.vue'),
        },

      ]
    },
    {
      name: 'login',
      path: '/auth/login',
      component: () => import('@/modules/auth/components/login.vue')
    },
    {
      name: '',
      path: '/',
      component: () => import('@/modules/layout/components/layout.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          name: 'home',
          path: '/',
          component: () => import('@/modules/layout/components/home.vue'),
        },
        {
          name: 'list',
          path: '/list/:objName',
          meta: { breadCrumb: 'List' },
          props: true,
          component: () => import('@/modules/layout/components/forms/list.vue'),
        },
        {
          name: 'adminList',
          path: '/admin/list/:objName/',
          props: true,
          meta: { breadCrumb: 'List of Admin Files' },
          component: () => import('@/modules/layout/components/forms/adminFileList.vue'),
        },
        {
          name: 'admin',
          path: '/admin/:objName/:Id/',
          meta: { breadCrumb: 'Admin' },
          props: true,
          component: () => import('@/modules/layout/components/forms/edit.vue'),
        },
        {
          name: 'redirect',
          path: '/edit/:objName/:Id/:IsRedirect',
          meta: { breadCrumb: 'Redirect' },
          props: true,
          component: () => import('@/modules/layout/components/forms/edit.vue'),
        },
        {
          name: 'edit',
          path: '/edit/:objName/:Id?',
          meta: { breadCrumb: 'Edit' },
          props: true,
          component: () => import('@/modules/layout/components/forms/edit.vue'),
        },
        {
          name: 'new',
          path: '/edit/:objName/:Id',
          meta: { breadCrumb: 'New' },
          props: true,
          component: () => import('@/modules/layout/components/forms/edit.vue'),
        },
        {
          name: 'view',
          path: '/view/:objName/:Id/:isParentViewer',
          meta: { breadCrumb: 'View' },
          component: () => import('@/modules/layout/components/forms/edit.vue'),
          props: true,
        },
        {
          name: 'calendar',
          path: '/calendar/:objName?/:filters?',
          meta: { breadCrumb: 'Calendar' },
          component: () => import('@/modules/layout/components/forms/calendar.vue'),
          props: true,
        },
        {
          name: 'dashboard',
          path: '/dashboard/:dashboardName',
          meta: { breadCrumb: 'Dashboard' },
          props: true,
          component: () => import('@/modules/layout/components/forms/dashboard.vue'),
        },
        // {
        //   name: 'report',
        //   path: '/report/:reportName',
        //   meta: {breadCrumb: 'Reports'},
        //   props: true,
        //   component: () => import('@/modules/layout/components/forms/report.vue'),
        // },
        {
          name: 'processViewer',
          path: '/process/view/:processName',
          meta: { breadCrumb: 'Process' },
          props: true,
          component: () => import('@/modules/layout/components/forms/processViewer.vue'),
        },
        {
          name: 'processModeler',
          path: '/process/edit/:processName',
          meta: { breadCrumb: 'Process Modeler' },
          props: true,
          component: () => import('@/modules/layout/components/forms/processModeler.vue'),
        },
        {
          name: 'processList',
          path: '/process/list',
          props: true,
          meta: { breadCrumb: 'List of Processes' },
          component: () => import('@/modules/layout/components/forms/processList.vue'),
        },
        {
          name: 'rulesViewer',
          path: '/rule/view/:ruleName',
          meta: { breadCrumb: 'Rule Viewer' },
          props: true,
          component: () => import('@/modules/layout/components/forms/ruleViewer.vue'),
        },
        {
          name: 'rulesModeler',
          path: '/rule/edit/:ruleName',
          meta: { breadCrumb: 'Rule Modeler' },
          component: () => import('@/modules/layout/components/forms/ruleModeler.vue'),
        },
        {
          name: 'rulesList',
          path: '/rule/list',
          meta: { breadCrumb: 'List of Rules' },
          props: true,
          component: () => import('@/modules/layout/components/forms/ruleList.vue'),
        },
        {
          name: 'gantt',
          path: '/gantt/:objName/:Id',
          component: () => import('@/modules/layout/components/forms/gantt.vue'),
        },
        {
          name: 'tree',
          path: '/tree/:objName',
          meta: { breadCrumb: 'Hierarchical' },
          props: true,
          component: () => import('@/modules/layout/components/forms/tree.vue'),
        },
        {
          name: 'fileBrowser',
          path: '/fileBrowser/:filePath',
          meta: { breadCrumb: 'File Browser' },
          props: true,
          component: () => import('@/modules/layout/components/forms/fileBrowser.vue'),
        },
        {
          name: 'gis',
          path: '/gis/:gisName',
          meta: { breadCrumb: 'Geolocation' },
          props: true,
          component: () => import('@/modules/layout/components/forms/map.vue'),
        },
        {
          name: 'dfeReport',
          path: '/dfeReport/:documentName?',
          meta: { breadCrumb: 'Dfe Report' },
          props: true,
          component: () => import('@/modules/layout/components/forms/dfeReport.vue'),
        },
      ],
    },
    {
      name: 'POSlogin',
      path: '/pos/login',
      component: () => import('@/modules/auth/posComponents/login.vue'),
    },
    {
      name: 'POS',
      path: '/pos',
      meta: { breadCrumb: 'POS' },
      props: true,
      component: () => import('@/modules/layout/posComponents/forms/posHome.vue'),
    },
  ]
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = JSON.parse(localStorage.getItem('token'));
    let pos = localStorage.getItem('pos');
    let _path = '/auth/login';
    if (pos == "true") {
        _path = "/pos/auth/login";
    }
    if (!token || !token.token) {
      router.replace({
          path: _path,
          query: { redirect: to.fullPath }
      });
    } else {
      if (token.exp <= Date.now()/1000) {
        router.replace({
          path: _path,
          query: { redirect: to.fullPath }
        });
      } else{
        next();
      }
    }
  } else {
      next();
  }
});

export default router;
