<template>
    <div>
        <el-dialog 
            :visible.sync="showDialog"
            width="90%"
            :before-close="HandleClose"
            :close-on-click-modal="false"
            :show-close="false"
            append-to-body
            class="modal-shift"
        >
          <edit-form
            :objName = objName 
            :is_pos="true"   
            :Id="new_id"        
            :showTitle="false"
            :canRedirect="false"
            @closeDialog = "closeDialog"           
          />
        </el-dialog>      
    </div>
</template>

<script>
export default {
    props: [
      "dialogCashJournalVisible"
    ],

    data() {
      return {
        data: [],
        value: ["_rigth_"],
        new_id: null,
        objName: "POSCashJournal",
        showDialog: false
      };
    },

    async created() {
      if(this.dialogCashJournalVisible) {
        let cashJournal = await this.$root.GetCashJournal().catch(err => {console.error(err);}); 
        if (cashJournal && cashJournal[1] > 0) {
          cashJournal = cashJournal[0][0];
          let obj = await this.$root.getObject({ "objName":this.objName}).catch(err => {console.error(err);});  
          this.new_id = cashJournal.Id;
          let content = {};
          content[this.objName + this.new_id] = obj;
          this.$store.dispatch('setLoadedObjects', content); 
          let cashJournalObj = await this.$root.ApplyProperties({"objName": this.objName, "data": cashJournal}).catch(err => {console.error(err);});
          if (cashJournalObj) {
            let propVal = {}
            //alert(JSON.stringify(cashJournalObj))
            if (cashJournalObj.ActiveActions) {
                propVal["ActiveActions"] = cashJournalObj.ActiveActions;
                await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'value': propVal}).catch(err => {console.error(err);}); 
            } 
            this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "Id", 'value':cashJournal.Id});
            this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "Number", 'value':cashJournal.Number});
            propVal["Disabled"] = "true";
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.InitialAmount",'value': propVal}).catch(err => {console.error(err);});
            this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "InitialAmount", 'value':cashJournal.InitialAmount});
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.InitialDateTime",'value': propVal}).catch(err => {console.error(err);});
            this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "InitialDateTime", 'value':cashJournal.InitialDateTime});
            this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "Status", 'value':cashJournal.Status});
            propVal["Disabled"] = "false";
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.FinalDateTime",'value': propVal}).catch(err => {console.error(err);});
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.FinalAmount",'value': propVal}).catch(err => {console.error(err);});
            propVal["Required"] = "true";
            await this.$root.updateObjectProperty({'objName': this.objName, 'Id':this.new_id, 'path': "Fields.FinalAmount",'value': propVal}).catch(err => {console.error(err);});
          }
        } 
        this.showDialog= this.dialogCashJournalVisible;
      }
      //}         
    },

    methods: {
        async HandleSaveTransfer(){
          let confirmed = true;
          await this.$confirm('Criar nota de oferta?', 'Warning', {
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                type: 'warning',
                title: 'Atenção!'
            }).then(() => {
                confirmed = true;
            }).catch(() => {
                confirmed = false;
            }
          );
        },

        async HandleClose(done) {  
          this.dialogCashJournalVisible=false;
          this.$emit("closed");
          done();
        },

        async closeDialog() {
          this.dialogCashJournalVisible=false;
          this.$emit("closed");
        }

    },
}
</script>

<style  scoped>
  .modal-shift >>> .scrollbar-edit{
    height: 30%;
  }

</style>

<style lang="scss" scoped>
  @import '@/assets/styles/scss/color-themes.scss';
  ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
      background-color: $modal__background__color;
  }
  ::v-deep .el-dialog__headerbtn {
      font-size: 25px;
      font-weight: bold;
  }
  ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
    background-color: $modal__input__background__color;
    border-color: $modal__input__border__color;
  }
</style>