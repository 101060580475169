<template>
    <div>
        <el-dialog 
            :visible.sync="dialogMargeVisible"
            width="50%"
            :before-close="HandleClose"
            :close-on-click-modal="true"
            :show-close="true"
            append-to-body
            class="modal-marge">
            <edit-form
              objName="ProfitMargin" 
              Id="new_id"  
              :is_pos="true"          
              :showTitle="false"
              :canRedirect="false"       
              @closeDialog = "closeDialog" />
        </el-dialog>      
    </div>
</template>

<script>
export default {
    props: [
      "dialogMargeVisible"
    ],

    data() {
      return {
        data: [],
        value: ["_rigth_"],
      };
    },

    methods: {

        async HandleClose(done) {     
            this.dialogMargeVisible=false;
            this.$emit("closed");
            done();
        },

        async closeDialog() {
          this.dialogMargeVisible=false;
          this.$emit("closed");
        }
    },
}
</script>

<style  scoped>
  .modal-marge >>> .scrollbar-edit{
    height: 30%;
  }
</style>

<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>