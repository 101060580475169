<template>
    <div>
        <span v-if="activePosMemory">
          <el-dialog 
              :visible.sync="dialogProformaVisible"
              width="60%"
              :before-close="HandleClose"
              :close-on-click-modal="false"
              :show-close="false"
              append-to-body
              class="modal-proforma"
          >
            <edit-form
              objName="POSProforma"                  
              :is_pos="true" 
              :showTitle="false"
              :canRedirect="false"  
              @closeDialog = "HandleCloseDialog()"         
            />
            <el-row>
                <el-col :span="24">
                  <p class="offer-memory">
                    # {{activePosMemory.Name}} / {{activePosMemory["POSMemorySectionId.Name"]}} #
                  </p> 
                </el-col>
              </el-row>
          </el-dialog>
        </span>
         <!-- MODAL PARA ESCOLHER MESAS/CARTOES de destino -->
        <pos-memory-choice
             v-if="showDialogMesa" 
            :dialogMesaVisible="showDialogMesa" 
            @closed="HandlePosMemoryChoiceClose" 
            @memorySelected="HandleTargetMemorySelected" 
        />        
    </div>
</template>

<script>
export default {
    props: [
      "dialogProformaVisible", 
      "activePosMemory",
      "i18n"
    ],

    data() {
      return {
        data: [],
        value: ["_rigth_"],
        showDialogMesa: false,
      };
    },

    methods: {
        async HandleSaveTransfer(){
          let confirmed = true;
          await this.$confirm('Cria o Proformao ?', 'Warning', {
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                type: 'warning',
                title: 'Atenção!'
            }).then(() => {
                confirmed = true;
            }).catch(() => {
                confirmed = false;
            }
          );
        },

        HandleTargetMemorySelected(new_val){
          this.activePosMemory = new_val;                     
        },

        async HandleClose(done) {     
            this.dialogProformaVisible=false;
            this.$emit("closed");
            done();
        },

        HandleCustomerValueChange(value){
          this.formData.ProformaCustomer=value;
        },

        HandleReasonValueChange(value){
          this.formData.ProformaReason=value;
        },

        HandlePosMemoryChoiceClose(){
          this.showDialogMesa=false;
          if(! this.activePosMemory || Object.keys(this.activePosMemory).length ==0){
            this.dialogProformaVisible=false;
            this.$emit("closed");
          }
        },
        HandleCloseDialog() {
          this.dialogProformaVisible=false;
          this.$emit("closed");
        }
    },

    async created(){
    },
}
</script>
<style  scoped>
  .modal-proforma >>> .scrollbar-edit{
    height: auto;
  }
</style>
<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>
