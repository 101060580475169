<template>
    <div>
        <span v-if="activePosMemory">
          <el-dialog 
              :visible.sync="showModal"
              width="70%"
              :before-close="HandleClose"
              :close-on-click-modal="false"
              :show-close="false"
              append-to-body
              class="modal-credit">
              <edit-form
                :Id="new_id"  
                :objName=objName                  
                :is_pos="true" 
                :showTitle="false"
                :canRedirect="false"  
                @closeDialog = "HandleCloseDialog()" />
              <el-row>
                  <el-col :span="24">
                    <p class="offer-memory">
                      # {{activePosMemory.Name}} / {{activePosMemory["POSMemorySectionId.Name"]}} #
                    </p> 
                  </el-col>
                </el-row>
          </el-dialog>
        </span>
         <!-- MODAL PARA ESCOLHER MESAS/CARTOES de destino -->
        <pos-memory-choice
             v-if="showDialogMesa" 
            :dialogMesaVisible="showDialogMesa" 
            @closed="HandlePosMemoryChoiceClose" 
            @memorySelected="HandleTargetMemorySelected" 
        />        
    </div>
</template>

<script>
export default {
    props: [
      "dialogCreditVisible", 
      "activePosMemory",
      "i18n"
    ],

    data() {
      return {
        data: [],
        value: ["_rigth_"],
        showDialogMesa: false,
        objName:"POSCredit",
        new_id: "",
        showModal: false
      };
    },

    methods: {
        async HandleSaveTransfer() {
          let confirmed = true;
          await this.$confirm('Cria o Credito ?', 'Warning', {
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
                type: 'warning',
                title: 'Atenção!'
            }).then(() => {
                confirmed = true;
            }).catch(() => {
                confirmed = false;
            }
          );
        },

        HandleTargetMemorySelected(new_val) {
          this.activePosMemory = new_val;                     
        },

        async HandleClose(done) {     
            this.dialogCreditVisible=false;
            this.$emit("closed");
            done();
        },

        HandleCustomerValueChange(value) {
          this.formData.creditCustomer=value;
        },

        HandleReasonValueChange(value) {
          this.formData.creditReason=value;
        },

        HandlePosMemoryChoiceClose() {
          this.showDialogMesa=false;
          if (! this.activePosMemory || Object.keys(this.activePosMemory).length ==0) {
            this.dialogCreditVisible=false;
            this.$emit("closed");
          }
        },
        
        HandleCloseDialog() {
          this.dialogCreditVisible=false;
          this.$emit("closed");
        }
    },

    async created(){
      if (this.dialogCreditVisible) {
        let obj = await this.$root.getObject({ "objName":this.objName}).catch(err => {console.error(err);});  
        this.new_id = this.$root.getNewObjectId();
        let content = {};
        content[this.objName + this.new_id] = obj;
        this.$store.dispatch('setLoadedObjects', content);  
        if (this.activePosMemory) {
          let consumptionTable = await this.$root.GetConsumptionTableData(this.activePosMemory).catch(err => {console.error(err);});
          let total = 0;
          consumptionTable.forEach(product => {
            total = total + product.TotalValue;
          });
          this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "TotalDebt", 'value':total});
          this.$root.setFieldValue({'objName': this.objName, 'Id': this.new_id, 'fullFieldName': "TempTotalValue", 'value':total});
        }
        this.showModal = this.dialogCreditVisible;
      }
    },
}
</script>

<style scoped>
  .modal-credit >>> .scrollbar-edit{
    height: 30%;
  }
</style>

<style lang="scss" scoped>
    @import '@/assets/styles/scss/color-themes.scss';
    ::v-deep .el-dialog__header, ::v-deep .el-dialog__body{
        background-color: $modal__background__color;
    }
    ::v-deep .el-dialog__headerbtn {
        font-size: 25px;
        font-weight: bold;
    }
    ::v-deep .el-input__inner, ::v-deep .el-switch__input, ::v-deep .el-textarea__inner {
      background-color: $modal__input__background__color;
      border-color: $modal__input__border__color;
    }
</style>
